import { compress, decompress } from 'lz-string'

/* eslint-disable no-unused-vars */
const getOwnPropertySymbols = Object.getOwnPropertySymbols
const hasOwnProperty = Object.prototype.hasOwnProperty
const propIsEnumerable = Object.prototype.propertyIsEnumerable

function toObject (val) {
if (val === null || val === undefined) {
  throw new TypeError('Object.assign cannot be called with null or undefined')
}

return Object(val)
}

function shouldUseNative () {
try {
  if (!Object.assign) {
    return false
  }

  // Detect buggy property enumeration order in older V8 versions.
  // https://bugs.chromium.org/p/v8/issues/detail?id=4118
  const test1 = new String('abc') // eslint-disable-line no-new-wrappers
  test1[5] = 'de'

  if (Object.getOwnPropertyNames(test1)[0] === '5') {
    return false
  }

  // https://bugs.chromium.org/p/v8/issues/detail?id=3056
  const test2 = {}

  for (let i = 0; i < 10; i++) {
    test2['_' + String.fromCharCode(i)] = i
  }

  const order2 = Object.getOwnPropertyNames(test2).map(function (n) {
    return test2[n]
  })

  if (order2.join('') !== '0123456789') {
    return false
  }

  // https://bugs.chromium.org/p/v8/issues/detail?id=3056
  const test3 = {}
  'abcdefghijklmnopqrst'.split('').forEach(function (letter) {
    test3[letter] = letter
  })
  if (Object.keys(Object.assign({}, test3)).join('') !==
      'abcdefghijklmnopqrst') {
    return false
  }

  return true
} catch (err) {
  // We don't expect any of the above to throw, but better to be safe.
  return false
}
}

const objectAssign = shouldUseNative() ? Object.assign : function (target, source) {
  let from
  const to = toObject(target)
  let symbols

  for (let s = 1; s < arguments.length; s++) {
    from = Object(arguments[s])

    for (const key in from) {
      if (hasOwnProperty.call(from, key)) {
        to[key] = from[key]
      }
    }

    if (getOwnPropertySymbols) {
      symbols = getOwnPropertySymbols(from)
      for (let i = 0; i < symbols.length; i++) {
        if (propIsEnumerable.call(from, symbols[i])) {
          to[symbols[i]] = from[symbols[i]]
        }
      }
    }
}

  return to
}

const storage = {}

class MemoryStorage {
  get length () {
    return Object.keys(this.storage).length
  }

  get storage () {
    return storage
  }

  getItem (key) {
    return (key in this.storage) ? this.storage[key] : null
  }

  setItem (key, value) {
    this.storage[key] = value
  }

  removeItem (key) {
    if (key in this.storage) {
      delete this.storage[key]
    }
  }

  clear () {
      const keys = Object.keys(this.storage)
      for (let i = 0; i <= keys.length; i++) {
          try {
              delete this.storage[keys[i]]
          } catch (_a) {
              // pass
          }
      }
  }
}

const MemoryStorage$1 = new MemoryStorage()

let StorageDriver
(function (StorageDriver) {
  StorageDriver.LOCAL = 'local'
  StorageDriver.SESSION = 'session'
  StorageDriver.MEMORY = 'memory'
})(StorageDriver || (StorageDriver = {}))

class StorageError extends Error {
  constructor (message, stack) {
    super(message)
    this.name = 'StorageError'
    if (stack) {
      this.stack = stack
    }
  }
}

class Vue2Storage {
  constructor (config = {}) {
    this.setOptions(config)
  }

  get length () {
    return this.keys().length
  }

  get prefix () {
    return this.options.prefix
  }

  get name () {
    return 'vue2-storage'
  }

  get version () {
    return '5.0.0'
  }

  get driver () {
    switch (this.options.driver) {
      case StorageDriver.LOCAL:
      default:
        return (typeof window !== 'undefined') ? window.localStorage : MemoryStorage$1
      case StorageDriver.SESSION:
        return (typeof window !== 'undefined') ? window.sessionStorage : MemoryStorage$1
      case StorageDriver.MEMORY:
        return MemoryStorage$1
    }
  }

  setOptions (config = {}) {
    const defaultOptions = {
      prefix: 'app_',
      driver: StorageDriver.LOCAL,
      ttl: 0,
    }
    const options = objectAssign(defaultOptions, config)
    this.options = Object.freeze(options)
  }

  get (key, fallback = null, prop) {
    let val = null
    try {
      if (!prop) {
        val = this.fromJSON(this.addPrefix(key))
      } else {
        const obj = this.fromJSON(this.addPrefix(key))

        // if the object already has the prop
        if (obj && hasOwnProperty.call(obj, prop)) {
          // get the value of it
          val = obj[prop]
        }
      }

      if (val === null) {
        val = fallback
      }

      return val
    } catch (e) {
      this.throwError(e)
    }
  }

  pull (key, fallback = null) {
    const val = this.get(key, fallback)
    if (val !== fallback) {
        this.remove(key)
    }
    return val
  }

  set (key, val, options = {}, prop) {
    try {
      if (!prop) {
        this.driver.setItem(this.addPrefix(key), this.toJSON(val, options))
      } else {
        // get the keys object
        let obj = this.get(key)

        // if the object already has the prop
        if (obj && hasOwnProperty.call(obj, prop)) {
          // update it with the new value
          obj[prop] = val

          this.driver.setItem(this.addPrefix(key), this.toJSON(obj, options))
        } else {
          if (!obj) {
            obj = {}
          }

          // add the prop to the key's content
          Object.defineProperty(obj, prop, {
            value: val,
            writable: true,
            enumerable: true,
            configurable: true,
          })

          // and set it's value
          obj[prop] = val
          this.driver.setItem(this.addPrefix(key), this.toJSON(obj, options))
        }
      }
    } catch (e) {
      this.throwError(e)
    }
  }

  remove (key, prop) {
    try {
      if (!prop) {
        this.driver.removeItem(this.addPrefix(key))
      } else {
        const data = this.get(key)

        // if the property exists
        if (data && hasOwnProperty.call(data, prop)) {
          // remove thew prop within from key's content
          delete data[prop]
          this.set(key, data)
        }
      }
    } catch (e) {
      this.throwError(e)
    }
  }

  clear (force = false) {
    try {
      if (force) {
        this.driver.clear()
      } else {
        const keys = this.keys().filter((key) => key.startsWith(this.options.prefix || ''))
        keys.forEach((key) => this.remove(this.removePrefix(key)))
      }
    } catch (e) {
      this.throwError(e)
    }
  }

  has (key) {
    if (this.options.driver !== StorageDriver.MEMORY) {
      return (this.addPrefix(key) in this.driver)
    }
    return (this.addPrefix(key) in this.driver.storage)
  }

  key (index) {
    try {
      const key = this.keys()[index]
      return this.get(this.removePrefix(key))
    } catch (e) {
      this.throwError(e)
    }
  }

  keys () {
    if (this.options.driver !== StorageDriver.MEMORY) {
      return Object.keys(this.driver)
    }
    return Object.keys(this.driver.storage)
  }

  addPrefix (key) {
    return `${this.options.prefix || ''}${key}`
  }

  removePrefix (key) {
    const re = new RegExp(`^${this.options.prefix || ''}`)
    return key.replace(re, '')
  }

  toJSON (data, options = {}) {
    const ttl = ('ttl' in options) ? options.ttl : this.options.ttl
    const compressData = 'compressData' in options && options.compressData === true
    const stringifyData = JSON.stringify(data)
    data = compressData ? compress(stringifyData) : data

    return JSON.stringify({
      value: data,
      compressed: compressData,
      ttl: ttl > 0 ? ttl + Date.now() : 0,
    })
  }

  fromJSON (key) {
    try {
      const data = JSON.parse(this.driver.getItem(key))
      if (data !== null) {
        if (('ttl' in data) &&
          Number(data.ttl) > 0 &&
          Number(data.ttl) < Date.now()) {
            this.remove(this.removePrefix(key))
            return null
        }

        if ('value' in data) {
          const dataValue = data.compressed ? JSON.parse(decompress(data.value)) : data.value
          return dataValue // typeof dataValue === 'string' ? JSON.parse(dataValue) : dataValue
        }
        return data.compressed ? decompress(data) : data
      }
      return null
    } catch (e) {
      return null
    }
  }

  throwError (e) {
      throw new StorageError(`${this.name}[${this.version}]: ${e.message}`, e.stack)
  }
}

class Vue2Storage$1 {
  static install (Vue, options) {
    const storage = new Vue2Storage(options)
    Vue.$storage = storage
    Vue.prototype.$storage = storage
  }
}

if (typeof window !== 'undefined') {
  // done this way so we can use this from the DevTools console.
  const options = {
    prefix: 'FV_',
    driver: 'local',
    ttl: 0,
  }
  const storage = new Vue2Storage(options)
  window.Vue2Storage = storage
}

export default Vue2Storage$1
export { Vue2Storage$1 as Vue2Storage }
