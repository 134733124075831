export const state = {
  preferencesInitialized: false,
}

export const mutations = {
  INITIALIZE_PREFERENCES (state, preferences) {
    state.preferencesInitialized = true
  },
  SET_PREFERENCES_INITIALIZED (state, preferencesInitialized) {
    state.preferencesInitialized = preferencesInitialized
  },
}

export const getters = {}

export const actions = {
  setPreferencesInitialized ({ commit }, preferencesInitialized) {
    commit('SET_PREFERENCES_INITIALIZED', preferencesInitialized)
  },
}
