import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"360","overlay-opacity":".75"},model:{value:(_vm.welcomeDialog),callback:function ($$v) {_vm.welcomeDialog=$$v},expression:"welcomeDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_c('h1',[_vm._v("Login Successful!")])]),_c(VCardText,{staticClass:"py-1"},[_c('p',[_vm._v(" You are now set up to use the FieldVue application as a "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.userRoleName))]),_vm._v(" based on your Job Title. Please contact the Help Desk if you need to change this Role. ")]),_c('p',[_vm._v("Click below to set up your Team members.")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"accent","width":"300"},on:{"click":function($event){return _vm.goToMyTeam()}}},[_vm._v(" Get Started ")]),_c(VSpacer)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }