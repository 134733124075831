<template>
  <v-card
    class="v-card--material px-2 pt-2 pb-0 my-0 mt-0 v-card--material-stats v-card--material--has-heading"
    elevation="5"
    :ripple="false"
  >
    <!-- Data Unavailable Message -->
    <div
      v-if="dataAvailabilityState.length > 0"
      :style="`height: ${tabbed ? '193' : '230'}px; width: 100%; overflow: hidden;`"
    >
      <v-col
        cols="12"
        class="text-center py-0"
        style="height: 175px;"
      >
        <div
          class="black--text d-flex justify-center text-h4"
        >
          {{ title }}
        </div>
        <v-progress-circular
          v-if="dataAvailabilityState === 'loading data'"
          :size="70"
          :width="7"
          class="my-5"
          color="primary"
          indeterminate
        />
        <div
          :class="`black--text d-flex ${dataAvailabilityState === 'no team' || dataAvailabilityState === 'loading error' ? 'fill-height' : ''} align-center justify-center text-h4 mb-5`"
        >
          {{ dataUnavailableMessage }}
        </div>
      </v-col>
    </div>
    <div
      v-else
      class="d-flex grow flex-wrap flex-grow-0"
      :style="`cursor: pointer; height: ${tabbed ? '193' : '230'}px; width: 100%; overflow: hidden;`"
      @click="openDialog"
    >
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <div
          class="black--text d-flex justify-center text-h4"
        >
          {{ title }}
        </div>

        <doughnut-chart
          v-if="chartType === 'DoughnutChart'"
          :chartdata="dataset"
          :options="options"
          :force-rerender="forceRerender"
          :class="tabbed ? 'wrapper-tabbed' : 'wrapper'"
        />

        <vertical-bar-chart
          v-if="chartType === 'VerticalBarChart'"
          :chartdata="dataset"
          :options="options"
          :force-rerender="forceRerender"
          :class="tabbed ? 'wrapper-tabbed' : 'wrapper'"
        />
      </v-col>

      <div
        v-if="$slots['after-heading']"
        class="ml-1"
      >
        <slot name="after-heading" />
      </div>
    </div>

    <v-col
      cols="12"
      class="px-0 pb-0"
    >
      <v-divider />
    </v-col>

    <v-card-actions class="mb-0 py-0">
      <slot name="actions" />
      <v-spacer />
      <slot name="reveal-actions" />
    </v-card-actions>
    <v-dialog
      v-model="dialog"
      fullscreen
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card
        style="overflow: hidden;"
        class="fullscreen-dialog"
      >
        <v-toolbar
          dark
          color="primary"
          class="dialogHeader"
        >
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click.stop="dialog = false"
          >
            <v-icon>
              fa-regular fa-circle-xmark
            </v-icon>
          </v-btn>
        </v-toolbar>
        <div
          class="chart-wrapper"
        >
          <doughnut-chart
            v-if="chartType === 'DoughnutChart'"
            :chartdata="dataset"
            :options="options"
            :force-rerender="forceRerender"
            class="fullscreen-chart pa-4"
          />
          <vertical-bar-chart
            v-if="chartType === 'VerticalBarChart'"
            :chartdata="dataset"
            :options="fullScreenOptions ? fullScreenOptions : options"
            :force-rerender="forceRerender"
            class="fullscreen-chart pa-4"
            :style="`min-width: ${minBarChartWidth}`"
          />
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import DoughnutChart from '@/components/infrastructure/Charts/Base/DoughnutChart'
  import VerticalBarChart from '@/components/infrastructure/Charts/Base/VerticalBarChart'

  export default {
    name: 'MaterialChartCard',
    components: {
      DoughnutChart,
      VerticalBarChart,
    },
    props: {
      chartType: {
        type: String,
        default: '',
      },
      dataset: null,
      options: null,
      fullScreenOptions: null,
      forceRerender: null,
      tabbed: {
        type: Boolean,
        default: false,
      },
      avatar: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: 'success',
      },
      hoverReveal: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: undefined,
      },
      image: {
        type: Boolean,
        default: false,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      loadingMessage: {
        type: String,
        default: 'Loading...',
      },
      dataAvailabilityState: {
        type: String,
        default: 'no team',
      },
      dataUnavailableMessage: {
        type: String,
        default: 'No team members were found.',
      },
    },
    data () {
      return {
        dialog: false,
      }
    },
    computed: {
      classes () {
        return {
          'v-card--material--has-heading': this.hasHeading,
          'v-card--material--hover-reveal': this.hoverReveal,
        }
      },
      hasHeading () {
        return Boolean(this.$slots.heading || this.title || this.icon)
      },
      hasAltHeading () {
        return Boolean(this.$slots.heading || (this.title && this.icon))
      },
      minBarChartWidth () {
        let width = ''
        if (this.dataset.labels.length > 15) {
          width = (400 + ((this.dataset.labels.length - 15) * 250)) + 'px'
        }
        return String(width)
      },
    },
    methods: {
      openDialog () {
        this.dialog = true
        const application = this.title + ' Widget'
        this.$azureLogger.writeLogItem('activity', {
          Application: application,
          FeatureName: 'Fullscreen chart',
          ActivityType: this.$store.getters.loggingActivityType.clicked,
          AdditionalDetails: '',
        })
      },
    },
  }
</script>

<style lang="sass">
.wrapper
  position: relative
  top: 0px
  height: 200px
  z-index: 1

.wrapper-tabbed
  position: relative
  top: 0px
  height: 160px
  z-index: 1

.fullscreen-dialog
  .chart-wrapper
    height: 100%
    width: 100%
    overflow-x: auto
  .fullscreen-chart
    position: relative
    top: 0px
    height: 100%
    z-index: 1

.v-card--material-chart
  p
    color: #999

  .v-card--material__heading
    max-height: 185px

    .ct-label
      color: inherit
      opacity: .7
      font-size: 0.975rem
      font-weight: 100

    .ct-grid
      stroke: rgba(255, 255, 255, 0.2)

    .ct-series-a .ct-point,
    .ct-series-a .ct-line,
    .ct-series-a .ct-bar,
    .ct-series-a .ct-slice-donut
        stroke: rgba(255,255,255,.8)

    .ct-series-a .ct-slice-pie,
    .ct-series-a .ct-area
        fill: rgba(255,255,255,.4)
</style>
