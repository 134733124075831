<template>
  <div>
    <v-app-bar
      id="appBar"
      app
      :clipped-left="clipped"
      dense
      :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
      flat
      v-bind="$attrs"
    >
      <template #img="{ props }">
        <v-img
          v-bind="props"
          gradient="to bottom, rgba(5, 39, 68, 1), rgba(5, 39, 68, 1)"
        />
      </template>
      <v-btn
        v-if="platform !== 'mobile'"
        class="mr-5"
        color="transparent"
        elevation="0"
        fab
        small
        @click="toggleDrawerMiniVariant()"
      >
        <v-icon v-if="drawerMiniVariant">
          fa-regular fa-chevrons-right
        </v-icon>

        <v-icon v-else>
          fa-regular fa-chevrons-left
        </v-icon>
      </v-btn>
      <v-btn
        v-if="platform === 'mobile'"
        class="mr-0"
        color="transparent"
        elevation="0"
        fab
        small
        @click="toggleDrawerOverlays()"
      >
        <v-icon v-if="!drawerState">
          fa-regular fa-chevrons-right
        </v-icon>

        <v-icon v-else>
          fa-regular fa-chevrons-left
        </v-icon>
      </v-btn>
      <v-toolbar-title>
        <span
          class="logo-normal"
          :style="platform === 'mobile' ? 'margin-left: 0 !important; padding-left: 0 !important;' : ''"
        >
          <v-img
            class="field-vue-name"
            src="@/assets/2022-Osmose-FieldVue-logo-343-white.png"
          />
        </span>
      </v-toolbar-title>
      <v-spacer />
      <v-menu
        offset-y
        :disabled="supportMenuDisabled"
      >
        <template #activator="{ on, attrs }">
          <v-chip
            class="ma-1"
            color="transparent"
            :style="platform === 'mobile' ? 'padding-left: 4px !important; padding-right: 4px !important;' : ''"
            elevation="1"
            label
            large
            v-bind="attrs"
            :disabled="!isOnline"
            v-on="on"
          >
            <v-toolbar-title
              class="font-weight-light dark-blue-text"
            >
              <span
                v-show="platform !== 'mobile'"
                id="support"
              >
                Support
              </span>
              <v-icon
                class="ml-1 mt-1"
              >
                fa-regular fa-chevron-down
              </v-icon>
            </v-toolbar-title>
          </v-chip>
        </template>
        <v-list
          dense
        >
          <v-container class="ma-0 pa-0">
            <v-subheader
              class="text-h6 font-weight-bold mb-0 pb-0"
              style="height: 25px"
            >
              FieldVue Support
            </v-subheader>
            <v-divider class="pa-0 ma-0" />
          </v-container>
          <report-an-issue
            :platform="platform"
            @close-menu="closeSupportMenu"
          />
          <feature-request
            :platform="platform"
            @close-menu="closeSupportMenu"
          />
          <user-support
            :platform="platform"
            @close-menu="closeSupportMenu"
          />
        </v-list>
      </v-menu>
      <v-menu
        offset-y
      >
        <template #activator="{ on, attrs }">
          <v-chip
            v-show="platform !== 'mobile'"
            class="ma-1"
            color="transparent"
            elevation="1"
            label
            large
            v-bind="attrs"
            :disabled="!isOnline"
            v-on="on"
          >
            <v-toolbar-title
              class="font-weight-light dark-blue-text"
            >
              <span id="usefulLinks">Useful Links</span>
              <v-icon
                class="ml-1 mt-1"
              >
                fa-regular fa-chevron-down
              </v-icon>
            </v-toolbar-title>
          </v-chip>
        </template>
        <v-list
          dense
        >
          <template v-for="(usefulLink, index) in usefulLinks">
            <v-container
              v-if="usefulLink.Title"
              :key="index"
              class="ma-0 pa-0"
            >
              <v-subheader
                class="text-h6 font-weight-bold mb-0 pb-0"
                style="height: 25px"
              >
                {{ usefulLink.Title }}
              </v-subheader>
              <v-divider
                class="pa-0 ma-0"
              />
            </v-container>
            <v-list-item
              v-for="(child, idx) in usefulLink.Links"
              :key="idx + child.Title"
              :href="child.LinkUrl"
              target="_blank"
              @click="logActivity(child.Title, child.IsLogged)"
            >
              <v-list-item-title>{{ child.Title }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <sync-badge />
      <span
        v-if="taskSyncCounter !== 0 && isOnline"
      >
        <span class="fa-stack fa-1x">
          <i
            class="fa fa-circle fa-stack-1x"
            style="color: green; font-size: 26px"
          />
          <i
            class="fa fa-refresh fa-spin fa-stack-1x"
            style="color: white; font-size: 15px"
          />
        </span>
      </span>
      <fv-notification-list />
      <span
        class="ml-0"
      >
        <v-icon
          v-if="!isOnline"
          color="red"
        >
          fa-regular fa-wifi-exclamation
        </v-icon>
        <v-icon
          v-else
          color="green"
        >
          fa-regular fa-wifi
        </v-icon>
      </span>

      <v-menu
        bottom
        :close-on-content-click="closeOnContentClick"
        left
        min-width="150"
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template #activator="{ attrs, on }">
          <v-chip
            :class="`ma-1 ${platform === 'tablet' ? 'disable-hover' : ''}`"
            color="transparent"
            elevation="1"
            label
            large
            v-bind="attrs"
            v-on="on"
          >
            <v-toolbar-title
              class="hidden-sm-and-down font-weight-light dark-blue-text"
            >
              <span id="userName">{{ userName }}</span>
            </v-toolbar-title>
            <v-avatar
              right
              size="150"
            >
              <v-icon>
                fa-circle-user
              </v-icon>
            </v-avatar>
          </v-chip>
        </template>
        <v-list
          :tile="false"
          flat
          dense
        >
          <app-bar-item
            class="pa-0"
          >
            <user-profile
              class="pl-3"
            />
          </app-bar-item>
          <app-bar-item
            v-if="canImpersonate && !impersonatingUser"
            class="pa-0"
          >
            <impersonate-a-user
              class="pl-3"
            />
          </app-bar-item>
          <app-bar-item
            v-if="impersonatingUser"
            class="pa-0"
          >
            <end-impersonate-a-user
              class="pl-3"
            />
          </app-bar-item>
          <app-bar-item
            class="pa-0"
          >
            <refresh-app-data
              class="pl-3"
            />
          </app-bar-item>
          <app-bar-item
            class="pa-0"
            :disabled="!isOnline"
          >
            <v-list-item-title
              :class="`${isOnline ? 'pl-3 pr-3 pt-3 pb-3' : 'pl-3 pr-3 pt-3 pb-3 grey--text'}`"
              @click="logUserOut"
            >
              Log Out
            </v-list-item-title>
          </app-bar-item>
          <app-bar-item
            class="pa-0"
          >
            <about
              class="pl-3"
            />
          </app-bar-item>
          <app-bar-item
            v-if="installPrompt"
            class="pa-0"
          >
            <v-list-item-title
              class="pl-3 pr-3 pt-3 pb-3"
              @click="installApp"
            >
              {{ installText }}
            </v-list-item-title>
          </app-bar-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import About from './About.vue'
import ApplicationControlStore from '@/store/Modules/ApplicationControlStore.js'
import Bowser from 'bowser'
import EndImpersonateAUser from './EndImpersonateAUser.vue'
import FeatureRequest from './FeatureRequest.vue'
import fvEnvVars from '@/fvEnvVars'
import ImpersonateAUser from './ImpersonateAUser.vue'
import RefreshAppData from './RefreshAppData.vue'
import ReportAnIssue from './ReportAnIssue.vue'
import SyncBadge from './SyncBadge.vue'
import UserProfile from './UserProfile.vue'
import UserSupport from './UserSupportDialog.vue'
import { B2CManager } from '@/Lib/B2CManager.js'
import { DataSyncManager } from '@/Lib/DataSyncManager'
import LinkManager from '@/Lib/LinkManager.js'
import { mapGetters, mapState } from 'vuex'
import { VHover, VListItem } from 'vuetify/lib'

export default {
  components: {
    AppBarItem: {
      render (h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default,
              )
            },
          },
        })
      },
    },
    FeatureRequest,
    ReportAnIssue,
    UserSupport,
    UserProfile,
    ImpersonateAUser,
    EndImpersonateAUser,
    RefreshAppData,
    About,
    SyncBadge,
  },
  mixins: [B2CManager],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    installPrompt: {
      type: Event,
      default: null,
    },
  },
  data: () => ({
    clipped: false,
    closeOnContentClick: true,
    contactURL: '',
    left: false,
    supportMenuDisabled: false,
    installText: '',
    notifications: [
      'Your team has 5 incomplete tasks',
      'Another Notification',
      'Another one',
    ],
    usefulLinks: [],
  }),
  computed: {
    ...mapGetters(['impersonatingUser']),
    ...mapState({
      preferencesInitialized: (state) => state.applicationPreferencesStore.preferencesInitialized,
      isOnline: (state) => state.applicationCoreStore.isOnline,
      taskSyncCounter: (state) => state.applicationCoreStore.taskSyncCounter,
    }),
    platform () {
      return Bowser.getParser(window.navigator.userAgent).parsedResult.platform.type
    },
    barColor: {
      get () {
        return this.$store.getters.getBarColor
      },
    },
    drawerOverlays: {
      get () {
        return this.$store.getters.getDrawerOverlays
      },
      set (payload) {
        return this.$store.commit('SET_DRAWER_OVERLAYS', payload)
      },
    },
    drawerState: {
      get () {
        return this.$store.getters.getDrawerState
      },
      set (payload) {
        return this.$store.commit('SET_DRAWER_STATE', payload)
      },
    },
    drawerMiniVariant: {
      get () {
        return this.$store.getters.getDrawerMiniVariant
      },
      set (payload) {
        return this.$store.commit('SET_DRAWER_MINI_VARIANT', payload)
      },
    },
    canImpersonate () {
      return this.$store.getters.hasCapability('Impersonation', 'Access', this.$store.getters.currentProject)
    },
    selectedProjectName: function () {
      return this.currentProject ? this.currentProject.projectName : ''
    },
    hasProject () {
      if (this.$store.getters.hasProject > 0) return true
      else {
        return false
      }
    },
    currentTargets: function () {
      if (this.$store.getters.userApplications) {
        const returnVal = this.$store.getters.userApplications
          .filter((display) => display.userDropDownDisplay)
          .slice()
          .sort((a, b) => a.serialNumber.localeCompare(b.serialNumber))
        return returnVal
      } else {
        return this.$store.getters.userApplications
      }
    },
  },
  watch: {
    async preferencesInitialized (newValue) {
      if (newValue === true) {
        this.getUsefulLinks()
      }
    },
  },
  async created () {
    if (!this.$store.hasModule('ApplicationControlStore')) {
      this.$store.registerModule('ApplicationControlStore', ApplicationControlStore)
    }
  },
  mounted () {
    this.contactURL = fvEnvVars[location.host].VUE_APP_ContactURL
    this.installText =
      this.platform === 'mobile'
        ? 'Install App on Home Screen'
        : 'Install App on Desktop'
  },
  methods: {
     async getUsefulLinks () {
      this.usefulLinks = await LinkManager.getLinks()
    },
    async logUserOut () {
      if (this.isOnline) {
        this.$store.dispatch('setLoggedOut', true)
        if (this.$store.getters.impersonatingUser) {
          const dataSyncManager = new DataSyncManager()
          await dataSyncManager.removeImpersonatedTasks()
        }
        this.logOut()
      }
    },
    toggleDrawerOverlays () {
      this.drawerOverlays = !this.drawerOverlays
      this.drawerState = !this.drawerState
      this.drawerMiniVariant = false
    },
    toggleDrawerMiniVariant () {
      this.drawerMiniVariant = !this.drawerMiniVariant
    },
    logActivity (app, isLogged) {
      if (isLogged) {
        this.$azureLogger.writeLogItem('activity', {
          Application: 'Useful Links',
          FeatureName: app,
          ActivityType: this.$store.getters.loggingActivityType.navigation,
          AdditionalDetails: '',
        })
      }
    },
    closeSupportMenu () {
      setTimeout(() => {
        this.supportMenuDisabled = true
      }, 10)
      setTimeout(() => {
        this.supportMenuDisabled = false
      }, 10)
    },
    async installApp () {
      this.installPrompt.prompt()
      const { outcome } = await this.installPrompt.userChoice
      if (outcome === 'accepted') {
        this.installPrompt = null
      }
    },
  },
}
</script>

<style>
.v-chip .v-icon {
  font-size: 20px;
}

.syncBadge > .v-badge__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.syncBadge > .v-badge__wrapper > span {
  inset: 0 !important;
  font-size: 12px !important;
  padding: 0 !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable-hover::before {
  display: none;
}

</style>
