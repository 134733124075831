import { referenceDataMixin } from '@/mixins/referenceDataMixin'
import RoleMapGridManager from '@/Lib/RoleMapGridManager'
import SafetyQualificationsManager from '@/Lib/SafetyQualificationsManager'
import Store from '@/store/store'
import VehicleManager from '@/Lib/VehicleManager'

export const allRefDataWorker = async (params) => {
  await updateAllReferenceData(params)
}
export const volatileRefDataWorker = async (params) => {
  updateVolatileReferenceData(params)
}

// console logs will be removed once we are satisfied with the logic flow
async function updateAllReferenceData (params) {
  try {
    Store.dispatch('updateTaskSyncExecuting', true)

    await VehicleManager.getDrivers()
    await RoleMapGridManager.getFromApi('JobCodes')
    await SafetyQualificationsManager.getSafetyQualificationConfig()
    // console.log('Getting QC Version')
    // await referenceDataMixin.methods.getQCVersionFromServer()
    // console.log('Evaluating the Reference Data')
    await referenceDataMixin.methods.getAllRefData()
    await referenceDataMixin.methods.getJobInfoRefData()
    // console.log('Done Evaluating the Reference Data')
    await referenceDataMixin.methods.getReferenceDataFromServiceNow('trucks')
    await referenceDataMixin.methods.getReferenceDataFromServiceNow('trailers')
    await referenceDataMixin.methods.getReferenceDataFromServiceNow('preservatives')
    // console.log('Done Evaluating the ServiceNow Reference Data')
    Store.dispatch('updateTaskSyncExecuting', false)
    return
  } catch (err) {
    Store.$app.$azureLogger.writeLogItem('error', {
      Application: 'refData web worker',
      FeatureName: 'refdata.worker.js -> updateAllReferenceData()',
      ErrorCode: err.name,
      ErrorDescription: 'Failed to get reference data.',
      AdditionalDetails: `Message: ${err.message}. Stack ${err.stack}`,
    })
  }
}

// console logs will be removed once we are satisfied with the logic flow
async function updateVolatileReferenceData (params) {
  try {
    Store.dispatch('updateTaskSyncExecuting', true)
    // console.log('Getting QC Version')
    // await referenceDataMixin.methods.getQCVersionFromServer()
    // console.log('Updating Volatile Reference Data')
    await referenceDataMixin.methods.getAllRefData()
    // console.log('Done Updating Volatile Reference Data')
    Store.dispatch('updateTaskSyncExecuting', false)
    return
  } catch (err) {
    Store.$app.$azureLogger.writeLogItem('error', {
      Application: 'refData web worker',
      FeatureName: 'refdata.worker.js -> updateVolatileReferenceData()',
      ErrorCode: err.name,
      ErrorDescription: 'Failed to get reference data.',
      AdditionalDetails: `Message: ${err.message}. Stack ${err.stack}`,
    })
  }
}
