import Axios from 'axios'
import Store from '@/store/store'
import SnakManager from '@/Lib/SnakManager'

export default {
  async postIncident (incident) {
    const URL = `${Store.getters.serviceNowBaseURL}fmt/CreateHelpDeskIncident`
    const snak = await SnakManager.getSNAK(Store.$app.$storage)

    try {
      const response = await Axios.post(URL, incident,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${snak}`,
          },
        })
      return response
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'Report an Issue',
        FeatureName: 'postIncident()',
        ErrorCode: 'Axios Error',
        ErrorDescription: 'Failed to post incident to Service Now.',
        AdditionalDetails: `Message: ${typeof error.message === 'object' && error.message !== null ? JSON.stringify(error.message) : error.message}. Stack: ${error.stack}`,
      })
    }
  },
}
