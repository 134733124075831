<template>
  <div
    v-show="computedMobileDisplay"
  >
    <fv-external-link
      v-if="item.NavType === 'ExtLink'"
      :title="item.Title"
      :item="item"
    />
    <fv-dynamic-link
      v-else
      :item="item"
      :link-level="linkLevel"
      :target-componunt="item.Title"
      :target-path="item.to"
      :entry-point="item.EntryPoint"
    />
  </div>
</template>

<script>
  import Bowser from 'bowser'
  import Themeable from 'vuetify/lib/mixins/themeable'
  export default {
    name: 'NavItem',

    mixins: [Themeable],

    props: {
      item: {
        type: Object,
        default: () => ({
          linkUrl: undefined,
          navIcon: undefined,
          navIconDisplay: undefined,
          subtitle: undefined,
          title: undefined,
          to: undefined,
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
      linkLevel: {
        type: String,
        default: 'top',
      },
    },
    computed: {
      computedMobileDisplay () {
        const platform = Bowser.getParser(window.navigator.userAgent).parsedResult.platform.type
        if (!this.item.AvailableOnPhone && platform === 'mobile') {
          return false
        } else return true
      },
      computedText () {
        if (!this.item || !this.item.title) return ''

        let text = ''

        this.item.Title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },
      href () {
        return this.item.Href || (!this.item.to ? '#' : undefined)
      },
    },
  }
</script>

<style lang="sass">
.v-list-item
  text-align: left
.v-icon
  float: right
  font-size: 12px
</style>
