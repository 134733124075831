import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"id":"fvModal","persistent":"","no-close-on-esc":"","no-close-on-backdrop":""},model:{value:(_vm.Visibility),callback:function ($$v) {_vm.Visibility=$$v},expression:"Visibility"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_c('h3',{staticClass:"text-h3"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c(VCardActions,[_c(VSpacer),(!_vm.hideCancel)?_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.updateVisibility}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")]):_vm._e(),(!_vm.hideOkay)?_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.updateVisibility}},[_vm._v(" "+_vm._s(_vm.okayText)+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }