<template>
  <v-main>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        justify="center"
        align="center"
      >
        <v-dialog
          v-model="welcomeDialog"
          persistent
          max-width="360"
          overlay-opacity=".75"
        >
          <v-card>
            <v-card-title class="justify-center">
              <h1>Login Successful!</h1>
            </v-card-title>
            <v-card-text
              class="py-1"
            >
              <p>
                You are now set up to use the FieldVue application as a <span class="font-weight-bold">{{ userRoleName }}</span> based on your Job Title. Please contact the Help Desk if you need to change this Role.
              </p>
              <p>Click below to set up your Team members.</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="accent"
                width="300"
                @click="goToMyTeam()"
              >
                Get Started
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    props: {
      apptabs: Array,
    },
    data () {
      return {
        welcomeDialog: true,
      }
    },
    computed: {
      userRoleName () {
        return this.$store.getters.userRoleName
      },
    },
    methods: {
      goToMyTeam () {
        this.$router.push('/myteam')
      },
    },
  }
</script>

<style scoped>
</style>
