import Axios from 'axios'
import Store from '@/store/store'

export default {
  getApiClient () {
    const apiClient = Axios.create({
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${Store.getters.authToken}`,
        dataType: 'json',
        contentType: 'application/json',
        crossDomain: true,
      },
    })
    return apiClient
  },
  async validatSupportUserNamePassword (userName, password) {
    if (userName.trim().length > 0 && password.trim().length > 0) {
      const cData = await this.getCipherData()
      if (cData) {
        const cipherResult = await this.encryptPassword(password, cData)
        cipherResult.userName = userName
        if (await this.sendSupportUserData(cipherResult) === true) {
          return true
        }
      }
    }
    return false
  },
  async sendSupportUserData (cipherdata) {
    const apiClient = this.getApiClient()
    const URL = Store.getters.azureProxyBaseURL + 'FieldVue/DecodeText'
    try {
      const response = await apiClient.post(URL, cipherdata)
      return response.data
    } catch (err) {
      console.error(err)
      return false
    }
  },
  async getCipherData () {
    const apiClient = this.getApiClient()
    const URL = Store.getters.azureProxyBaseURL + 'FieldVue/Cipher'
    try {
      const response = await apiClient.get(URL)
      return response.data
    } catch (err) {
      return false
    }
  },
  // From here down was taken from: https://pilabor.com/series/dotnet/js-gcm-encrypt-dotnet-decrypt/
  // generates a 128 bit key for encryption and decryption in base64 format
  async getKey (cipherData) {
    const key = cipherData.Item1
    const iv = new Uint8Array(this.base64ToBuffer(cipherData.Item2))

    var result = {
        key: key,
        iv: iv,
    }
    return result
  },
  // arrayBuffer to base64
  bufferToBase64 (arrayBuffer) {
    return window.btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
  },
  // load a base64 encoded key
  async loadKey (base64Key) {
    return await window.crypto.subtle.importKey('raw', this.base64ToBuffer(base64Key), 'AES-GCM', true, ['encrypt', 'decrypt'])
  },
  // base64 to arrayBuffer
  base64ToBuffer (base64) {
    const binaryString = window.atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes.buffer
  },
  async cryptGcm (base64Key, bytes, iv) {
    const key = await this.loadKey(base64Key)
    const algorithm = { iv, name: 'AES-GCM' }
    const cipherData = await window.crypto.subtle.encrypt(algorithm, key, bytes)

    // prepend the random IV bytes to raw cipherdata
    const cipherText = this.concatArrayBuffers(iv.buffer, cipherData)
    return this.bufferToBase64(cipherText)
  },
  // concatenate two array buffers
  concatArrayBuffers (buffer1, buffer2) {
    const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength)
    tmp.set(new Uint8Array(buffer1), 0)
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength)
    return tmp.buffer
  },
  async encryptPassword (textToEncrypt, cipherData) {
    const plaintextBytes = (new TextEncoder()).encode(textToEncrypt, 'utf-8')
    const encryptionKeys = await this.getKey(cipherData)
    const ciphertext = await this.cryptGcm(encryptionKeys.key, plaintextBytes, encryptionKeys.iv)

    var resultData = {
        cipherText: ciphertext,
        key: encryptionKeys.key,
      }
    return resultData
  },
}
