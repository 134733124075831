import { employeeDataWorker } from '@/workers/employeeRefData.worker'
import { allRefDataWorker, volatileRefDataWorker } from '@/workers/refdata.worker'
import { submitTaskDataWorker, syncTaskDataWorker, removeImpersonatedTasksDataWorker, updateSyncBadge } from '@/workers/taskData.worker'
import PreferencesManager from '@/components/dynamic/ApplicationPreferences/LocalLib/ApplicationPreferencesManager'
import Store from '@/store/store'

class DataSyncManager {
  async updateActiveEmployees (params) {
    // console.log('Evaluating the Active Employee List')
    // Web Worker -> get/update the list of active employees in indexedDb
    const employees = await employeeDataWorker(params)
    // console.log('Done Evaluating the Active Employee List')
    // check to see if the user has a team, if so, are any members missing
    let missingTeamMembers = []
    if (!params.firstTimeUser) {
      missingTeamMembers = await this.#checkForInactiveTeamMembers(employees)
      //remove inactive team member from user's team
      this.#removeTeamMembersFromTeam(missingTeamMembers)
    }
    return missingTeamMembers
  }

  async updateAllReferenceData (params) {
    if (Store.getters.isOnline) {
      // Web Worker -> get the current reference datas
      await allRefDataWorker(params)
    }
  }

  async updateSyncBadge () {
    await updateSyncBadge()
  }

  async updateVolatileReferenceData (params) {
    if (Store.getters.isOnline) {
      // Web Worker -> get the newest employee subcategories
      volatileRefDataWorker(params)
    }
  }

  async syncTaskData (employeeId) {
    if (Store.getters.isOnline) {
      // deal with any tasks needing be submitted/updated or deleted
      await this.submitTaskData()
      // Web Worker -> update the the two week period of tasks
      syncTaskDataWorker(employeeId)
    }
  }

  // Web Worker -> get the form data from indexedDb, then send the form data to service now
  async submitTaskData () {
    if (Store.getters.isOnline) {
      await submitTaskDataWorker()
    }
  }

  // This is used when impersonating a user
  // If you only want to delete the tasks of the user that was impersonated
  // the employeeId is provided
  // ********************************************************
  async removeImpersonatedTasks (employeeId) {
    if (Store.getters.isOnline) {
      await removeImpersonatedTasksDataWorker(employeeId)
    }
  }

  async #checkForInactiveTeamMembers (employees) {
    if (employees && employees.length > 0) {
      const projectId = Store.getters.currentProject.ProjectId
      const appId = Store.getters.getUserApplicationId('My Team')
      const hasTeam = PreferencesManager.hasPreference({
        projId: projectId,
        appId: appId,
        key: 'MyTeam',
      })

      if (hasTeam) {
        const tempTeam = PreferencesManager.getPreference({
          projId: projectId,
          appId: appId,
          key: 'MyTeam',
        }).value
          return tempTeam.reduce((accum, teamMember) => {
              const employee = employees.find(
                ({ EmployeeId }) => teamMember.EmployeeId === EmployeeId,
              )
              if (!employee) {
                teamMember.Name = `${teamMember.Name}`
                accum.push({
                  ...{ Name: `Employee (${teamMember.EmployeeId}) not found` },
                  ...teamMember,
                })
              }
              return accum
            }, [])
      }
    }
   }

  // eslint-disable-next-line
  #removeTeamMembersFromTeam (inactiveTeamMembers) {
    const projectId = Store.getters.currentProject.ProjectId
    const appId = Store.getters.getUserApplicationId('My Team')
    const hasTeam = PreferencesManager.hasPreference({
      projId: projectId,
      appId: appId,
      key: 'MyTeam',
    })

    if (hasTeam) {
      let modifiedTeam = PreferencesManager.getPreference({
          projId: projectId,
          appId: appId,
          key: 'MyTeam',
        }).value

      inactiveTeamMembers.forEach((inactiveMember) => {
          modifiedTeam = this.#remove(modifiedTeam, 'Name', inactiveMember.Name)
      })

      PreferencesManager.setPreference({
          projId: projectId,
          appId: appId,
          key: 'MyTeam',
          value: modifiedTeam,
          userCanEdit: true,
        })
      PreferencesManager.saveApplicationPreferencesToTheCloud()
    }
  }

  // eslint-disable-next-line
  #remove (array, key, value) {
    const index = array.findIndex(obj => obj[key] === value)
    return index >= 0 ? [
        ...array.slice(0, index),
        ...array.slice(index + 1),
    ] : array
  }
}

export { DataSyncManager }
