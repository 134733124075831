<template>
  <div />
</template>

<script>
  import Bowser from 'bowser'
  import { NavigationHelperMixin } from '@/mixins/NavigationHelperMixin.js'

  export default {
    mixins: [NavigationHelperMixin],
    props: {
      targetComponunt: String,
      targetNavType: String,
      targetPath: String,
      linkUrl: String,
      entryPoint: String,
    },
    computed: {
      platform () {
        return Bowser.getParser(window.navigator.userAgent).parsedResult.platform.type
      },
      activeComponunt () {
        return () => import(`../components/dynamic/${this.entryPoint}`)
      },
      drawerState: {
        get () {
          return this.$store.getters.getDrawerState
        },
        set (payload) {
          return this.$store.commit('SET_DRAWER_STATE', payload)
        },
      },
      drawerMiniVariant: {
        get () {
          return this.$store.getters.getDrawerMiniVariant
        },
        set (payload) {
          return this.$store.commit('SET_DRAWER_MINI_VARIANT', payload)
        },
      },
    },
    methods: {
      cardNavigate: function () {
        // Closes the navDrawer overlay on mobile when the user navigates
        if (this.platform === 'mobile') {
          this.drawerState = false
        }
        this.$azureLogger.writeLogItem('activity', {
          Application: this.targetComponunt,
          FeatureName: '',
          ActivityType: this.$store.getters.loggingActivityType.navigation,
          AdditionalDetails: '',
        })

        if (!this.hasRoute(this.targetComponunt)) {
          this.$router.addRoute({
            path: `/${this.targetPath}`,
            name: this.targetComponunt,
            component: this.activeComponunt,
          })
        }

        this.pushRouteTo({ path: this.targetPath })
      },
      hasRoute (routeName) {
        let retVal = false
        const routes = this.$router.getRoutes()
        routes.forEach((route) => {
          if (route.name === routeName) {
            retVal = true
          }
        })
        return retVal
      },
    },
  }
</script>
<style></style>
