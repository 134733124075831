<template>
  <v-card
    class="v-card--material px-2 pt-2 pb-0 my-0 mt-0 v-card--material-stats v-card--material--has-heading"
    elevation="5"
  >
    <div
      v-if="dataAvailabilityState.length > 0"
      style="height: 230px; width: 100%; overflow: hidden;"
    >
      <v-col
        cols="12"
        class="text-center py-0"
        style="height: 175px;"
      >
        <div
          class="black--text d-flex justify-center text-h4"
        >
          {{ title }}
        </div>
        <v-progress-circular
          v-if="dataAvailabilityState === 'loading data'"
          :size="70"
          :width="7"
          class="my-5"
          color="primary"
          indeterminate
        />
        <div
          :class="`black--text d-flex ${dataAvailabilityState === 'no team' || dataAvailabilityState === 'loading error' ? 'fill-height' : ''} align-center justify-center text-h4 mb-5`"
        >
          {{ dataUnavailableMessage }}
        </div>
      </v-col>
    </div>
    <div
      v-else
      style="height: 230px; width: 100%; overflow: hidden;"
    >
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <div
          class="black--text d-flex justify-center text-h4"
        >
          {{ title }}
        </div>
        <slot name="custom-content" />
      </v-col>

      <div
        v-if="$slots['after-heading']"
        class="ml-1"
      >
        <slot name="after-heading" />
      </div>
    </div>

    <v-col
      cols="12"
      class="px-0 pb-0"
    >
      <v-divider />
    </v-col>

    <v-card-actions class="mb-0 py-0">
      <slot name="actions" />
      <v-spacer />
      <slot name="reveal-actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'MaterialChartCard',
    props: {
      title: {
        type: String,
        default: '',
      },
      loadingMessage: {
        type: String,
        default: 'Loading...',
      },
      dataAvailabilityState: {
        type: String,
        default: 'no team',
      },
      dataUnavailableMessage: {
        type: String,
        default: 'No team members were found.',
      },
    },

    computed: {
      classes () {
        return {
          'v-card--material--has-heading': this.hasHeading,
        }
      },
      hasHeading () {
        return Boolean(this.$slots.heading || this.title || this.icon)
      },
      hasAltHeading () {
        return Boolean(this.$slots.heading || (this.title && this.icon))
      },
    },
  }
</script>

<style lang="sass">
.wrapper
  position: relative
  top: 0px
  height: 200px
  z-index: 1

.v-card--material-chart
  p
    color: #999

  .v-card--material__heading
    max-height: 185px

    .ct-label
      color: inherit
      opacity: .7
      font-size: 0.975rem
      font-weight: 100

    .ct-grid
      stroke: rgba(255, 255, 255, 0.2)

    .ct-series-a .ct-point,
    .ct-series-a .ct-line,
    .ct-series-a .ct-bar,
    .ct-series-a .ct-slice-donut
        stroke: rgba(255,255,255,.8)

    .ct-series-a .ct-slice-pie,
    .ct-series-a .ct-area
        fill: rgba(255,255,255,.4)
</style>
