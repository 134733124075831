function SyncDateTimesManager (vue2Storage) {
  function setSyncDateTime (syncDateTimeKey, syncDateTimeObject) {
    // syncDateTimeObject => { syncDateTime: <String>, duration: <Integer>}
    // get the syncDateTimes obj
    const syncDateTimes = vue2Storage.get('syncDateTimes')

    syncDateTimes[syncDateTimeKey] = syncDateTimeObject

    vue2Storage.set('syncDateTimes', syncDateTimes)
  }
  function getSyncDateTime (syncDateTimeKey) {
    // initialize our retval to false
    let syncDateTime = false

    // if we have syncDateTimes in LocalStorage
    if (vue2Storage.has('syncDateTimes')) {
      // get the syncDateTimes obj
      const syncDateTimes = vue2Storage.get('syncDateTimes')

      // retrieve the object requested by key
      syncDateTime = typeof syncDateTimes?.[syncDateTimeKey] === 'undefined' ? false : syncDateTimes?.[syncDateTimeKey]
    // this is the first time here...
    } else {
      // ...initialize the syncDateTimes var in LocalStorage
      vue2Storage.set('syncDateTimes', {})
    }

    // send it back
    return syncDateTime
  }

  return {
    setSyncDateTime,
    getSyncDateTime,
  }
}

module.exports = SyncDateTimesManager
