<template>
  <v-list-item
    @click="listItemClicked"
  >
    <v-list-item-title>Submit Feature Request</v-list-item-title>
    <v-dialog
      v-model="display"
      max-width="400"
      persistent
    >
      <!-- Base Card -->
      <v-card
        :style="`max-height: ${this.$vuetify.breakpoint.height - 150 + 'px'}; overflow: hidden;`"
        elevation="5"
      >
        <!-- Base Card Title -->
        <v-card-title class="v-dialogHeader text-left px-4 py-1">
          Submit Feature Request
        </v-card-title>
        <v-form
          id="form"
          ref="form"
          class="px-0"
        >
          <!-- Main Form Card -->
          <v-card
            elevation="0"
            class="pa-4 pt-2"
            style="overflow-x: hidden; overflow-y: auto;"
            :max-height="`${this.$vuetify.breakpoint.height - 275 + 'px'}`"
          >
            <v-row class="mt-0">
              <v-col cols="12">
                <!-- Description of Request -->
                <v-textarea
                  v-model="description"
                  label="Description of Request"
                  name="description"
                  :rules="descriptionRules"
                  row-height="20"
                  maxlength="1000"
                  auto-grow
                  counter
                  dense
                  @blur="setFormRules('descriptionRules')"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-form>
        <v-divider />
        <!-- Form Actions -->
        <v-card-actions class="pr-4 pt-2">
          <v-spacer />
          <v-btn
            color="accent"
            :disabled="submitButtonDisabled"
            @click="submit"
          >
            Submit
          </v-btn>
          <v-snackbar
            v-model="snackbar"
            :app="false"
            :timeout="snackbarTimeout"
            absolute
            centered
            light
            :multi-line="snackbarMultiline"
            width="300"
            rounded
          >
            <span
              class="text-h4"
            >
              {{ snackbarText }}
            </span>
            <template #action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="closeSnackbar"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
          <!-- Cancel button + dialog -->
          <v-dialog
            v-model="cancelDialog"
            persistent
            max-width="300"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                Cancel
              </v-btn>
            </template>
            <v-card class="text-left">
              <v-card-title class="v-dialogHeader text-left px-3 py-1">
                Cancel?
              </v-card-title>
              <v-card-text class="px-3 py-1">
                Are you sure you want to cancel?
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="accent"
                  @click="cancel"
                >
                  Yes
                </v-btn>
                <v-btn
                  color="primary"
                  @click="cancelDialog = false"
                >
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
  import FeatureRequestManager from '../../Lib/FeatureRequestManager'

  export default {
    name: 'FeatureRequest',
    props: {
      platform: String,
    },
    data () {
      return {
        allRules: {
          descriptionRules: [
            v => !!v || 'Description is required.',
          ],
        },
        cancelDialog: false,
        description: '',
        descriptionRules: [],
        display: false,
        employeeId: this.$store.getters.employeeId,
        isFormValid: false,
        snackbar: false,
        snackbarClosesDialog: false,
        snackbarMultiline: true,
        snackbarText: '',
        snackbarTimeout: -1,
        submitButtonDisabled: true,
      }
    },
    watch: {
      description (value) {
        this.submitButtonDisabled = value === ''
      },
      display (value) {
        if (value) {
          this.$emit('close-menu')
        }
      },
    },
    methods: {
      // Clears form and closes both dialogs
      // Called from the "Yes" button on the cancel dialog
      cancel () {
        this.clearForm()
        this.cancelDialog = false
        this.display = false
      },
      // Resets fields and rules on the form
      clearForm () {
        this.description = ''
        this.descriptionRules = []
      },
      // Closes the snackbar and also the dialog when relevant
      closeSnackbar () {
        this.snackbar = false
        if (this.snackbarClosesDialog) {
          this.display = false
        }
      },
      // Logs navigation to the Feature Request form
      // Called when the list item "Submit Feature Request" is clicked in the Support menu
      listItemClicked () {
        setTimeout(() => { this.display = true })
      },
      // Sets validation rules when a field is blurred
      // Sets all rules when the form is submitted to account for fields that were never clicked
      setFormRules (fieldName) {
        if (fieldName === 'all') {
          Object.keys(this.allRules).forEach(key => { this[key] = this.allRules[key] })
        } else {
          this[fieldName] = this.allRules[fieldName]
        }
      },
      /*
        1. Sets all rules
        2. Validates the form
        3. If valid, submits the Feature Request to SN
        4. Displays a snackbar with a success/error message
      */
      submit () {
        this.setFormRules('all')
        this.$nextTick(() => {
          this.validate()
          if (this.isFormValid) {
            const request = {
              employeeId: this.employeeId,
              description: this.description,
            }
            FeatureRequestManager.submitRequest(request)
              .then(res => {
                if (res) {
                  this.clearForm()
                  this.snackbar = true
                  this.snackbarText = 'Your request has been submitted.'
                  this.snackbarTimeout = -1
                  this.snackbarClosesDialog = true
                  this.writeToLogging(request)
                } else {
                  this.snackbarText = 'There was a problem submitting your issue. Please try again in a moment.'
                  this.snackbarTimeout = 6000
                  this.snackbarClosesDialog = false
                  this.snackbar = true
                }
              })
          } else {
            this.snackbar = true
            this.snackbarText = 'Form validation failed. Please fix any errors and try again.'
            this.snackbarClosesDialog = false
          }
        })
      },
      // Use v-form api to validate all fields based on given rules
      validate () {
        this.isFormValid = this.$refs.form.validate()
      },
      writeToLogging (request) {
      this.$azureLogger.writeLogItem('activity', {
        Application: 'Support',
        FeatureName: 'Feature Request',
        ActivityType: this.$store.getters.loggingActivityType.clicked,
        AdditionalDetails: `
          employeeId: ${request.employeeId}
          description: ${request.description}
        `,
      })
    },
    },
  }
</script>

<style>
</style>
