<template>
  <material-card
    :icon="icon"
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #after-heading>
      <div class="ml-0 text-left">
        <div
          class="text-h4"
          v-text="title"
        />
        <v-skeleton-loader
          v-if="!value"
          type="list-item"
          width="198"
        />
        <p
          v-if="value"
          :class="truncateText + ' text-body-1 text--primary'"
        >
          {{ value }}
        </p>
      </div>
    </template>

    <v-col
      cols="12"
      class="px-0"
    >
      <v-divider />
    </v-col>

    <v-icon
      :color="subIconColor"
      size="16"
      class="ml-2 mr-1"
    >
      {{ subIcon }}
    </v-icon>

    <span
      :class="subTextColor"
      class="text-caption grey--text font-weight-light"
      v-text="subText"
    />
  </material-card>
</template>

<script>
  import Card from './Card'

  export default {
    name: 'MaterialStatsCard',

    inheritAttrs: false,

    props: {
      ...Card.props,
      icon: {
        type: String,
        required: true,
      },
      subIcon: {
        type: String,
        default: undefined,
      },
      subIconColor: {
        type: String,
        default: undefined,
      },
      subTextColor: {
        type: String,
        default: undefined,
      },
      subText: {
        type: String,
        default: undefined,
      },
      title: {
        type: String,
        default: undefined,
      },
      value: {
        type: String,
        default: undefined,
      },
      smallValue: {
        type: String,
        default: undefined,
      },
    },
    computed: {
      bpname () {
        return this.$vuetify.breakpoint.name
      },
      truncateText () {
        let retVal = ''
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            retVal = 'd-inline-block text-truncate truncate-width'
            break
          case 'sm':
            retVal = 'd-inline-block text-truncate truncate-width'
            break
          case 'md':
            retVal = 'd-inline-block text-truncate truncate-width'
            break
          case 'lg':
            retVal = 'd-inline-block text-truncate truncate-width'
            break
          case 'xl':
            retVal = ''
            break
        }
        return retVal
      },
    },
  }
</script>

<style lang="sass">
.truncate-width
  max-width: 205px

.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

  .v-card__actions
    flex: 1 0 100%
</style>
