import '@fortawesome/fontawesome-pro/css/all.min.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import 'typeface-roboto/index.css'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  accent: '#003568',
  accentblue: '#7ca5d0',
  buttonblue: '#0067ce',
  darkgrey: '#707070',
  error: '#ff5252',
  info: '#2196f3',
  lightgrey: '#f2f2f2',
  lightmeduimgrey: '#dedede',
  mediumgrey: '#c7c8ca',
  meduimdarkgrey: '#8f8f8f',
  menuhovergrey: '#f4f7f9',
  orange: '#e94c39',
  osmoseblue: '#1c3766',
  osmoseerror: '#d9534f',
  osmosewarning: '#d9534f',
  paleblue: '#a5c0dc',
  primary: '#e94c39',
  secondary: '#c7c8ca',
  success: '#0067ce',
  warning: '#ffc107',
  white: '#ffffff',
}

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
