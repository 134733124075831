import { DateTime } from 'luxon'
import Axios from 'axios'
import Store from '@/store/store'
import SyncDateTimesManager from '@/Lib/SyncDateTimesManager'

export default {
    getApiClient () {
        const apiClient = Axios.create({
            withCredentials: false,
            headers: {
                Authorization: `Bearer ${Store.getters.authToken}`,
                dataType: 'json',
                contentType: 'application/json',
                crossDomain: true,
            },
        })
        return apiClient
    },
    async getFromApi (path) {
        let retVal = ''
        // get and instance of the syncDateTimesManager
        const syncDateTimeManager = new SyncDateTimesManager(Store.$app.$storage)
        //  get the fleetDrivers lastSyncDateTime obj
        const lastSyncDateTime = syncDateTimeManager.getSyncDateTime(path)
        // get the current dateTime
        const currentDateTime = DateTime.local()

        try {
            // if we need to get new fleetDrivers
            if (path === 'JobCodeRoles' || (lastSyncDateTime === false || currentDateTime.diff(DateTime.fromISO(lastSyncDateTime.lastSync), 'minutes').toFormat('m') >= lastSyncDateTime.duration)) {
                const URL = Store.getters.azureProxyBaseURL + 'FieldVue/' + path

                const response = await Axios.get(
                    URL,
                    {
                        headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${Store.getters.authToken}`,
                        },
                    },
                )

                Store.$app.$storage.set(path, response.data)

                // update the fleetDrivers lastSyncDateTime obj
                syncDateTimeManager.setSyncDateTime(path, { lastSync: currentDateTime, duration: 480 })
            }

            retVal = Store.$app.$storage.get(path)
            return retVal
        } catch (err) {
            Store.$app.$azureLogger.writeLogItem('error', {
                Application: 'Support Data',
                FeatureName: 'RoleMapGridManager.js - getFromApi()',
                ErrorCode: err.name,
                ErrorDescription: 'Failed to get from Api.',
                AdditionalDetails: `Message: ${err.message}. Stack: ${err.stack}`,
            })
        }
    },
    async insertUpdateRoleMapping (params) {
        const apiClient = this.getApiClient()
        const URL = Store.getters.azureProxyBaseURL + 'FieldVue/JobCodeRole'
        let success = ''
        try {
            const response = await apiClient.post(URL, params)
            success = response.data
        } catch (err) {
            success = err.message
            Store.$app.$azureLogger.writeLogItem('error', {
                Application: 'Support Data',
                FeatureName: 'RoleMapGridManager.js - insertUpdateRoleMapping()',
                ErrorCode: err.name,
                ErrorDescription: 'Failed to insert or update Role Mapping.',
                AdditionalDetails: `Message: ${err.message}. Stack: ${err.stack}`,
            })
        }
        return success
    },
    async deleteJobRoleMapping (jobCode) {
        const apiClient = this.getApiClient()
        const URL = Store.getters.azureProxyBaseURL + `FieldVue/DeleteJobCodeRole/${jobCode}`
        let success = ''
        try {
            const response = await apiClient.get(URL)
            success = response.data
        } catch (err) {
            success = err.message
            Store.$app.$azureLogger.writeLogItem('error', {
                Application: 'Support Data',
                FeatureName: 'RoleMapGridManager.js - deleteJobRoleMapping()',
                ErrorCode: err.name,
                ErrorDescription: 'Failed to delete Job Role Mapping.',
                AdditionalDetails: `Message: ${err.message}. Stack: ${err.stack}`,
            })
        }
        return success
    },
}
