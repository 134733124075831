/***********************************************
 * ConfigurationManager
 * *********************************************
 * functions to obtain configuration
 * Information from AccessManager Service
 *
 **********************************************/

/// //////////////// Imports ///////////////////
import Axios from 'axios'

export default {
  getO360ApplicationObj: function (application, projectId, authToken, callBack, URL) {
    const url = `${URL}${projectId}/${application}`
    Axios.get(url, { headers: { Authorization: `Bearer ${authToken}` } })

      .then(response => {
        callBack(response.data)
      })
      .catch(error => {
        const errorObj = {
          requestName: 'getProjectApplicationConfiguration',
          errorMessage:
            `Could not retrieve the project information. Please try again.
            If this error continues to occur, please contact support.`,
          Error: error,
        }
        console.dir(errorObj)
      })
  },

  getO360tProjectsObj: function (projectId, authToken, callback, callbackName, URL) {
    const url = `${URL}${projectId}`
    Axios.get(url, { headers: { Authorization: `Bearer ${authToken}` } })

      .then(response => {
        callback(callbackName, response.data)
      })
      .catch(error => {
        console.dir(error)
      })
  },
  updateUserDefaultProject: function (URL, authToken, userId, projectId) {
    const url = `${URL}${userId}/${projectId}`

    Axios.get(url, { headers: { Authorization: `Bearer ${authToken}` } })

      .then(response => {
      })
      .catch(error => {
        console.dir(error)
      })
  },
}
