import Axios from 'axios'
import FileDownload from 'js-file-download'
import { DateTime } from 'luxon'
import Store from '@/store/store'
import SyncDateTimesManager from '@/Lib/SyncDateTimesManager'

export default {
  async getFleetData (params, cancelToken) {
    try {
      const response = await Axios.post(
        `${Store.getters.azureProxyBaseURL}fieldvue/fleetrecords`,
        params,
        {
          headers: {
            dataType: 'json',
            contentType: 'application/json',
            crossDomain: true,
            Authorization: `Bearer ${Store.getters.authToken}`,
          },
          cancelToken: cancelToken,
        })

      return response.data
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'Vehicles',
        FeatureName: 'vehicleManager.js - getFleetData()',
        ErrorCode: error.name,
        ErrorDescription: 'Failed to get Fleet records.',
        AdditionalDetails: `Message: ${error.message}. Stack: ${error.stack}`,
      })
      throw error
    }
  },
  async getFleetServiceData (params, cancelToken) {
    try {
      const response = await Axios.post(
        `${Store.getters.azureProxyBaseURL}fieldvue/fleet/service/data`,
        params,
        {
          headers: {
            dataType: 'json',
            contentType: 'application/json',
            crossDomain: true,
            Authorization: `Bearer ${Store.getters.authToken}`,
          },
          cancelToken: cancelToken,
        })

      return response.data
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'Vehicles',
        FeatureName: 'vehicleManager.js - getFleetServiceData()',
        ErrorCode: error.name,
        ErrorDescription: 'Failed to get Fleet Service records.',
        AdditionalDetails: `Message: ${error.message}. Stack: ${error.stack}`,
      })
      throw error
    }
  },
  async getFleetRecordId (id, cancelToken) {
    try {
      const response = await Axios.get(
        `${Store.getters.azureProxyBaseURL}fieldvue/fleet/service/${id}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Store.getters.authToken}`,
          },
          cancelToken: cancelToken,
        })

      return response.data
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'Vehicles',
        FeatureName: 'vehicleManager.js - getFleetRecordId()',
        ErrorCode: error.name,
        ErrorDescription: 'Failed to get Fleet record.',
        AdditionalDetails: `Message: ${error.message}. Stack: ${error.stack}`,
      })
      throw error
    }
  },
  // returns a cancel request object, containing an axios cancel token, and a function to cancel the call
  getCancelRequest () {
    const requestToBeCancelled = Axios.CancelToken.source()
    return requestToBeCancelled
  },
  async updateFleetService (params, cancelToken) {
    try {
      const response = await Axios.post(`${Store.getters.azureProxyBaseURL}fieldvue/fleet/service`,
        params,
        {
          headers: {
            Authorization: `Bearer ${Store.getters.authToken}`,
            crossDomain: true,
          },
          cancelToken,
        })

      return response.data
    } catch (error) {
      if (Axios.isCancel(error)) {
        // console.log('Request canceled', error.message)
      } else {
        Store.$app.$azureLogger.writeLogItem('error', {
          Application: 'Vehicles',
          FeatureName: 'vehicleManager.js - updateFleetService()',
          ErrorCode: error.name,
          ErrorDescription: error.message,
          AdditionalDetails: 'Failed to update Fleet record. Stack: ' + error.stack,
        })
        throw error
      }
    }
  },
  async exportVehicleData (params, cancelToken) {
    try {
      // cancel the request (the message parameter is optional)
      const response = await Axios.post(
        `${Store.getters.azureProxyBaseURL}fieldvue/exportdata`,
        params,
        {
          headers: {
            Authorization: `Bearer ${Store.getters.authToken}`,
            crossDomain: true,
          },
          responseType: 'blob',
          cancelToken,
        })

      FileDownload(response.data, 'VehicleExportData.xlsx')
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'Vehicles',
        FeatureName: 'VehicleManager.js - exportVehicleData()',
        ErrorCode: error.name,
        ErrorDescription: error.message,
        AdditionalDetails: 'Failed to export Vehicle data. Stack: ' + error.stack,
      })
    }
  },
  async getUnitNumbers () {
    try {
    const response = await Axios.get(
      `${Store.getters.azureProxyBaseURL}FieldVue/unitnumbers`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Store.getters.authToken}`,
        },
      },
    )
    return response.data
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'Vehicles',
        FeatureName: 'VehicleManager.js - getUnitNumbers()',
        ErrorCode: error.name,
        ErrorDescription: error.message,
        AdditionalDetails: 'Failed get unit numbers. Stack: ' + error.stack,
      })
      throw error
    }
  },
  async getDrivers () {
    let drivers = ''
    // get and instance of the syncDateTimesManager
    const syncDateTimeManager = new SyncDateTimesManager(Store.$app.$storage)
    //  get the fleetDrivers lastSyncDateTime obj
    const lastSyncDateTime = syncDateTimeManager.getSyncDateTime('fleetDrivers')
    // get the current dateTime
    const currentDateTime = DateTime.local()

    try {
      // if we need to get new fleetDrivers
      if (lastSyncDateTime === false || currentDateTime.diff(DateTime.fromISO(lastSyncDateTime.lastSync), 'minutes').toFormat('m') >= lastSyncDateTime.duration) {
        const response = await Axios.get(
          `${Store.getters.azureProxyBaseURL}FieldVue/fleet/drivers`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${Store.getters.authToken}`,
            },
          },
        )

        Store.$app.$storage.set('fleetDrivers', response.data)

        // update the fleetDrivers lastSyncDateTime obj
        syncDateTimeManager.setSyncDateTime('fleetDrivers', { lastSync: currentDateTime, duration: 480 })
      }

      drivers = Store.$app.$storage.get('fleetDrivers')

      return drivers
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'Vehicles',
        FeatureName: 'VehicleManager.js - getDrivers()',
        ErrorCode: error.name,
        ErrorDescription: error.message,
        AdditionalDetails: 'Failed to get driver data. Stack: ' + error.stack,
      })
      throw error
    }
  },
  async getFleetDataByStatus (statuses = ['Active'], include = true) {
    try {
      const response = await Axios.get(
        `${Store.getters.azureProxyBaseURL}FieldVue/fleetbystatus?statuses=${statuses.join(',')}&include=${include}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Store.getters.authToken}`,
          },
        },
      )

      return JSON.parse(response.data)
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'Vehicles',
        FeatureName: 'VehicleManager.js - getFleetDataByStatus()',
        ErrorCode: error.name,
        ErrorDescription: error.message,
        AdditionalDetails: 'Failed to get driver data. Stack: ' + error.stack,
      })
      throw error
    }
  },
  async getVehicleAssignments(id = 0) {
    let uri = `${Store.getters.azureProxyBaseURL}fieldvue/fleet/assignments`;
    
    if (id !== 0) {
        uri += `/${id}`;
    }

    try {
      const response = await Axios.get(uri,
        {
          headers: {
            Authorization: `Bearer ${Store.getters.authToken}`,
            crossDomain: true,
          },
        })

      return response.data
    } catch (error) {
      if (Axios.isCancel(error)) {
        // console.log('Request canceled', error.message)
      } else {
        Store.$app.$azureLogger.writeLogItem('error', {
          Application: 'Fleet Assignment',
          FeatureName: 'VehicleAssignmentManager.js - saveVehicleAssignments()',
          ErrorCode: error.name,
          ErrorDescription: error.message,
          AdditionalDetails: 'Failed to save vehicle assignments. Stack: ' + error.stack,
        })
        throw error
      }
    }
  },
  async getVehicleAssignmentTypes() {
    try {
      const response = await Axios.get(`${Store.getters.azureProxyBaseURL}fieldvue/fleet/assignmenttypes`,
        {
          headers: {
            Authorization: `Bearer ${Store.getters.authToken}`,
            crossDomain: true,
          },
        })

      return response.data
    } catch (error) {
      if (Axios.isCancel(error)) {
        // console.log('Request canceled', error.message)
      } else {
        Store.$app.$azureLogger.writeLogItem('error', {
          Application: 'Fleet Assignment',
          FeatureName: 'VehicleAssignmentManager.js - saveVehicleAssignments()',
          ErrorCode: error.name,
          ErrorDescription: error.message,
          AdditionalDetails: 'Failed to save vehicle assignments. Stack: ' + error.stack,
        })
        throw error
      }
    }
  },
  async saveVehicleAssignments(vehicleAssignments) {
    try {
      const response = await Axios.post(`${Store.getters.azureProxyBaseURL}fieldvue/fleet/assignments`,
        vehicleAssignments,
        {
          headers: {
            Authorization: `Bearer ${Store.getters.authToken}`,
            crossDomain: true,
          },
        })

      return response.data
    } catch (error) {
      if (Axios.isCancel(error)) {
        // console.log('Request canceled', error.message)
      } else {
        Store.$app.$azureLogger.writeLogItem('error', {
          Application: 'Fleet Assignment',
          FeatureName: 'VehicleAssignmentManager.js - saveVehicleAssignments()',
          ErrorCode: error.name,
          ErrorDescription: error.message,
          AdditionalDetails: 'Failed to save vehicle assignments. Stack: ' + error.stack,
        })
        throw error
      }
    }
  },
  async updateVehicleAssignmentProcessedDate(id, newProcessedDate) {
    const URL = Store.getters.azureProxyBaseURL + 'fieldvue/fleet/assignment/' + id
    const assignmentPatch = [
      {
        op: 'replace',
        path: '/ProcessedDate',
        value: newProcessedDate,
      }
    ]

    try {
      const response = await Axios.patch(
        URL,
        assignmentPatch,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Store.getters.authToken}`,
          },
        },
      )
      
      return response.data
    } catch (err) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'Fleet Assignment',
        FeatureName: 'VehicleAssignmentManager.js - patchVehicleAssignmentProcessedDate()',
        ErrorCode: err.name,
        ErrorDescription: 'Failed to PATCH VehicleAssignmentProcessedDate.',
        AdditionalDetails: `Message: ${err.message} Stack: ${err.stack}`,
      })
    }
  }
}
