import Axios from 'axios'
import Store from '@/store/store'
import DB from '@/db/db'
import { DateTime } from 'luxon'
import SyncDateTimesManager from '@/Lib/SyncDateTimesManager'

export default {
  async performSync (localStorage) {
    // get an instance of the syncDateTimesManager
    const syncDateTimeManager = new SyncDateTimesManager(localStorage)
    // get the current dateTime
    const currentDateTime = DateTime.local()

    try {
      // console.log('Getting the Active Employee List from SQL DB')
      if (Store.getters.isOnline) {
        const response = await Axios.get(`${Store.getters.azureProxyBaseURL}FieldVue/AllActive/${Store.getters.employeeId}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Store.getters.authToken}`,
          },
        })

        await DB.employee.clear()
        await DB.employee.bulkPut(response.data)
        // console.log('Done Updating the Active Employee in IDB')
        localStorage.set('globalSyncOverride', false)
        // get the QC version from LS
        // const qcVersion = localStorage.get('qcVersion')
        syncDateTimeManager.setSyncDateTime('employees', { qcVersion: '0.0', lastSync: currentDateTime, duration: 720 })
      }
      // console.log('Retrieving the Active Employee List')
      const employees = await DB.employee.orderBy('Name').toArray()
      // console.log('Returning the Active Employee List')
      return employees
    } catch (error) {
      let errorName = ''
      let errorMsg = ''

      if (error.response) {
        errorName = error.response.status
        errorMsg = error.response.headers
      } else if (error.request) {
        errorName = 'No Error Name Received'
        errorMsg = error.request
      } else {
        errorName = 'Axios Setup Error'
        errorMsg = error.message
      }

      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'EmployeeManager',
        FeatureName: 'performSync()',
        ErrorCode: errorName,
        ErrorDescription: 'Failed to sync Employees',
        AdditionalDetails: `Message: ${errorMsg}. Stack: ${error.stack}.`,
      })
    }
  },
  async getEmployees () {
    // get and instance of the syncDateTimesManager
    const syncDateTimeManager = new SyncDateTimesManager(Store.$app.$storage)
    //  get the global lastSyncDateTime obj
    const lastSyncDateTime = syncDateTimeManager.getSyncDateTime('employees')
    // get the current dateTime
    const currentDateTime = DateTime.local()
    // determine if we're forcing a sync
    const syncOverride = Store.$app.$storage.get('globalSyncOverride')

    try {
      let employees = []
      // const currentQCVersion = Store.$app.$storage.get('qcVersion')
      if (syncOverride ||
        lastSyncDateTime === false ||
        currentDateTime.diff(DateTime.fromISO(lastSyncDateTime.lastSync), 'minute').toFormat('m') > lastSyncDateTime.duration) { //||
        // lastSyncDateTime.qcVersion !== currentQCVersion) {
        employees = await this.performSync(Store.$app.$storage)
      } else {
        // check to see that the data actually exist
        const employeeCount = await DB.employee.count()
        if (employeeCount === 0) {
          // no employee recs found - go get them
          employees = await this.performSync(Store.$app.$storage)
        }
        employees = await DB.employee.orderBy('Name').toArray()
      }
      return employees
    } catch (error) {
      let errorName = ''
      let errorMsg = ''

      if (error.response) {
        errorName = error.response.status
        errorMsg = error.response.headers
      } else if (error.request) {
        errorName = 'No Error Name Received'
        errorMsg = error.request
      } else {
        errorName = 'Axios Setup Error'
        errorMsg = error.message
      }

      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'EmployeeManager',
        FeatureName: 'getEmployees()',
        ErrorCode: errorName,
        ErrorDescription: 'Failed to get Employees',
        AdditionalDetails: `Message: ${errorMsg}. Stack: ${error.stack}.`,
      })
    }
  },
  async getEmployeeByEmployeeId (employeeId) {
    const employee = await DB.employee.where({ EmployeeId: employeeId }).first()

    return typeof employee !== 'undefined' ? employee : false
  },
}
