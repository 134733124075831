import { referenceDataMixin } from '@/mixins/referenceDataMixin'

export default {
    /**
     * flattens a multi-level array
     * @param {} arr
     * @returns {}
     */
     flatten (arr) {
        const self = this
        return arr.reduce(function (flat, toFlatten) {
            return flat.concat(Array.isArray(toFlatten) ? self.flatten(toFlatten) : toFlatten)
        }, [])
    },
    /*
     * Checks to see if the date is equal to the base date 1/1/1900
     * @param {} date
     * @returns {}
     */
    isBaseDate (date) {
        return new Date(date) <= new Date(1900, 0, 2)
    },
     getRatingAsAPercentage (ratingValue, dataVersion, isMQC, bypassRounding) {
        var maxRating = this.getMaxRating(dataVersion, isMQC)
        var qcRating = (bypassRounding)
            ? (ratingValue / maxRating) * 100
            : Math.round((ratingValue / maxRating) * 100)

        return qcRating
    },
    getMaxRating (versionId, isMQC) {
        var ratingTypeVersion = referenceDataMixin.methods.offLineVersions(versionId)
        var data = null
        if (ratingTypeVersion) {
          var ratingTypes = referenceDataMixin.methods.offLineRatingTypes({ versionId: ratingTypeVersion.RatingTypeVersion, isMqc: isMQC })
          var ratings = []

          // find ratings that include rating types and add to array
          ratingTypes.forEach(function (value, i) {
            var validRatings = referenceDataMixin.methods.offLineRatings(null).filter(p => p.RatingTypeId === value.Id)
            validRatings.forEach(function (value, i) {
              ratings.push(value)
            })
          })
          // get max Score
          data = Math.max(...ratings.map(p => p.Score))
        }
        return data
    },
    parseDate (date) {
        if (date && date.length > 0) {
            var dateStr = date.substr(0, 10)
            var year = parseInt(dateStr.substr(0, 4))
            var month = parseInt(dateStr.substr(5, 2))
            var day = parseInt(dateStr.substr(8, 2))
            return new Date(year, month - 1, day)
        } else if (Object.prototype.toString.call(date) === '[object Date]') {
            // add this because I (not Charlie) found atleast one case where this method was failing
            // because a date was being passed in and then failing because there isn't
            // a length method for a date object.
            return date
        }
        return ''
    },
    parseDateTime (dateTime) {
        if (dateTime && dateTime.length > 0) {
            var dateStr = dateTime.substr(0, 10)
            var year = parseInt(dateStr.substr(0, 4))
            var month = parseInt(dateStr.substr(5, 2))
            var day = parseInt(dateStr.substr(8, 2))
            var time = dateTime.substr(11, 8)
            var hours = parseInt(time.substr(0, 2))
            var minutes = parseInt(time.substr(3, 2))
            var seconds = parseInt(time.substr(6, 2))
            return new Date(year, month - 1, day, hours, minutes, seconds)
        }
        return ''
    },
 }
