import Dexie from 'dexie'

const DB = new Dexie('fieldvue')
const currentDbVersion = 22

const dbSchemata = {
  Attachment: 'Id, ParentId, Md5, SnId, Submitted',
  companynews: '++',
  employee: 'EmployeeId,Name',
  Form: 'Id, SnId, Status, Submitted, [Status+Submitted], FormIsDirty, FormData.taskDate, [TaskDefinitionId+FormData.taskDate], [Status+FormIsDirty], EmployeeId, [FormIsDirty+Submitted]',
  oncore: '++',
  qcTodayStatus: 'EmployeeId',
  saTodayStatus: 'EmployeeId',
  sharepointdocument: 'Id,Type',
}

if (!DB.isOpen()) {
  DB.version(currentDbVersion).stores(dbSchemata)
  DB.open()
}

export default DB
