import Ping from 'ping.js'
import Store from '@/store/store'

export default {
  data () {
    return {
      onlineCheckIntervalId: null,
    }
  },
  mounted () {
    if (typeof window !== 'undefined') {
      if (navigator.onLine) {
        Store.dispatch('setIsOnline', true)
      } else {
        Store.dispatch('setIsOnline', false)
      }

      const onlineHandler = () => {
        if (!navigator.onLine) {
          // If the browser is offline, emit the 'offline' event and set isOnline to false
          this.$emit('offline')
          Store.dispatch('setIsOnline', false)
          return
        }
        
        const p = new Ping()
        const pingUrl = 'https://webops.osmose.com/qualitycontrol'
        // console.log('in online handler')

        p.ping(pingUrl)
        .then(data => {
          this.$emit('online')
          clearInterval(this.onlineCheckIntervalId)
          Store.dispatch('setIsOnline', true)
        })
        .catch(data => {
          this.$emit('offline')
          Store.dispatch('setIsOnline', false)
        })

        // try {
        //   p.ping(pingUrl, (err) => {
        //     if (err || !navigator.onLine) {
        //       this.$emit('offline')
        //       // console.log('setting isOnline to false')
        //       // this.isOffline = true
        //       // this.isOnline = false
        //       Store.dispatch('setIsOnline', false)
        //     } else {
        //       this.$emit('online')
        //       // console.log('setting isOnline to true')
        //       // this.isOnline = true
        //       // this.isOffline = false
        //       clearInterval(this.onlineCheckIntervalId)
        //       Store.dispatch('setIsOnline', true)
        //     }
        //   })
        // } catch (e) {
        //   this.$emit('offline')
        //   // console.log('setting isOnline to false')
        //   // this.isOffline = true
        //   // this.isOnline = false
        //   Store.dispatch('setIsOnline', false)
        // }
      }

      const offlineHandler = () => {
        this.$emit('offline')
        Store.dispatch('setIsOnline', false)
        this.onlineCheckIntervalId = setInterval(onlineHandler, 10000)
      }

      window.addEventListener('online', onlineHandler)
      window.addEventListener('offline', offlineHandler)
    }
  },
}
