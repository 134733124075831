export const state = {
  safetyAuditDetails: [],
  safetyAuditLastSyncDateTime: null,
  safetyAuditDetailsUpdatedOn: null,
}

export const mutations = {
  SET_SAFETY_AUDIT_DETAILS (state, safetAuditDetails) {
    state.safetyAuditDetails = safetAuditDetails
  },
  SET_SAFETY_AUDIT_LAST_SYNC (state, lastSyncDateTime) {
    state.safetyAuditLastSyncDateTime = lastSyncDateTime
  },
  SET_SAFETY_AUDIT_DETAILS_UPDATED_ON (state, safetyAuditDetailsUpdatedOn) {
    state.safetyAuditDetailsUpdatedOn = safetyAuditDetailsUpdatedOn
  },
}

export const getters = {
  getSafetyAuditDetails (state) {
    return state.safetyAuditDetails
  },
  getSafetyAuditDetailsLastSync (state) {
    return state?.safetyAuditLastSyncDateTime
  },
  getSafetyAuditDetailsUpdatedOn (state) {
    return state.safetyAuditDetailsUpdatedOn
  },
  hasSafetyAuditDetails (state) {
    return state.safetyAuditDetails.length > 0
  },
}

export const actions = {
  setSafetyAuditDetails ({ commit }, safetyAuditDetails) {
    commit('SET_SAFETY_AUDIT_DETAILS', safetyAuditDetails)
  },
  setSafetyAuditLastSyncDateTime ({ commit }, lastSyncDateTime) {
    commit('SET_SAFETY_AUDIT_LAST_SYNC', lastSyncDateTime)
  },
  setSafetyAuditDetailsUpdatedOn ({ commit }, safetyAuditDetailsUpdatedOn) {
    commit('SET_SAFETY_AUDIT_DETAILS_UPDATED_ON', safetyAuditDetailsUpdatedOn)
  },
}
