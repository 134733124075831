<template>
  <v-list-item
    @click="listItemClicked"
  >
    <v-list-item-title>User Support Access</v-list-item-title>
    <v-dialog
      v-model="display"
      max-width="500"
      persistent
    >
      <v-card
        :style="`max-height: ${this.$vuetify.breakpoint.height - 150 + 'px'}; overflow: hidden;`"
        elevation="5"
        class="mt-0 pt-0"
      >
        <v-row
          align="start"
        >
          <v-col>
            <!-- Base Card Title -->
            <v-card-title
              class="v-dialogHeader text-left px-4 py-1"
            >
              User Support Access
            </v-card-title>
            <v-form
              class="px-0"
            >
              <!-- Main Form Card -->
              <v-card
                elevation="0"
                style="overflow-x: hidden; overflow-y: auto;"
                :max-height="`${this.$vuetify.breakpoint.height - 275 + 'px'}`"
              >
                <!-- Access Information -->
                <v-card
                  class="pa-4 mb-0"
                  elevation="0"
                >
                  <v-card-title class="text-left pa-0 mb-4">
                    Login Information
                  </v-card-title>
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Support User's login name -->
                      <v-text-field
                        v-model="supportUserName"
                        label="User Name"
                        :height="fieldHeight"
                        dense
                        autofocus
                        @input="toggleSubmitButton"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Password -->
                      <v-text-field
                        v-model="password"
                        label="Password"
                        :height="fieldHeight"
                        type="password"
                        dense
                        @input="toggleSubmitButton"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
              <v-divider />
              <!-- Form Actions -->
              <v-card-actions class="pr-2 py-3">
                <v-spacer />
                <v-btn
                  color="accent"
                  :disabled="submitButtonDisabled"
                  @click="submit"
                >
                  Submit
                </v-btn>
                <!-- Cancel button -->
                <v-btn
                  color="primary"
                  class="ml-2"
                  @click="reset(true)"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
      <!-- Status snackbar -->
      <v-snackbar
        v-model="displaySnackbar"
        :app="false"
        :timeout="snackbarTimeout"
        absolute
        centered
        light
        :multi-line="snackbarMultiline"
        rounded
      >
        <span
          class="text-h4"
        >
          {{ statusText }}
        </span>

        <template #action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="displaySnackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </v-list-item>
</template>

<script>
  import SupportManager from '@/Lib/UserSupportManager.js'
  import eruda from '../../../node_modules/eruda/eruda.js'

  export default {
    data () {
      return {
        display: false,
        displaySnackbar: false,
        fieldHeight: '20',
        fieldVueUserRole: '',
        password: '',
        showAssterisk: false,
        snackbarMultiline: true,
        snackbarTimeout: 2000,
        statusText: '',
        submitButtonDisabled: true,
        supportUserName: '',
      }
    },
    methods: {
      initialize () {
        this.submitButtonDisabled = true
        this.reset(false)
       },
      // Clears the form and closes dialog
      reset (closeDialog) {
        this.supportUserName = ''
        this.password = ''

        if (closeDialog) {
          this.display = false
        }
      },
      // Logs navigation to the User Support
      // Called when the list item "User Support Access" is clicked in the Support menu
      listItemClicked () {
        setTimeout(() => {
          this.display = true
          this.initialize()
        })
      },
      // Starts the support process
      async submit () {
        // validate the user credentials entered
        // if valid then turn on Eruda
        // else popup up dialog indicating that the user credentials are not valid
        if (await SupportManager.validatSupportUserNamePassword(this.supportUserName, this.password)) {
          // eruda.init({ container: 'header' })
          eruda.init()
          this.display = false
          this.$azureLogger.writeLogItem('activity', {
          Application: 'FieldVue',
          FeatureName: 'Mobile Support',
          ActivityType: this.$store.getters.loggingActivityType.started,
          AdditionalDetails: '',
        })
        } else {
          this.statusText = 'Incorrect credentials provided'
          this.displaySnackbar = true
        }
      },
      // Enables the submit button
      toggleSubmitButton () {
        this.submitButtonDisabled = true
        if (this.supportUserName.trim() !== '' && this.password.trim() !== '') {
          this.submitButtonDisabled = false
        }
      },
    },
  }
</script>

<style scoped>
  /* Gives chips the same font attributes as buttons */
  .v-chip__content  {
    font-size: 0.85rem;
    font-weight: 400;
  }
</style>
