<template>
  <v-list-item
    @click="listItemClicked"
  >
    <v-list-item-title>Profile</v-list-item-title>
    <v-dialog
      v-model="display"
      max-width="300"
      persistent
    >
      <v-card class="text-left">
        <v-card-title class="v-dialogHeader text-left px-3 py-1">
          Profile
        </v-card-title>
        <v-card-text
          class="px-3 py-3"
        >
          <h3>{{ userName }}</h3>
          <p>
            User Role: {{ userRole }}
          </p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="accent"
            @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
  import { B2CManager } from '@/Lib/B2CManager.js'
  export default {
    mixins: [B2CManager],
    data () {
      return {
        display: false,
      }
    },
    computed: {
      userRole () {
        return this.$store.getters.userRoleName
      },
    },
    methods: {
      listItemClicked () {
        setTimeout(() => { this.display = true })
        this.$azureLogger.writeLogItem('activity', {
          Application: 'FieldVue',
          FeatureName: 'User Profile',
          ActivityType: this.$store.getters.loggingActivityType.navigation,
          AdditionalDetails: '',
        })
      },
      close () {
        this.display = false
      },
    },
  }
</script>

<style>

</style>
