<template>
  <v-list-item
    :active-class="`primary ${!isDark ? 'black' : 'white'}--text`"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :to="item.to"
    :class="linkLevel === 'top' ? '' : drawerState ? '' : 'navexpanded'"
    :disabled="!isOnline && item.OnlineOnly"
    :title="item.Title"
    @click="cardNavigate"
  >
    <v-list-item-icon v-if="item.NavIconDisplay">
      <v-icon
        small
        v-text="item.NavIcon"
      />
    </v-list-item-icon>
    <v-list-item-icon
      v-else
      class="v-list-item__icon--text text-h3 font-weight-bold text-center pl-1 pt-0 mt-0"
      v-text="computedText"
    />

    <v-list-item-content v-if="item.Title || item.subtitle">
      <v-list-item-title v-text="item.Title" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import Themeable from 'vuetify/lib/mixins/themeable'
  import CardNavBase from '@/components/CardNavBase'
  import { mapState } from 'vuex'

  export default {
    name: 'FvDynamicLink',
    extends: CardNavBase,
    mixins: [Themeable],
    props: {
      targetComponunt: String,
      targetPath: String,
      entryPoint: String,
      item: {
        type: Object,
        default: () => ({
          linkUrl: undefined,
          icon: undefined,
          subtitle: undefined,
          title: undefined,
          to: undefined,
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
      linkLevel: {
        type: String,
        default: 'top',
      },
    },
    computed: {
      ...mapState({
        isOnline: (state) => state.applicationCoreStore.isOnline,
      }),
      computedText () {
        if (!this.item || !this.item.Name) return ''

        let text = ''

        this.item.Name.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })
        return text
      },
      href () {
        return this.item.href || (!this.item.to ? '#' : undefined)
      },
    },
  }
</script>

<style lang="sass">
.navexpanded
  text-align: left
  padding-left: 8px !important
.navcollapsed
  padding-left: 0px !important
.v-list-item__icon
  float: right !important
</style>
