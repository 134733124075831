<template>
  <div id="noticeScreenLayout">
    <div id="overLay" />
    <div class="messageBox">
      <h2>{{ title }}</h2>
      <p
        class="description"
        v-html="description"
      />
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      title: String,
      description: String,
      overlay: {
        type: Boolean,
        default: true,
      },
    },
  }
</script>
<style scoped>
#noticeScreenLayout {
  background: transparent 0% 0% no-repeat padding-box;
  background-size: cover;
  letter-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  height: calc(100vh - 146px);
}

#overLay {
  background-color: rgba(0, 0, 0, 0.46);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.messageBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: 50px; */
  padding: 40px;
  max-width: 60vw;
  /* height: fit-content; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  color: #1c3766;

}

.description {
  font-size: 16;
  font-weight: 500;
}
</style>
