<template>
  <v-list-group
    v-show="computedMobileDisplay"
    :group="group"
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    :disabled="!isOnline && item.OnlineOnly"
    append-icon="fa-solid fa-caret-down"
    :color="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)' ? 'white' : 'grey darken-1'"
    :active-class="isLinkGroup"
    :title="item.Title"
    @click="navGroupClicked(item.Title)"
  >
    <template #activator>
      <v-list-item-icon v-if="item.NavIconDisplay">
        <v-icon
          small
          v-text="item.NavIcon"
        />
      </v-list-item-icon>
      <v-list-item-icon
        v-else
        class="pl-1 v-list-item__icon--text text-h3 font-weight-bold"
        v-text="computedText"
      />

      <v-list-item-content>
        <v-list-item-title
          class="font-weight-override"
          v-text="item.Title"
        />
      </v-list-item-content>
    </template>

    <template
      v-for="(child, i) in children"
    >
      <nav-group
        v-if="child.children"
        :key="`sub-group-${i}`"
        :item="child"
        class="nav-sub-group"
        @clicked="removePrimaryFromGroup()"
      />
      <nav-item
        v-else
        :key="`item-${i}`"
        :item="child"
        link-level="child"
        class="nav-item"
        text
        @clicked="removePrimaryFromGroup()"
      />
    </template>
  </v-list-group>
</template>

<script>
  import Bowser from 'bowser'
  import { mapState } from 'vuex'

  export default {

    name: 'NavGroup',
    inheritAttrs: false,

    props: {
      item: {
        type: Object,
        default: () => ({
          avatar: undefined,
          group: undefined,
          title: undefined,
          children: [],
        }),
      },
      subGroup: {
        type: Boolean,
        default: false,
      },
      text: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      isLinkGroup: null,
    }),
    computed: {
      ...mapState({
        isOnline: (state) => state.applicationCoreStore.isOnline,
      }),
      computedMobileDisplay () {
        const platform = Bowser.getParser(window.navigator.userAgent).parsedResult.platform.type
        if (!this.item.AvailableOnPhone && platform === 'mobile') {
          return false
        } else return true
      },
      href () {
        return this.item.Href || (!this.item.to ? '#' : undefined)
      },
      children () {
        return this.item.children.map(item => ({
          ...item,
          to: item.to,
        }))
      },
      barColor: {
        get () {
          return this.$store.getters.getBarColor
        },
      },
      computedText () {
        if (!this.item || !this.item.Title) return ''

        let text = ''

        this.item.Title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },
      group () {
        const groups = this.getApplicationDescendants(this.item).filter((child) => {
          return child !== '' && child !== undefined
        }).join('|')

        return groups
      },
    },
    created () {
      if (this.item.NavType === 'LinkGroup') {
        this.isLinkGroup = 'primary'
      }
    },
    methods: {
      navGroupClicked (title) {
        setTimeout(() => {
          const element = document.querySelector(`[title='${title}']`)
          const rect = element.getBoundingClientRect()
          const inFrame = (rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
          )
          if (!inFrame) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        }, 350)
      },
      removePrimaryFromGroup () {
        this.isLinkGroup = ''
      },
      getApplicationDescendants (app, accum) {
          // set up the accumulator array
          accum = accum || []

          // if the current application object has descendants
          if (app?.children !== undefined) {
            app.children.forEach((descendant) => {
                // add the current descendant route to the accumulator
                accum.push(descendant.to)
                // get the descendants from the current descendant
                this.getApplicationDescendants(descendant, accum)
            })
          }
          // send them all home
          return accum
      },
    },
  }
</script>

<style lang="sass">
.nav-item
  margin-left: 20px
.nav-sub-group
  margin-left: 20px
.font-weight-override
  font-weight: initial !important
.v-list-group--disabled > .v-list-group__header
  color: rgba(255, 255, 255, 0.5) !important
  .v-list-item__icon > i
    color: rgba(255, 255, 255, 0.5) !important
</style>
