/********************************************
 * AuthorizationManager.js
 ******************************************
 * Functions to Retrieve Authorizaion Data
 ********************************************
 * Methods:
 * getApplicationRegistry
 *
 ********************************************/

/// ///////////////////// Imports /////////////////
import Axios from 'axios'
import Store from '@/store/store'
import NavigationManager from '@/Lib/NavigationManager'
import applicationPreferencesManager from '@/components/dynamic/ApplicationPreferences/LocalLib/ApplicationPreferencesManager.js'

/// /////////////////// Public Methods ////////////
export default {
  async getConfiguration (URL, b2cToken, callback, callbackName) {
    try {
      const response = await Axios.get(URL, {
        headers: {
          Authorization: `Bearer ${b2cToken}`,
        },
      })

      callback(callbackName, response.data)

      if (callbackName === 'updateUserAuthObject') {
        Store.dispatch('setDisplayLoadingFieldVue', true)
        Store.dispatch('setDisplayUnableToLogIn', false)
      }

      return 'success'
    } catch (error) {
      if (callbackName === 'updateUserAuthObject') {
        Store.dispatch('setDisplayLoadingFieldVue', false)
        Store.dispatch('setDisplayUnableToLogIn', true)
      }
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'FieldVue',
        FeatureName: 'AuthorizationManager.js - getConfiguration()',
        ErrorCode: error.name,
        ErrorDescription: `Failed to get the Data for ${callbackName}.`,
        AdditionalDetails: `Message: ${error.message}. Stack: ${error.stack}`,
      })
    }
  },
  async getConfigurationForLocalStorage (URL, b2cToken, LocalStorageKey) {
    try {
      const response = await Axios.get(URL, {
        headers: {
          Authorization: `Bearer ${b2cToken}`,
        },
      })

      Store.$app.$storage.set(LocalStorageKey, response.data)

      return response.data
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'FieldVue',
        FeatureName: 'AuthorizationManager.js - getConfigurationForLocalStorage()',
        ErrorCode: error.name,
        ErrorDescription: `Failed to get the Data for ${LocalStorageKey}.`,
        AdditionalDetails: `Message: ${error.message}. Stack: ${error.stack}`,
      })

      throw error
    }
  },
  getConfigurationFromLocalStorage (LocalStorageKey) {
    return Store.$app.$storage.has(LocalStorageKey) ? Store.$app.$storage.get(LocalStorageKey) : false
  },
  createAuthInfo: function (
    userAuthObject,
    appRegistry,
    dispatch,
    callbackName,
  ) {
    // using a set to make sure there are no duplicate Applications
    const userApplications = new Set()
    const userApplicationsWithPreferences = new Set()
    const appCapabilities = {}
    const customersAndProjects = []

    userAuthObject.Customers.forEach(customer => {
      customersAndProjects.push(customer)
      customer.Projects.forEach(project => {
        project.Applications.forEach(application => {
          const theApplication = getApplication(application, appRegistry)
          if (theApplication) {
            appCapabilities[theApplication.Name.split('/').pop()] = application.Capabilities
            userApplications.add(theApplication)
            if (theApplication.HasPreferences) {
              userApplicationsWithPreferences.add(theApplication)
            }
          }
        })
      })
    })
    const authInfo = {
      UserApplications: Array.from(userApplications),
      UserApplicationsWithPreferences: Array.from(userApplicationsWithPreferences),
      AppCapabilities: appCapabilities,
      Projects: customersAndProjects,
    }
    Store.dispatch(callbackName, authInfo)
    NavigationManager.constructNavState(userApplications)
  },
  addUserToAuthDB: async function (URL, authToken) {
    try {
      const response = await Axios.post(URL,
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        },
      )

      Store.dispatch('updateFirstTimeUser', true)

      const authData = {
        UserId: Store.getters.userId,
        ImpersonatingUser: Store.getters.impersonatingUser,
        ImpersonatingEmailAddress: Store.getters.impersonatingEmailAddress,
      }

      Store.dispatch('getAuthObjectsForUser', {
        authData: authData,
        applicationRegistry: Store.$app.$storage.get('appRegistry'),
      })

      applicationPreferencesManager.saveApplicationPreferencesToTheCloud()

      Store.$app.$azureLogger.writeLogItem('activity', {
        Application: 'FieldVue',
        FeatureName: 'New User',
        ActivityType: 'New User Created',
        AdditionalDetails: JSON.stringify(Store.getters.authAccountObj),
      })
      Store.dispatch('updateEmployeeId', response.data)
      return response.data
    } catch (err) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'FieldVue',
        FeatureName: 'AuthorizationManager.js - addUserToAuthDB()',
        ErrorCode: err.name,
        ErrorDescription: 'Failed to add user to auth db.',
        AdditionalDetails: `Message: ${err.message}. Stack: ${err.stack}`,
      })
    }
  },
}
function getApplication (userApplication, appRegistry) {
  let theApplication
  appRegistry.forEach(application => {
    if (application.Name.split('/').pop() === userApplication.ApplicationName) {
      theApplication = application
    }
  })
  return theApplication
}
