import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"v-card--material px-2 pt-2 pb-0 my-0 mt-0 v-card--material-stats v-card--material--has-heading",attrs:{"elevation":"5"}},[(_vm.dataAvailabilityState.length > 0)?_c('div',{staticStyle:{"height":"230px","width":"100%","overflow":"hidden"}},[_c(VCol,{staticClass:"text-center py-0",staticStyle:{"height":"175px"},attrs:{"cols":"12"}},[_c('div',{staticClass:"black--text d-flex justify-center text-h4"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.dataAvailabilityState === 'loading data')?_c(VProgressCircular,{staticClass:"my-5",attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}}):_vm._e(),_c('div',{class:`black--text d-flex ${_vm.dataAvailabilityState === 'no team' || _vm.dataAvailabilityState === 'loading error' ? 'fill-height' : ''} align-center justify-center text-h4 mb-5`},[_vm._v(" "+_vm._s(_vm.dataUnavailableMessage)+" ")])],1)],1):_c('div',{staticStyle:{"height":"230px","width":"100%","overflow":"hidden"}},[_c(VCol,{staticClass:"text-center py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"black--text d-flex justify-center text-h4"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._t("custom-content")],2),(_vm.$slots['after-heading'])?_c('div',{staticClass:"ml-1"},[_vm._t("after-heading")],2):_vm._e()],1),_c(VCol,{staticClass:"px-0 pb-0",attrs:{"cols":"12"}},[_c(VDivider)],1),_c(VCardActions,{staticClass:"mb-0 py-0"},[_vm._t("actions"),_c(VSpacer),_vm._t("reveal-actions")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }