const messages = {
  DEFAULT: '',
  GENERAL_NETWORK_ERROR: 'There was a network error, some items could not be loaded. If the issue persists contact the help desk.',
  GRID_OPTIONS_SAVED: 'Your grid options have been saved.',
  MY_TEAM_NETWORK: 'There was a network error, please refresh the page and try again before modifying your team.',
  PONI_SEARCH_EXCEEDED_LIMIT: "Number of PONI's exceeded the maximum allowed for the search, please further refine your search criteria.",
  SEARCH_ERROR_NETWORK: 'There was a network error, please try your search again.  If the issue persists contact the help desk.',
  SEARCH_NO_RESULTS: 'Your search returned no results. Modify your search criteria and try again.',
  TEAM_MEMBER_REMOVED: 'The following member(s) of your Team were found to be inactive employees and have been removed:  ',
  TEAM_UNAVAILABLE: 'Your team data is unavailable. Features that require a team may not behave as expected. Navigate to Settings and confirm your team is set up.',
  GENERAL_ERROR: 'There was a problem performing the requested operation.  Please try again.  If the issue persists contact the help desk.',
}
export default messages
