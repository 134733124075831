import Vue from 'vue'
import Router from 'vue-router'

const MyTeam = () => import('@/components/dynamic/MyTeam/MyTeam.vue')
const TaskAssignment = () => import('@/components/dynamic/TaskAssignment/TaskAssignment.vue')
const Today = () => import('@/components/dynamic/Today/Today.vue')
const InitialLogin = () => import('@/components/infrastructure/InitialLogin.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    // redirects
    { path: '/ponicollectiondetails', redirect: '/dctponicollectionstatus' },
    { path: '/ponicollectionstatus', redirect: '/dctponicollectionstatus' },
    { path: '/qcinspectiondetails', redirect: '/remainingqcs' },
    { path: '/safetyauditdetails', redirect: '/remainingaudits' },
    // routes
    // My Team
    {
      path: '/myteam',
      name: 'My Team',
      component: MyTeam,
    },
    //Task Assignment
    {
      path: '/taskassignment',
      name: 'Task Assignment',
      component: TaskAssignment,
    },
    //Today (root)
    {
      path: '/',
      redirect: '/today',
    },
    //Today (widgets)
    {
      path: '/today',
      name: 'Today',
      component: Today,
      //This beforeEnter is used to prevent page compoenents from loading before user authentication
    },
    //Initial Login
    {
      path: '/initiallogin',
      name: 'Initial Login',
      component: InitialLogin,
      props: true,
    },
    {
      path: '/logout',
      name: 'Logout',
    },
  ],
})
