import Axios from 'axios'
import Store from '@/store/store'

export default {
  getApiClient () {
    const apiClient = Axios.create({
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${Store.getters.authToken}`,
        dataType: 'json',
        contentType: 'application/json',
        crossDomain: true,
      },
    })
    return apiClient
  },
  async getFieldVueUsers (includeInActive) {
    const apiClient = this.getApiClient()
    const URL = Store.getters.azureProxyBaseURL + 'FieldVue/Users'
    try {
      const response = await apiClient.get(URL)
      const allUsers = response.data
      const activeOnlyUser = response.data.filter(user => user.IsActive === true)
      let users = activeOnlyUser
      if (includeInActive) {
        users = allUsers
      }

      Store.dispatch('setCurrentUsers', users).then(() => {
        return true
      })
    } catch (err) {
      console.error(err)
    }
  },
  editUserRole (params) {
    const apiClient = this.getApiClient()
    const URL = Store.getters.azureProxyBaseURL + 'FieldVue/UserRole'
    try {
      apiClient.post(URL, params)
    } catch (err) {
      console.error(err)
    }
  },
}
