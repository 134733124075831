export const state = {
  formModes: {
    closed: 'closed',
    completed: 'completed',
    edit: 'edit',
    new: 'new',
    readOnly: 'readOnly',
  },
  testerEmails: [
    {
      emailAddress: {
        address: 'mnatali@osmose.com',
      },
    },
    {
      emailAddress: {
        address: 'jgibbons@osmose.com',
      },
    },
    {
      emailAddress: {
        address: 'tlaratta@osmose.com',
      },
    },
    {
      emailAddress: {
        address: 'gwheelock@osmose.com',
      },
    },
    {
      emailAddress: {
        address: 'wwhite@osmose.com',
      },
    },
    {
      emailAddress: {
        address: 'tkimball@osmose.com',
      },
    },
    {
      emailAddress: {
        address: 'nbarbero@osmose.com',
      },
    },
  ],
}
export const getters = {
  formModes: state => {
    return state.formModes
  },
  testerEmails: state => {
    return state.testerEmails
  },
}
