<template>
  <v-list-item
    :disabled="!isOnline"
    @click="listItemClicked"
  >
    <v-list-item-title>Impersonate User</v-list-item-title>
    <v-dialog
      v-model="display"
      max-width="500"
      persistent
    >
      <v-card
        :style="`max-height: ${this.$vuetify.breakpoint.height - 150 + 'px'}; overflow: hidden;`"
        elevation="5"
        class="mt-0 pt-0"
      >
        <v-row
          align="start"
        >
          <v-col>
            <!-- Base Card Title -->
            <v-card-title
              class="v-dialogHeader text-left px-4 py-1"
            >
              Impersonate User
            </v-card-title>
            <v-form
              class="px-0"
            >
              <!-- Main Form Card -->
              <v-card
                elevation="0"
                style="overflow-x: hidden; overflow-y: auto;"
                :max-height="`${this.$vuetify.breakpoint.height - 275 + 'px'}`"
              >
                <!-- General Information -->
                <v-card
                  class="pa-4 mb-0"
                  elevation="0"
                >
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- User to impersonate -->
                      <v-autocomplete
                        v-model="userToImpersonate"
                        :items="users"
                        :height="fieldHeight"
                        clearable
                        dense
                        required
                        item-text="Name"
                        item-value="EmployeeId"
                        label="Select a user to impersonate"
                        @change="toggleSubmitButton(userToImpersonate)"
                      />
                    </v-col>
                  </v-row>
                  <v-card-title class="text-left pa-0 mb-4">
                    User's Information
                  </v-card-title>
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- User's Name -->
                      <v-text-field
                        v-model="fullName"
                        label="Full Name"
                        :height="fieldHeight"
                        dense
                        readonly
                      />
                    </v-col>
                    <v-col
                      cols="6"

                      class="pb-0"
                    >
                      <!-- User's Email Address -->
                      <v-text-field
                        v-model="emailAddress"
                        label="Email Address"
                        :height="fieldHeight"
                        dense
                        readonly
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Employee Id -->
                      <v-text-field
                        v-model="impersonatedEmployeeId"
                        label="Employee ID"
                        :height="fieldHeight"
                        dense
                        readonly
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Crew ID -->
                      <v-text-field
                        v-model="crewId"
                        label="Crew ID"
                        :height="fieldHeight"
                        dense
                        readonly
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Job title -->
                      <v-text-field
                        v-model="jobTitle"
                        label="Job Title"
                        :height="fieldHeight"
                        dense
                        readonly
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- Login Name -->
                      <v-text-field
                        v-model="loginName"
                        label="Login Name"
                        :height="fieldHeight"
                        dense
                        readonly
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <!-- User Role -->
                      <v-text-field
                        v-model="fieldVueUserRole"
                        label="FieldVue Role"
                        :height="fieldHeight"
                        dense
                        readonly
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
              <v-divider />
              <!-- Form Actions -->
              <v-card-actions class="pr-2 py-3">
                <v-spacer />
                <v-btn
                  color="accent"
                  :disabled="submitButtonDisabled"
                  @click="submit"
                >
                  Submit
                </v-btn>
                <!-- Cancel button -->
                <v-btn
                  color="primary"
                  class="ml-2"
                  @click="reset(true)"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
  import { B2CManager } from '@/Lib/B2CManager.js'
  import { DataSyncManager } from '@/Lib/DataSyncManager'
  import { DateTime } from 'luxon'
  import { mapState } from 'vuex'
  import { referenceDataMixin } from '../../mixins/referenceDataMixin'
  import ApplicationPreferencesManager from '@/components/dynamic/ApplicationPreferences/LocalLib/ApplicationPreferencesManager'
  import messages from '@/messages'
  import UserManager from '@/Lib/UserManager.js'

  export default {
    mixins: [B2CManager, referenceDataMixin],
    data () {
      return {
        display: false,
        fullName: '',
        emailAddress: '',
        crewId: '',
        jobTitle: '',
        loginName: '',
        fieldVueUserRole: '',
        impersonatedEmployeeId: '',
        submitButtonDisabled: true,
        userToImpersonate: null,
        fieldHeight: '20',
        users: [],
        userGUID: '',
      }
    },
    computed: {
      ...mapState({
        isOnline: (state) => state.applicationCoreStore.isOnline,
      }),
    },
    methods: {
      async initialize () {
        this.submitButtonDisabled = true
        await UserManager.getFieldVueUsers(false)
        this.users = this.$store.getters.currentUsers.sort(function (nameA, nameB) {
          if (nameA.Name.toUpperCase() < nameB.Name.toUpperCase()) {
            return -1
          } else if (nameA > nameB) {
            return 1
          } else {
            return 0
          }
        })
        // remove users which do not have all numeric employeeids
        const users = [...this.users.map((user) => {
          const digitsOnly = string => [...string].every(char => '0123456789'.includes(char))
          if (digitsOnly(user.EmployeeId.trim())) {
            return user
          }
        })]
        // remove any holes in the array
        this.users = users.filter(n => n)
        this.reset(false)
       },
      // Clears the form and closes dialog
      reset (closeDialog) {
        this.userToImpersonate = ''
        this.fullName = ''
        this.emailAddress = ''
        this.crewId = ''
        this.jobTitle = ''
        this.loginName = ''
        this.fieldVueUserRole = ''
        this.impersonatedEmployeeId = ''
        this.userGUID = ''
        if (closeDialog) {
          this.display = false
        }
      },
      // Logs navigation to the Impersonate A User
      // Called when the list item "Impersonate A User" is clicked in the Support menu
      listItemClicked () {
        setTimeout(() => {
          this.display = true
          this.initialize()
        })
        this.$azureLogger.writeLogItem('activity', {
          Application: 'FieldVue',
          FeatureName: 'Impersonate User',
          ActivityType: this.$store.getters.loggingActivityType.navigation,
          AdditionalDetails: '',
        })
      },
      // Starts the impersonation process
      async submit () {
        // we need to know who is impersonating who
        const currentUser = this.$store.getters.authAccountObj.name

        // indicates to FV that impersonation is underway
        this.$store.dispatch('updateImpersonating', true)
        this.$store.dispatch('updateImpersonatingEmailAddress', this.emailAddress)

        // replace what can be replaced in the MSAL object with the user's information being impersonated
        await this.initMsalObj(this.userGUID, this.fullName, this.emailAddress).then(async (response) => {
          // Remove any tasks form that belonged to the current user
          if (this.$store.getters.isOnline) {
            const dataSyncManager = new DataSyncManager()
            await dataSyncManager.removeImpersonatedTasks().then(() => {})
          }

          // this is done to have the components using team member data to update
          //this.$store.dispatch('setPreferencesInitialized', false)
          this.$store.dispatch('updateUserId', this.userGUID)
          await ApplicationPreferencesManager.initializeApplicationPreferences()

          // causes the internal FV auth data to be refreshed to the user being impersonated
          this.$store.dispatch('updateAuthToken', {
            accessToken: this.$store.getters.authToken,
            expiresOn: '',
            expChkProcessId: '',
            ImpersonatedUserId: this.userGUID,
          })

          const authData = {
            UserId: this.userGUID,
            ImpersonatingUser: true,
            ImpersonatingEmailAddress: this.emailAddress,
          }
          this.$store.dispatch('getAuthObjectsForUser', {
            authData: authData,
            applicationRegistry: this.$store.$app.$storage.get('appRegistry'),
          })

          this.display = false
          this.$azureLogger.writeLogItem('activity', {
            Application: 'FieldVue',
            FeatureName: 'Impersonate User',
            ActivityType: this.$store.getters.loggingActivityType.impersonating,
            AdditionalDetails: `User: ${currentUser} is Impersonating User: ${this.fullName} - ${this.userToImpersonate.trim()}`,
          })

          this.$store.dispatch('updateEmployeeId', this.impersonatedEmployeeId)

          const dataSyncManager = new DataSyncManager()
          //refresh tasks
          if (await this.getTaskDefinitions()) {
            // update the task data from ServiceNow
            await dataSyncManager.syncTaskData(this.impersonatedEmployeeId)
          }
          // remove crewPoniCounts and dotFailures from LS so they get refreshed at the next opportunity
          this.$storage.remove('crewPoniCounts')
          this.$storage.remove('previousCrewPoniCounts')
          this.$storage.remove('dotFailures')
          this.$storage.remove('fulcrumCrewPoniCounts')
          this.$storage.remove('previousFulcrumCrewPoniCounts')

          // This was added as a fix for #149584
          // Ensures that crewPoniCounts, fulcrumCrewPoniCounts, and dotFailures are refreshed properly
          this.$store.dispatch('updateTeamDataRefreshTime', DateTime.now().ts)

          this.$store.dispatch('setPreferencesInitialized', false)
          this.$forceUpdate()
        })
      },
      // Enables the submit button and displays information about the user selected for impersonation
      toggleSubmitButton (selectedUser) {
        const user = this.users.find(user => user.EmployeeId === selectedUser)
        this.submitButtonDisabled = false
        this.fullName = user.Name
        this.emailAddress = user.EmailAddress
        this.crewId = user.CrewId
        this.jobTitle = user.JobTitle
        this.loginName = user.LoginName
        this.fieldVueUserRole = user.UserRole
        this.impersonatedEmployeeId = user.EmployeeId
        this.userGUID = user.UserGUID
      },
    },
  }
</script>

<style scoped>
  /* Gives chips the same font attributes as buttons */
  .v-chip__content  {
    font-size: 0.85rem;
    font-weight: 400;
  }
</style>
