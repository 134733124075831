/***********************************************
 * NavigationManager
 * *********************************************
 * functions to convert navigation data from services
 * to the data structure needed to render in
 * vue components, and sets state in vuex store.
 *
 * Author: Elijah Parrish
 **********************************************/

import Store from '@/store/store'

export default {
  layerAndSortApps (appRegistry) {
    const registryLength = Object.keys(appRegistry).length
    Object.values(appRegistry).forEach(currentApp => {
      let layerCount = 0
      if (currentApp.ParentId === 0) {
        currentApp.layer = layerCount++
      } else {
        layerCount++
        const currentAppParentId = currentApp.ParentId
        let parentApp = appRegistry.find(app => { return app.Id === currentAppParentId })

        for (let currentAppIndex = 0; currentAppIndex < registryLength; currentAppIndex++) {
          if (parentApp && parentApp.ParentId !== 0) {
            layerCount++
            parentApp = appRegistry.find(app => { return app.Id === parentApp.ParentId })
          } else {
            break
          }
        }
        currentApp.layer = layerCount
      }
    })

    const registrySortedByLayer = Object.values(appRegistry).sort((a, b) => (a.layer < b.layer) ? 1 : -1)
    const registrySortedByOrder = Object.values(registrySortedByLayer).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1)
    return registrySortedByOrder
  },
  constructNavState (userApplications) {
    try {
      const navItemsArray = []
      userApplications = Array.from(userApplications)
      const sortedApps = this.layerAndSortApps(userApplications)
      Object.values(sortedApps).forEach(currentApp => {
        if (currentApp.NavType === 'ExtLink' ||
          currentApp.NavType === 'App' ||
          currentApp.NavType === 'LinkGroup') {
          currentApp.to = currentApp.Name.toLowerCase().replace(/ /g, '') // removes all white space
        }

        if (currentApp.layer !== 0) {
          if (currentApp.DisplayInNav !== false) {
            currentApp.Title = currentApp.Name
            const parentApp = sortedApps.find(app => { return app.Id === currentApp.ParentId })
            if (parentApp && parentApp.children) {
              parentApp.children.push(currentApp)
            } else if (parentApp) {
              parentApp.children = []
              parentApp.children.push(currentApp)
            }
          }
        } else {
          currentApp.Group = '/' + currentApp.Name.toLowerCase()
          currentApp.Title = currentApp.Name

          if (currentApp.DisplayInNav !== false) {
            navItemsArray.push(currentApp)
          }
        }
      })
      Store.dispatch('setNavItems', navItemsArray)
    } catch (error) {
        // what to do here?
        console.error(error)
      }
  },
}
