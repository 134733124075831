import authorizationManager from '@/Lib/AuthorizationManager.js'
import configurationManager from '@/Lib/ConfigurationManager.js'
import applicationPreferencesManager from '@/components/dynamic/ApplicationPreferences/LocalLib/ApplicationPreferencesManager.js'
import Store from '@/store/store'

export const state = {
  AllUserTypes: null,
  AppBaseURL: null,
  authAccountObj: null,
  Authority: null,
  AuthToken: null,
  AzureProxyBaseURL: null,
  Capabilities: null,
  ClientId: null,
  CurrentCustProjectConfig: null,
  currentProject: null,
  CustomerProjectURL: null,
  EmployeeId: null,
  FirstTimeUser: false,
  ImpersonatingEmailAddress: null,
  ImpersonatingUser: false,
  KnownAuthorities: null,
  MSALObj: null,
  PasswordResetURL: null,
  projects: [],
  ServiceNowBaseUrl: null,
  UserApplications: null,
  UserApplicationsWithPreferences: null,
  UserAuthObject: null,
  UserEmail: null,
  UserId: null,
  UserInfo: null,
  UserName: null,
  UserNameShort: null,
  UserPreferences: null,
  UserRoleURL: null,
  WebOpsBaseURL: null,
}
export const mutations = {
  SET_MY_MSAL_OBJ (state, mSALObj) {
    state.MSALObj = mSALObj
  },
  SET_CLIENT_ID (state, clientId) {
    state.ClientId = clientId
  },
  SET_KNOWN_AUTHORITITES (state, knownAuthorities) {
    state.KnownAuthorities = knownAuthorities
  },
  SET_APP_BASE_URL (state, appBaseUrl) {
    state.AppBaseURL = appBaseUrl
  },
  SET_AUTHORITY (state, authority) {
    state.Authority = authority
  },
  SET_PASSWORD_RESET_URL (state, passwordResetURL) {
    state.PasswordResetURL = passwordResetURL
  },
  SET_AUTHTOKEN (state, response) {
    state.AuthToken = response.accessToken
  },
  SET_AUTH_ACCOUNT_OBJ (state, newAuthAccountObj) {
    state.authAccountObj = newAuthAccountObj
  },
  SET_USERNAME (state, userName) {
    state.UserName = userName
  },
  SET_FIRST_TIME_USER (state, firstTimeUser) {
    state.FirstTimeUser = firstTimeUser
  },
  SET_USERNAME_SHORT (state, userNameShort) {
    state.UserNameShort = userNameShort
  },
  SET_USERID (state, userId) {
    state.UserId = userId
  },
  SET_EMPLOYEEID (state, employeeId) {
    state.EmployeeId = employeeId
  },
  SET_USEREMAIL (state, userEmail) {
    state.UserEmail = userEmail
  },
  SET_USER_AUTH_OBJECT (state, userAuthObject) {
    state.UserAuthObject = userAuthObject
    // reconcile here with auth manager
  },
  SET_CUSTOMER_PROJECT_URL (state, customerProjectURL) {
    state.CustomerProjectURL = customerProjectURL
  },
  SET_USER_APPLICATIONS (state, userApplications) {
    state.UserApplications = userApplications
  },
  SET_USER_APPLICATIONS_WITH_PREFERENCES (state, userApplicationsWithPreferences) {
    state.UserApplicationsWithPreferences = userApplicationsWithPreferences
  },
  SET_APP_CAPABILITIES (state, capabilities) {
    state.Capabilities = capabilities
  },
  SET_CURRENT_PROJECT (state, project) {
    state.currentProject = project
  },
  SET_PROJECTS (state, projects) {
    state.projects = projects
  },
  SET_CURRENT_CUST_PROJECT_CONFIG (state, project) {
    state.CurrentCustProjectConfig = project
  },
  SET_USER_INFO (state, userInfo) {
    state.UserInfo = userInfo
  },
  SET_AZURE_PROXY_BASE_URL (state, payload) {
    state.AzureProxyBaseURL = payload
  },
  SET_WEBOPS_BASE_URL (state, payload) {
    state.WebOpsBaseURL = payload
  },
  SET_SERVICE_NOW_BASE_URL (state, payload) {
    state.ServiceNowBaseUrl = payload
  },
  SET_IMPERSONATION (state, payload) {
    state.ImpersonatingUser = payload
  },
  SET_IMPERSONATION_EMAILADDRESS (state, payload) {
    state.ImpersonatingEmailAddress = payload
  },
}
export const getters = {
  authAccountObj: state => {
    return state.authAccountObj
  },
  mSALObj: state => {
    return state.MSALObj
  },
 isCustomer: state => {
    if (state.MSALObj) {
      return !state.MSALObj.account.idTokenClaims.idp
    }
    return false
  },
  clientId: state => {
    return state.ClientId
  },
  knownAuthorities: state => {
    return state.KnownAuthorities
  },
  appBaseURL: state => {
    return state.AppBaseURL
  },
  authority: state => {
    return state.Authority
  },
  authToken: state => {
    return state.AuthToken
  },
  userName: state => {
    return state.UserName
  },
  userNameShort: state => {
    return state.UserNameShort
  },
  userId: state => {
    return state.UserId
  },
  employeeId: state => {
    return state.EmployeeId
  },
  initialTeamRoleId: state => {
    if (state.UserAuthObject) {
      return state.UserAuthObject?.InitialTeamRoleId
    } else {
      return ''
    }
  },
  userRoleName: state => {
    if (state.UserAuthObject) {
      return state.UserAuthObject.RoleName
    } else {
      return ''
    }
  },
  firstTimeUser: state => {
    return state.FirstTimeUser
  },
  userEmail: state => {
    if (state.UserInfo) return state.UserInfo.EmailAddress
    return ''
  },
  // customerId: state => {
  //   if (state.UserInfo) return state.UserInfo.UserPreferences.CustomerId
  //   return ''
  // },
  userApplications: state => {
    return state.UserApplications
  },
  userApplicationsWithPreferences: state => {
    return state.UserApplicationsWithPreferences
  },
  passwordResetURL: state => {
    return state.PasswordResetURL
  },
  userCustomersLength: state => {
    if (state.UserAuthObject === null || state.UserAuthObject.Customers === null) {
      return null
    }
    return state.UserAuthObject.Customers.length
  },
  currentProject: state => {
    return state.currentProject
  },
  // projects: state => {
  //   return state.projects
  // },
  // globalCustomerProject: state => (projectName) => {
  //   for (let i = 0; i < state.projects.length; i++) {
  //     if (state.projects[i].customerName === 'Global Customer') {
  //       const globalCustomerObj = state.projects[i]
  //       for (let j = 0; j < globalCustomerObj.projects.length; j++) {
  //         if (globalCustomerObj.projects[j].projectName === projectName) {
  //           return globalCustomerObj.projects[j]
  //         }
  //       }
  //     }
  //   }
  //   return null
  // },
  hasCapability: state => (application, requestedCapability, project) => {
    let currentProjectCapabilities = []
    let hasCap = false

    if (typeof project !== 'undefined' && project !== null) {
      /* if a project is passed in, then capability existance determenation would be based
      on the passed in project. Otherwise it will be based on the current selected project. */
      const currentProjectApplications = project ? project.Applications : state.currentProject.Applications
      for (let i = 0; i < currentProjectApplications.length; i++) {
        if (currentProjectApplications[i].ApplicationName === application) {
          currentProjectCapabilities = currentProjectApplications[i].Capabilities
          break
        }
      }
      for (let j = 0; j < currentProjectCapabilities.length; j++) {
        if (currentProjectCapabilities[j].CapabilityName === requestedCapability) {
          hasCap = true
        }
      }
    }
    return hasCap
  },
  getUserApplicationId: state => (applicationName) => {
    const currentProjectApplications = state.currentProject.Applications
    const application = currentProjectApplications.find(app => { return app.ApplicationName === applicationName })
    if (application) {
      return application.ApplicationId
    }
    return null
  },
  hasProject: state => {
    return state.projects.length
  },
  azureProxyBaseURL: state => {
    return state.AzureProxyBaseURL
  },
  webOpsBaseURL: state => {
    return state.WebOpsBaseURL
  },
  serviceNowBaseURL: state => {
    return state.ServiceNowBaseUrl
  },
  impersonatingUser: state => {
    return state.ImpersonatingUser
  },
  impersonatingEmailAddress: state => {
    return state.ImpersonatingEmailAddress
  },
  userAuthObject: state => {
    return state.UserAuthObject
  },
}
export const actions = {
  updateMSALObj ({ commit }, mSALObj) {
    commit('SET_MY_MSAL_OBJ', mSALObj)
  },
  updateUserAuthObject ({ commit }, userAuthObject) {
    commit('SET_USER_AUTH_OBJECT', userAuthObject)
  },
  updateClientId ({ commit }, clientId) {
    commit('SET_CLIENT_ID', clientId)
  },
  updateKnownAuthorities ({ commit }, knownAuthorities) {
    commit('SET_KNOWN_AUTHORITITES', knownAuthorities)
  },
  updateBaseURL ({ commit }, appBaseURL) {
    commit('SET_APP_BASE_URL', appBaseURL)
  },
  updateAuthority ({ commit }, authority) {
    commit('SET_AUTHORITY', authority)
  },
  updatePasswordPolicyResetURL ({ commit }, passwordResetURL) {
    commit('SET_PASSWORD_RESET_URL', passwordResetURL)
  },
  async updateAuthToken ({ state, commit, dispatch }, response) {
    if (!state.ImpersonatingUser) {
      commit('SET_AUTHTOKEN', response)
    }
    if (response.accessToken && !response.notFirstTime) dispatch('getApplicationRegistry', state)
    if (response.accessToken && !response.notFirstTime) dispatch('getUserInfo')
  },
  updateAuthAccountObj ({ commit }, newAuthAccountObj) {
    commit('SET_AUTH_ACCOUNT_OBJ', newAuthAccountObj)
  },
  updateUserName ({ commit }, userName) {
    commit('SET_USERNAME', userName)
  },
  updateFirstTimeUser ({ commit }, firstTimeUser) {
    commit('SET_FIRST_TIME_USER', firstTimeUser)
  },
  updateUserNameShort ({ commit }, userNameShort) {
    commit('SET_USERNAME_SHORT', userNameShort)
  },
  updateUserId ({ commit }, userId) {
    commit('SET_USERID', userId)
  },
  updateEmployeeId ({ commit }, employeeId) {
    commit('SET_EMPLOYEEID', employeeId)
  },
  updateUserEmail ({ commit }, userEmail) {
    commit('SET_USEREMAIL', userEmail)
  },
  async updateAuthInfo ({ dispatch }, authInfo) {
    dispatch('updateUserApplications', authInfo.UserApplications)
    dispatch('updateUserApplicationsWithPreferences', authInfo.UserApplicationsWithPreferences)
    dispatch('updateAppCababilities', authInfo.AppCapabilities)
    dispatch('updateProjects', authInfo.Projects)
    dispatch('updateCurrentProjToDefaultProj', authInfo.Projects)
  },
  updateCustomerProjectURL ({ state, commit }, customerProjectURL) {
    commit('SET_CUSTOMER_PROJECT_URL', customerProjectURL)
  },
  updateUserApplications ({ commit }, userApplications) {
    commit('SET_USER_APPLICATIONS', userApplications)
  },
  updateUserApplicationsWithPreferences ({ commit }, userApplicationsWithPreferences) {
    commit('SET_USER_APPLICATIONS_WITH_PREFERENCES', userApplicationsWithPreferences)
  },
  updateAppCababilities ({ commit }, appCapabilities) {
    commit('SET_APP_CAPABILITIES', appCapabilities)
  },
  async getAuthObjectsForUser ({ state, commit, dispatch }, { authData, applicationRegistry }) {
    try {
      Store.dispatch('setFvLoadingMessage', 'Loading your account...')
      
      let userAuthObject = false

      if (this.getters.isOnline) {
        userAuthObject = await authorizationManager.getConfigurationForLocalStorage(
          state.AzureProxyBaseURL + (!authData.ImpersonatingUser ? `FieldVue/Authorization/${authData.UserId}` : `FieldVue/Authorization/${authData.UserId}/${authData.ImpersonatingEmailAddress}`),
          state.AuthToken,
          'userAuthObject',
        )
      } else {
        userAuthObject = this.$app.$storage.get('userAuthObject')
      }
      if (userAuthObject && userAuthObject.Customers.length > 0) {
        const employeeId = userAuthObject.EmployeeId.trim()
        commit('SET_USER_AUTH_OBJECT', userAuthObject)
        dispatch('updateEmployeeId', employeeId)

        authorizationManager.createAuthInfo(
          userAuthObject,
          applicationRegistry,
          dispatch,
          'updateAuthInfo',
        )

        // Get Preferences here
        await applicationPreferencesManager.initializeApplicationPreferences()
      } else {
        // add user to auth DB and start over
        await authorizationManager.addUserToAuthDB(
          `${state.AzureProxyBaseURL}FieldVue/User`,
          state.AuthToken,
        )
      }

      dispatch('setDisplayLoadingFieldVue', true)
      dispatch('setDisplayUnableToLogIn', false)
    } catch (error) {
      // what to do here?
      console.error(`Error: ${error}`)
      dispatch('setDisplayLoadingFieldVue', false)
      dispatch('setDisplayUnableToLogIn', true)
    }
  },

  async getApplicationRegistry ({ state, dispatch }) {
    Store.dispatch('setFvLoadingMessage', 'Getting your applications...')

    const applicationRegistry = await authorizationManager.getConfigurationForLocalStorage(
      state.AzureProxyBaseURL + 'FieldVue/Applications/',
      state.AuthToken,
      'appRegistry',
    )

    const authData = {
      UserId: state.UserId,
      ImpersonatingUser: state.ImpersonatingUser,
      ImpersonatingEmailAddress: state.ImpersonatingEmailAddress,
    }
    dispatch('getAuthObjectsForUser', {
      authData: authData,
      applicationRegistry: applicationRegistry,
    })
  },
  updateCurrentCustProjectConfig ({ state, commit, dispatch }, project) {
    if (project) {
      commit('SET_CURRENT_CUST_PROJECT_CONFIG', project)
    }
  },
  updateCurrentProject ({ state, commit, dispatch }, project) {
    commit('SET_CURRENT_PROJECT', project)
    // dispatch('getCustomerProjectInfo', project.ProjectId)
  },
  updateProjects ({ state, commit }, projects) {
    commit('SET_PROJECTS', projects)
  },
  updateCurrentProjToDefaultProj ({ state, commit, dispatch }, Projects) {
    let defaultProject = null
    outerloop: for (const x in Projects) {
      const customer = Projects[x]
      // defaultProject = getDefaultProject(projects)
      innerloop: for (const y in customer.Projects) {
        const Project = customer.Projects[y]
        if (Project.IsDefault) {
          defaultProject = Project
          break outerloop
        }
      }
    }
    dispatch('updateCurrentProject', defaultProject)
  },
  getUserInfo ({ state, commit, dispatch, getters }) {
    if (getters.IsCustomer) {
      authorizationManager.getConfiguration(
        state.UserInfoURL,
        state.AuthToken,
        dispatch,
        'updateUserInfo',
        commit,
      )
    }
  },
  updateUserInfo ({ state, commit }, userInfo) {
    // if (userInfo) userInfo = userInfo[0];
    commit('SET_USER_INFO', userInfo)
  },
  updateUserPreferences ({ state, commit }, userPreferences) {
    commit('SET_USER_PREFERENCES', userPreferences)
  },
  getCustomerProjectInfo ({ state, commit, dispatch }, currentProjectId) {
    configurationManager.getO360tProjectsObj(
      currentProjectId,
      state.AuthToken,
      dispatch,
      'updateCurrentCustProjectConfig',
      state.CustomerProjectURL,
    )
  },
  updateAzureProxyBaseURL ({ commit }, AzureProxyBaseURL) {
    commit('SET_AZURE_PROXY_BASE_URL', AzureProxyBaseURL)
  },
  updateWebOpsBaseURL ({ commit }, WebOpsBaseURL) {
    commit('SET_WEBOPS_BASE_URL', WebOpsBaseURL)
  },
  updateServiceNowBaseURL ({ commit }, ServiceNowBaseURL) {
    commit('SET_SERVICE_NOW_BASE_URL', ServiceNowBaseURL)
  },
  updateImpersonating ({ commit }, impersonatingUser) {
    commit('SET_IMPERSONATION', impersonatingUser)
  },
  updateImpersonatingEmailAddress ({ commit }, impersonatingemailAddress) {
    commit('SET_IMPERSONATION_EMAILADDRESS', impersonatingemailAddress)
  },
}
