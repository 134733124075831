import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListGroup,{directives:[{name:"show",rawName:"v-show",value:(_vm.computedMobileDisplay),expression:"computedMobileDisplay"}],attrs:{"group":_vm.group,"prepend-icon":_vm.item.icon,"sub-group":_vm.subGroup,"disabled":!_vm.isOnline && _vm.item.OnlineOnly,"append-icon":"fa-solid fa-caret-down","color":_vm.barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)' ? 'white' : 'grey darken-1',"active-class":_vm.isLinkGroup,"title":_vm.item.Title},on:{"click":function($event){return _vm.navGroupClicked(_vm.item.Title)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(_vm.item.NavIconDisplay)?_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.item.NavIcon)}})],1):_c(VListItemIcon,{staticClass:"pl-1 v-list-item__icon--text text-h3 font-weight-bold",domProps:{"textContent":_vm._s(_vm.computedText)}}),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-override",domProps:{"textContent":_vm._s(_vm.item.Title)}})],1)]},proxy:true}])},[_vm._l((_vm.children),function(child,i){return [(child.children)?_c('nav-group',{key:`sub-group-${i}`,staticClass:"nav-sub-group",attrs:{"item":child},on:{"clicked":function($event){return _vm.removePrimaryFromGroup()}}}):_c('nav-item',{key:`item-${i}`,staticClass:"nav-item",attrs:{"item":child,"link-level":"child","text":""},on:{"clicked":function($event){return _vm.removePrimaryFromGroup()}}})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }