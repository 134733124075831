import Axios from 'axios'
import Store from '@/store/store'

export default {
  async getLinks () {
    try {
      const response = await Axios.get(
        `${Store.getters.azureProxyBaseURL}FieldVue/Links`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Store.getters.authToken}`,
          },
        },
      )

      return response.data
    } catch (error) {
    }
  },
}
