<script>
  import { Doughnut } from 'vue-chartjs'
  // eslint-disable-next-line
  import ChartDataLabels from "chartjs-plugin-datalabels"
  // eslint-disable-next-line
  import DoughnutHoleLabel from '@/components/infrastructure/Charts/Base/doughnutholelabel.js'

  export default {
    extends: Doughnut,
    props: {
      chartdata: {
        type: Object,
        default: null,
      },
      options: {
        type: Object,
        default: function () {
          return {
            responsive: true,
            maintainAspectRatio: false,
          }
        },
      },
      forceRerender: { type: Number, default: 0 },
    },
    watch: {
      chartdata (newVal, oldVal) {
        // eslint-disable-next-line
        if (newVal !== oldVal) this.renderChart(this.chartdata, this.options)
      },
      forceRerender (newVal, oldVal) {
        this.renderChart(this.chartdata, this.options)
      },
    },
    mounted () {
      this.renderChart(this.chartdata, this.options)
    },
  }
</script>
