import Axios from 'axios'
import Store from '@/store/store'
import { DateTime } from 'luxon'
import SyncDateTimesManager from '@/Lib/SyncDateTimesManager'

export default {
  async performSync (localStorage) {
    // get an instance of the syncDateTimesManager
    const syncDateTimeManager = new SyncDateTimesManager(localStorage)
    // get the current dateTime
    const currentDateTime = DateTime.local()

    const URL = `${Store.getters.azureProxyBaseURL}FieldVue/SNToken`

    try {
      const snakResponse = await Axios.get(URL,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Store.getters.authToken}`,
          },
        },
      )

      localStorage.set('snak', snakResponse.data)

      // update the snak lastSyncDateTime obj
      syncDateTimeManager.setSyncDateTime('snak', { lastSync: currentDateTime, duration: 720 })

      return true
    } catch (error) {
      let errorName = ''
      let errorMsg = ''

      if (error.response) {
        errorName = error.response.status
        errorMsg = error.response.headers
      } else if (error.request) {
        errorName = 'No Error Name Received'
        errorMsg = error.request
      } else {
        errorName = 'Axios Setup Error'
        errorMsg = error.message
      }

      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'FieldVue',
        FeatureName: 'snakManager.js - performSync()',
        ErrorCode: errorName,
        ErrorDescription: 'Failed to get SNAK.',
        AdditionalDetails: `Message: ${errorMsg}. Stack: ${error.stack}`,
      })

      return false
    }
  },
  async getSNAK (localStorage) {
    let snak = ''
    // get and instance of the syncDateTimesManager
    const syncDateTimeManager = new SyncDateTimesManager(localStorage)
    //  get the snak lastSyncDateTime obj
    const lastSyncDateTime = syncDateTimeManager.getSyncDateTime('snak')
    // get the current dateTime
    const currentDateTime = DateTime.local()

    // if we need to get new SNAK
    if (lastSyncDateTime === false || currentDateTime.diff(DateTime.fromISO(lastSyncDateTime.lastSync), 'minutes').toFormat('m') >= lastSyncDateTime.duration) {
        await this.performSync(localStorage)
    }

    snak = localStorage.get('snak')

    return snak.access_token
  },
}
