import Store from '@/store/store'
import EmployeeManager from '@/Lib/EmployeeManager'

export const employeeDataWorker = async (params) => {
  // **************************************************************************************
  // a. See if this is a first-time user situation, we don't run this for them
  // b. Use the EmployeeManager to get/update the employee data

  if (!params.firstTimeUser) {
    return fetchEmployees(params)
  }
}

async function fetchEmployees (params) {
  try {
    Store.dispatch('updateTaskSyncExecuting', true)
    const employees = await EmployeeManager.getEmployees()
    Store.dispatch('updateTaskSyncExecuting', false)
    Store.dispatch('updateEmployeeDataIsAvailable', true)
    return employees
  } catch (err) {
    Store.$app.$azureLogger.writeLogItem('error', {
      Application: 'employeeRefData web worker',
      FeatureName: 'get employees',
      ErrorCode: err.name,
      ErrorDescription: 'Failed to get employees.',
      AdditionalDetails: `Message: ${err.message}. Stack ${err.stack}`,
    })
  }
}
