import Axios from 'axios'
import Store from '@/store/store'
import SnakManager from '@/Lib/SnakManager'

export default {
  async submitRequest (request) {
    const URL = `${Store.getters.serviceNowBaseURL}fmt/CreateEnhancementRequest`
    const snak = await SnakManager.getSNAK(Store.$app.$storage)

    try {
      const response = await Axios.post(URL, request,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${snak}`,
          },
        })
      return response
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'Feature Request',
        FeatureName: 'submitRequest()',
        ErrorCode: 'Axios Error',
        ErrorDescription: 'Failed to post Feature Request to Service Now.',
        AdditionalDetails: `Message: ${typeof error.message === 'object' && error.message !== null ? JSON.stringify(error.message) : error.message}. Stack: ${error.stack}`,
      })
    }
  },
}
