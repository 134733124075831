/* eslint-disable no-console */
import { register } from 'register-service-worker'
self.addEventListener('fetch', function (event) {}) // add this to fool it into thinking its offline ready

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      // console.log('App is being served from cache by a service worker.\n')
    },
    registered () {
      // console.log('Service worker has been registered.')
      //  setInterval(() => {
      //   registration.update()
      // }, 5000) // every 5 seconds
    },
    cached () {
      // console.log('Content has been cached for offline use.')
    },
    updatefound () {
      // console.log('updatefound')
      // if this is not a new install
      if (localStorage.getItem('FV_syncDateTimes') !== null) {
        console.log('New content is downloading.')
        document.dispatchEvent(
          new CustomEvent('swDownloading', {}),
        )
      }
    },
    updated (registration) {
      // console.log('updated')

      // console.log('New content is available; please refresh.')
      // registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      // console.log('dispatching the swUpdated event')
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration }),
      )
    },
    offline () {
      // console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
