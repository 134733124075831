<template>
  <v-list-item
    :href="item.LinkUrl"
    :active-class="`primary ${!isDark ? 'black' : 'white'}--text`"
    target="_blank"
    :disabled="!isOnline && item.OnlineOnly"
    @click="writeToLogging()"
  >
    <v-list-item-icon
      v-if="item.NavIconDisplay"
    >
      <v-icon
        small
        v-text="item.NavIcon"
      />
    </v-list-item-icon>
    <v-list-item-icon
      v-else
      class="v-list-item__icon--text"
      v-text="ComputedText"
    />

    <v-list-item-content
      v-if="item.Title || item.Subtitle"
    >
      <v-list-item-title
        v-text="item.Title"
      />
      <v-list-item-subtitle
        v-text="item.Subtitle"
      />
    </v-list-item-content>
    <v-icon small>
      fa-solid fa-arrow-up-right-from-square
    </v-icon>
  </v-list-item>
</template>

<script>
  import Themeable from 'vuetify/lib/mixins/themeable'
  import { mapState } from 'vuex'

  export default {
    name: 'NavItem',
    mixins: [Themeable],
    props: {
      item: {
        type: Object,
        default: () => ({
          linkUrl: undefined,
          icon: undefined,
          subtitle: undefined,
          title: undefined,
          to: undefined,
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState({
        isOnline: (state) => state.applicationCoreStore.isOnline,
      }),
      computedText () {
        if (!this.item || !this.item.Title) return ''

        let text = ''

        this.item.Title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },
      href () {
        return this.item.Href || (!this.item.to ? '#' : undefined)
      },
    },
    methods: {
      writeToLogging () {
        this.$azureLogger.writeLogItem('activity', {
          Application: this.item.Title,
          FeatureName: '',
          ActivityType: this.$store.getters.loggingActivityType.navigation,
          AdditionalDetails: '',
        })
      },
    },
  }
</script>

<style lang="sass">
.v-list-item
  text-align: left
.v-list-item__icon
  float: right !important
</style>
