/**
 * @function
 * @param {!object} obj  - The object which contains the value you want to change/set.
 * @param {!array} path  - The array representation of path to the value you want to change/set.
 * @param {!mixed} value - The value you want to set it to.
 * @param {boolean} setrecursively - If true, will set value of non-existing path as well.
 */
function setDeepObject (obj, path, value, setrecursively = false) {
  path.reduce((a, b, level) => {
      if (
        setrecursively &&
        level !== path.length - 1 &&
        (typeof a[b] === 'undefined' || a[b] === null)
      ) {
          a[b] = {}
          return a[b]
      }

      if (level === path.length - 1) {
          a[b] = value
          return value
      }
      return a[b]
  }, obj)
}

export const setDeep = {
  setDeepObject,
}
