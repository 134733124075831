export default {
  state: {
    currentApplications: [],
    currentCapabilities: [],
    currentRoles: [],
    currentPracs: [],
    currentUsers: [],
  },
  getters: {
    currentApplications (state) {
      return state.currentApplications
    },
    currentCapabilities (state) {
      return state.currentCapabilities
    },
    currentRoles (state) {
      return state.currentRoles
    },
    currentPracs (state) {
      return state.currentPracs
    },
    currentUsers (state) {
      return state.currentUsers
    },
  },
  mutations: {
    // Applications
    SET_CURRENT_APPLICATIONS (state, applications) {
      state.currentApplications = applications
    },
    UPDATE_CURRENT_APPLICATION (state, application) {
      const applicationToUpdate = state.currentApplications.find(
        item => item.Id === application.Id)
      Object.assign(applicationToUpdate, application)
    },
    ADD_CURRENT_APPLICATION (state, application) {
      state.currentApplications.push(application)
    },
    DELETE_CURRENT_APPLICATION (state, application) {
      const indexToDelete = state.currentApplications.findIndex(
        item => item.ApplicationId === application.ApplicationId)
      state.currentApplications.splice(indexToDelete, 1)
    },
    // Capabilities
    SET_CURRENT_CAPABILITIES (state, capabilities) {
      state.currentCapabilities = capabilities
    },
    UPDATE_CURRENT_CAPABILITY (state, capability) {
      const capabilityToUpdate = state.currentCapabilities.find(
        item => item.CapabilityId === capability.CapabilityId)
      Object.assign(capabilityToUpdate, capability)
    },
    ADD_CURRENT_CAPABILITY (state, capability) {
      state.currentCapabilities.push(capability)
    },
    DELETE_CURRENT_CAPABILITY (state, capability) {
      const indexToDelete = state.currentCapabilities.findIndex(
        item => item.CapabilityId === capability.CapabilityId)
      state.currentCapabilities.splice(indexToDelete, 1)
    },
    // Roles
    SET_CURRENT_ROLES (state, roles) {
      state.currentRoles = roles
    },
    UPDATE_CURRENT_ROLE (state, role) {
      const roleToUpdate = state.currentRoles.find(
        item => item.RoleId === role.RoleId)
      Object.assign(roleToUpdate, role)
    },
    ADD_CURRENT_ROLE (state, role) {
      state.currentRoles.push(role)
    },
    DELETE_CURRENT_ROLE (state, role) {
      const indexToDelete = state.currentRoles.findIndex(
        item => item.RoleId === role.RoleId)
      state.currentRoles.splice(indexToDelete, 1)
    },
    // PRACS (Project, Role, Application - Capability)
    SET_CURRENT_PRACS (state, pracs) {
      state.currentPracs = pracs
    },
    UPDATE_CURRENT_PRAC (state, prac) {
      const pracToUpdate = state.currentPracs.find(
        item => item.PracId === prac.PracId)
      Object.assign(pracToUpdate, prac)
    },
    ADD_CURRENT_PRAC (state, prac) {
      state.currentPracs.push(prac)
    },
    DELETE_CURRENT_PRAC (state, prac) {
      const indexToDelete = state.currentPracs.findIndex(
        item => item.PracId === prac.PracId)
      state.currentPracs.splice(indexToDelete, 1)
    },
    // Users
    SET_CURRENT_USERS (state, users) {
      state.currentUsers = users
    },
    UPDATE_CURRENT_USER (state, user) {
      const userToUpdate = state.currentApplications.find(
        item => item.Id === user.Id)
      Object.assign(userToUpdate, user)
    },
  },
  actions: {
    // Applications
    setCurrentApplications ({ commit }, currentApplications) {
      commit('SET_CURRENT_APPLICATIONS', currentApplications)
    },
    editApplication ({ commit }, applicationToUpdate) {
      commit('UPDATE_CURRENT_APPLICATION', applicationToUpdate)
    },
    addApplication ({ commit }, applicationToAdd) {
      commit('ADD_CURRENT_APPLICATION', applicationToAdd)
    },
    // Capabilities
    setCurrentCapabilities ({ commit }, currentCapabilities) {
      commit('SET_CURRENT_CAPABILITIES', currentCapabilities)
    },
    editCapability ({ commit }, capabilityToUpdate) {
      commit('UPDATE_CURRENT_CAPABILITY', capabilityToUpdate)
    },
    addCapability ({ commit }, capabilityToAdd) {
      commit('ADD_CURRENT_CAPABILITY', capabilityToAdd)
    },
    deleteCapability ({ commit }, capabilityToDelete) {
      commit('DELETE_CURRENT_CAPABILITY', capabilityToDelete)
    },
    // Roles
    setCurrentRoles ({ commit }, currentRoles) {
      commit('SET_CURRENT_ROLES', currentRoles)
    },
    editRole ({ commit }, roleToUpdate) {
      commit('UPDATE_CURRENT_ROLE', roleToUpdate)
    },
    addRole ({ commit }, roleToAdd) {
      commit('ADD_CURRENT_ROLE', roleToAdd)
    },
    deleteRole ({ commit }, roleToDelete) {
      commit('DELETE_CURRENT_ROLE', roleToDelete)
    },
    // PRACS
    setCurrentPracs ({ commit }, currentPracs) {
      commit('SET_CURRENT_PRACS', currentPracs)
    },
    addPrac ({ commit }, pracToAdd) {
      commit('ADD_CURRENT_PRAC', pracToAdd)
    },
    deletePrac ({ commit }, pracToDelete) {
      commit('DELETE_CURRENT_PRAC', pracToDelete)
    },
    // Users
    setCurrentUsers ({ commit }, currentUsers) {
      commit('SET_CURRENT_USERS', currentUsers)
    },
    editUsers ({ commit }, userToUpdate) {
      commit('UPDATE_CURRENT_USER', userToUpdate)
    },
  },
}
