import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{on:{"click":_vm.listItemClicked}},[_c(VListItemTitle,[_vm._v("Profile")]),_c(VDialog,{attrs:{"max-width":"300","persistent":""},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c(VCard,{staticClass:"text-left"},[_c(VCardTitle,{staticClass:"v-dialogHeader text-left px-3 py-1"},[_vm._v(" Profile ")]),_c(VCardText,{staticClass:"px-3 py-3"},[_c('h3',[_vm._v(_vm._s(_vm.userName))]),_c('p',[_vm._v(" User Role: "+_vm._s(_vm.userRole)+" ")])]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"accent"},on:{"click":_vm.close}},[_vm._v(" Close ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }