<template>
  <Component
    :is="cardContent"
    :target-nav-type="targetNavType"
    :navigate="cardNavigate"
    :link-url="linkUrl"
  />
</template>

<script>
  import CardNavBase from './CardNavBase.vue'
  export default {
    extends: CardNavBase,
    props: {
      targetComponunt: String,
      targetNavType: String,
      targetPath: String,
      linkUrl: String,
      entryPoint: String,
    },
    computed: {
      cardContent () {
        return () =>
        import(`../components/dynamic/${this.entryPoint}Content`)
      },
    },
    methods: {},
  }
</script>
<style scoped>
</style>
