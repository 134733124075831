export const NavigationHelperMixin = {
  methods: {
    pushRouteTo (route) {
      if (typeof route === 'string') {
        if (this.$route.path !== route) {
            this.$router.push(route)
        }
      } else { // if sending a {name: '', ...}
        if (this.$route.name === route.name || this.$route.path.substring(1) === route.path) {
          if ('params' in route) {
            let routesMatched = true
            for (const key in this.$route.params) {
              const value = this.$route.params[key]
              if (value === null || value === undefined) {
                if (key in route.params) {
                  if (route.params[key] !== undefined && route.params[key] !== null) {
                    routesMatched = false
                    break
                  }
                }
              } else {
                if (key in route.params) {
                  if (this.$router.getRoutes().params[key] !== value) {
                    routesMatched = false
                    break
                  }
                } else {
                  routesMatched = false
                  break
                }
              }
              if (!routesMatched) {
                this.$router.push(route)
              }
            }
          } else {
            if (Object.keys(this.$route.params).length !== 0) {
              this.$router.push(route)
            }
          }
        } else {
          this.$router.push(route)
        }
      }
    },
  },
}
