import Vue from 'vue'
import Vuex from 'vuex'
import * as applicationCoreStore from '@/store/Modules/ApplicationCoreStore.js'
import * as applicationPreferencesStore from '@/components/dynamic/ApplicationPreferences/LocalStore/ApplicationPreferencesStore.js'
import * as authorizationStore from '@/store/Modules/AuthorizationStore.js'
import * as globalConstantsStore from '@/store/Modules/GlobalConstantsStore.js'
import * as navigationStore from '@/store/Modules/NavigationStore.js'
import * as QCInspectionDetails from '@/components/dynamic/QCInspectionDetails/LocalStore/QCInspectionDetails.js'
import * as SafetyAuditDetails from '@/components/dynamic/SafetyAuditDetails/LocalStore/SafetyAuditDetails.js'
// import * as alertStore from '@/store/Modules/AlertStore.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    applicationCoreStore,
    applicationPreferencesStore,
    authorizationStore,
    globalConstantsStore,
    navigationStore,
    QCInspectionDetails,
    SafetyAuditDetails,
    // alertStore,
  },
})
