<template>
  <v-list-item
    :disabled="!isOnline"
    @click="listItemClicked"
  >
    <v-list-item-title>End Impersonation</v-list-item-title>
  </v-list-item>
</template>

<script>
import ApplicationControlStore from '@/store/Modules/ApplicationControlStore.js'
import { mapState } from 'vuex'
import { DataSyncManager } from '@/Lib/DataSyncManager'

export default {
  computed: {
    ...mapState({
      isOnline: (state) => state.applicationCoreStore.isOnline,
    }),
  },
  async created () {
    if (!this.$store.hasModule('ApplicationControlStore')) {
      this.$store.registerModule(
        'ApplicationControlStore',
        ApplicationControlStore,
      )
    }
  },
  methods: {
    async initialize () {
      // remove any tasks form that belonged to the impersonate user
      if (this.$store.getters.isOnline) {
        const dataSyncManager = new DataSyncManager()
        await dataSyncManager.removeImpersonatedTasks(this.$store.getters.employeeId)
      }
    },
    // Logs navigation to the Impersonate A User
    // Called when the list item "Impersonate A User" is clicked in the Support menu
    async listItemClicked () {
      const self = this
      setTimeout(() => {
        this.initialize()
      })
      await this.$azureLogger.writeLogItem('activity', {
        Application: 'FieldVue',
        FeatureName: 'End Impersonate User',
        ActivityType: self.$store.getters.loggingActivityType.impersonating,
        AdditionalDetails: `User: ${self.$store.getters.authAccountObj.name} Stopped Impersonating User: ${self.$store.getters.userName} - ${self.$store.getters.employeeId}`,
      })

      if (self.$route.name !== 'Today') {
        self.$router.push('today')
        window.location.reload()
      } else {
        window.location.reload()
      }
    },
  },
}
</script>

<style scoped>
/* Gives chips the same font attributes as buttons */
.v-chip__content {
  font-size: 0.85rem;
  font-weight: 400;
}
</style>
