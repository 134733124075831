<template>
  <v-list-item
    :disabled="!isOnline"
    @click="listItemClicked"
  >
    <v-list-item-title>Refresh App Data</v-list-item-title>
    <v-dialog
      v-model="display"
      max-width="350"
      persistent
    >
      <v-card
        height="15vh"
        :style="`max-height: ${this.$vuetify.breakpoint.height - 150 + 'px'}; overflow: hidden;`"
        elevation="5"
        class="mt-0 pt-0"
      >
        <v-row
          align="start"
        >
          <v-col>
            <!-- Base Card Title -->
            <v-card-title
              class="v-dialogHeader text-left px-3 py-1"
            >
              Refreshing App Data
            </v-card-title>
            <v-card-text
              class="py-5"
            >
              <p>Updating App Data</p>
              <v-spacer
                class="py-2"
              />
              <v-progress-linear
                indeterminate
                class="mb-0"
              />
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
  import { mapState } from 'vuex'
  import EmployeeManager from '@/Lib/EmployeeManager'
  import messages from '@/messages'
  import PreferencesManager from '@/components/dynamic/ApplicationPreferences/LocalLib/ApplicationPreferencesManager'
  import ReferenceDataManager from '@/Lib/ReferenceDataManager'
  import Store from '@/store/store'

  export default {
    data () {
        return {
          display: false,
          notificationId: '01905498-81eb-7792-bc11-ce64b03a9e94',
        }
    },
    computed: {
      ...mapState({
        isOnline: (state) => state.applicationCoreStore.isOnline,
      }),
    },
    methods: {
      async listItemClicked () {
        const self = this
        setTimeout(async () => {
          self.display = true
          self.$storage.set('globalSyncOverride', true)
          try {
            Store.dispatch('updateTaskSyncExecuting', true)
            await PreferencesManager.initializeApplicationPreferences()
            await EmployeeManager.getEmployees()
            await ReferenceDataManager.getReferenceDataFromServiceNow('preservatives')
            await ReferenceDataManager.getReferenceDataFromServiceNow('trucks')
            await ReferenceDataManager.getReferenceDataFromServiceNow('trailers')
            await ReferenceDataManager.getReferenceDataFromServer('all', '1900-01-01%2005:00')
            self.display = false
            Store.dispatch('updateTaskSyncExecuting', false)
          } catch (error) {
            self.display = false
            Store.$app.$azureLogger.writeLogItem('error', {
              Application: 'RefreshAppData',
              FeatureName: 'RefreshAppData',
              ErrorCode: 'Axios Error',
              ErrorDescription: 'Failed to update app data.',
              AdditionalDetails: `Message: ${
                typeof error.message === 'object' && error.message !== null
                  ? JSON.stringify(error.message)
                  : error.message
              }. Stack: ${error.stack}`,
            })

            this.$notificationPlugin.add(
              DateTime.now().toISO(),
              DateTime.now().toISO(),
              this.notificationId,
              messages.GENERAL_NETWORK_ERROR,
              'high',
              false
            )
          }
        })
        await this.$azureLogger.writeLogItem('activity', {
          Application: 'FieldVue',
          FeatureName: 'Refresh App Data',
          ActivityType: self.$store.getters.loggingActivityType.refreshing,
          AdditionalDetails: `User: ${self.$store.getters.authAccountObj.name}`,
        })
      },
    },
  }
</script>

<style scoped>
/* Gives chips the same font attributes as buttons */
.v-chip__content {
  font-size: 0.85rem;
  font-weight: 400;
}
</style>
