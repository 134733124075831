export const state = {
  qcInspectionDetails: [],
  qcInspectionLastSyncDateTime: null,
  qcInspectionDetailsUpdatedOn: null,
}

export const mutations = {
  SET_QC_INSPECTION_DETAILS (state, qcInspectionDetails) {
    state.qcInspectionDetails = qcInspectionDetails
  },
  SET_QC_INSPECTION_LAST_SYNC (state, lastSyncDateTime) {
    state.qcInspectionLastSyncDateTime = lastSyncDateTime
  },
  SET_QC_INSPECTION_DETAILS_UPDATED_ON (state, qcInspectionDetailsUpdatedOn) {
    state.qcInspectionDetailsUpdatedOn = qcInspectionDetailsUpdatedOn
  },
}

export const getters = {
  getQCInspectionDetails (state) {
    return state.qcInspectionDetails
  },
  getQCInspectionDetailsLastSync (state) {
    return state?.qcInspectionLastSyncDateTime
  },
  getQCInspectionDetailsUpdatedOn (state) {
    return state.qcInspectionDetailsUpdatedOn
  },
  hasQCInspectionDetails (state) {
    return state.qcInspectionDetails.length > 0
  },
}

export const actions = {
  setQCInspectionDetails ({ commit }, qcInspectionDetails) {
    commit('SET_QC_INSPECTION_DETAILS', qcInspectionDetails)
  },
  setQCInspectionLastSyncDateTime ({ commit }, lastSyncDateTime) {
    commit('SET_QC_INSPECTION_LAST_SYNC', lastSyncDateTime)
  },
  setQCInspectionDetailsUpdatedOn ({ commit }, qcInspectionDetailsUpdatedOn) {
    commit('SET_QC_INSPECTION_DETAILS_UPDATED_ON', qcInspectionDetailsUpdatedOn)
  },
}
