import { NavigationHelperMixin } from '@/mixins/NavigationHelperMixin.js'
import * as Msal from '@azure/msal-browser'
import fvEnvVars from '@/fvEnvVars'
import Store from '@/store/store'

// eslint-disable-next-line
let mSALObj = {}
let accountId = null
let silentTokenAttempt = 0
export const B2CManager = {
  mixins: [NavigationHelperMixin],
  computed: {
    mSALObj: {
      get: function () {
        return this.$store.getters.mSALObj
      },
      set: function (newMSALObj) {
        mSALObj = newMSALObj
        this.$store.dispatch('updateMSALObj', newMSALObj)
      },
    },
    userName: {
      get: function () {
        return this.$store.getters.userName
      },
      set: function (newUserName) {
        this.$store.dispatch('updateUserName', newUserName)
      },
    },
    userNameShort: {
      get: function () {
        return this.$store.getters.userNameShort
      },
      set: function (newUserNameShort) {
        this.$store.dispatch('updateUserNameShort', newUserNameShort)
      },
    },
    userEmail: {
      get: function () {
        return this.$store.getters.userEmail
      },
      set: function (newUserEmail) {
        this.$store.dispatch('updateUserEmail', newUserEmail)
      },
    },
    userId: {
      get: function () {
        return this.$store.getters.userId
      },
      set: function (newUserId) {
        this.$store.dispatch('updateUserId', newUserId)
      },
    },
    employeeId: {
      get: function () {
        return this.$store.getters.employeeId
      },
      set: function (newEmployeeId) {
        this.$store.dispatch('updateEmployeeId', newEmployeeId)
      },
    },
    authAccountObj: {
      get: function () {
        return this.$store.getters.authAccountObj
      },
      set: function (newAuthAccountObj) {
        this.$store.dispatch('updateAuthAccountObj', newAuthAccountObj)
      },
    },
    clientId () {
      return this.$store.getters.clientId
    },
    authority () {
      return this.$store.getters.authority
    },
    routeDoesntRequireAuthorization () {
      const currentRoute = this.$router.currentRoute
      return (
        currentRoute.name === 'SessionExpired'
      )
    },
  },
  methods: {
    createMsalObj () {
      const msalConfig = {
        auth: {
          clientId: this.clientId,
          authority: this.authority,
          knownAuthorities: [Store.getters.knownAuthorities],
          redirectUri: Store.getters.appBaseURL,
          navigateToRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
              loggerCallback: (level, message, containsPii) => {
                  if (containsPii) {
                      return
                  }
                  switch (level) {
                      case Msal.LogLevel.Trace:
                          return
                      case Msal.LogLevel.Error:
                          return
                      case Msal.LogLevel.Info:
                          return
                      case Msal.LogLevel.Verbose:
                          return
                      case Msal.LogLevel.Warning:
                          console.warn(message)
                  }
              },
              piiLoggingEnabled: false,
            },
        },
      }
      const mSALObj = new Msal.PublicClientApplication(msalConfig)
      return mSALObj
    },
    async initMsalObj (impersonatedUserId, name, emailAddress) {
      if (impersonatedUserId) {
        await this.handleResponse(null, impersonatedUserId, name, emailAddress)
      } else if (this.mSALObj == null) {
        this.mSALObj = this.createMsalObj()

        await this.mSALObj
          .handleRedirectPromise()
          .then(this.handleResponse, impersonatedUserId, name, emailAddress)
          .catch(this.handleRedirectPromiseError)
      }
    },
    async handleResponse (response, impersonatedUserId, name, emailAddress) {
      if (response) {
        // if response contains an access token, store it
        this.selectAccount()

        if (response.accessToken && response.accessToken !== '') {
          this.$store.dispatch('updateAuthToken', {
            accessToken: response.accessToken,
          })
        }

        // for handling B2C user-flows and policies
        this.handlePolicyChange(response)
      } else {
        // this is needed for page that don't need authentication;
        if (this.routeDoesntRequireAuthorization) {
          // do nothing since we don't require authentication
        } else {
          this.selectAccount(impersonatedUserId, name, emailAddress)
          if (!this.$store.getters.impersonatingUser) {
            await this.getTokenRedirect(false, ++silentTokenAttempt)
          }
        }
      }
      this.routerControlBeforeAuthObject(this.$router.currentRoute, impersonatedUserId, name, emailAddress)
    },
    handleRedirectPromiseError (error) {
      // Check for forgot password error
      // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      if (error.errorMessage && error.errorMessage.indexOf('AADB2C90118') > -1) {
        try {
          mSALObj.loginRedirect({
            authority: Store.getters.passwordResetURL,
          })
        } catch (err) {
          Store.$app.$azureLogger.writeLogItem('error', {
            Application: 'FieldVue',
            FeatureName: 'B2CManager - handleRedirectPromiseError',
            ErrorCode: err.error_codes.join(),
            ErrorDescription: 'An error was encountered while handling a redirect promise.',
            AdditionalDetails: ' Stack: ' + err.stack,
          })
        }
      } else if (error.errorMessage && error.errorMessage.indexOf('AADB2C90091') > -1) {
        this.logIn()
      } else {
        this.logIn()
      }
    },
    handlePolicyChange (response) {
      /**
       * We need to reject id tokens that were not issued with the default sign-in policy.
       * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
       * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
       */
      if (
        Store.getters.passwordResetURL.includes(response.idTokenClaims.tfp)
      ) {
          // window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
          this.mSALObj.logout()
      }
    },
    selectAccount (impersonatedUserId, name, emailAddress) {
      /**
       * See here for more information on account retrieval:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
       */
      // Store.$app.isOnline
      if (this.$store.getters.isOnline) {
        const tmpCurrentAccounts = this.mSALObj.getAllAccounts()
        Store.$app.$storage.set('currentAccounts', tmpCurrentAccounts)
      }

      const currentAccounts = Store.$app.$storage.get('currentAccounts')

      if (impersonatedUserId && currentAccounts.length === 1) {
        accountId = accountId.replace(currentAccounts[0].homeAccountId, impersonatedUserId)
        this.userName = name
        // currentAccounts[0].name = name
        currentAccounts[0].homeAccountId = accountId
        currentAccounts[0].localAccountId = impersonatedUserId
        currentAccounts[0].username = emailAddress
        this.authAccountObj = currentAccounts[0]
      } else {
        if (currentAccounts.length === 1) {
          accountId = currentAccounts[0].homeAccountId
          this.userName = currentAccounts[0].name
          this.authAccountObj = currentAccounts[0]
        } else if (currentAccounts.length > 1) {
          accountId = currentAccounts[0].homeAccountId
          this.userName = currentAccounts[0].name
          this.authAccountObj = currentAccounts[0]
        } else if (currentAccounts.length === 0) {
          // we chose not to log this non-error error
        }
      }
    },
    logIn () {
      /**
      * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
      * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
      */
      this.$store.dispatch('setLoggedIn', true)
      var requestObj = {
        scopes: ['openid', fvEnvVars[location.host].VUE_APP_B2CScope],
        // scopes: ['openid', this.o360APIScope],
        state: JSON.stringify(this.$store.getters.onStartAppState),
      }

      this.mSALObj.loginRedirect(requestObj)
    },
    logOut: function () {
      /**
      * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
      * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
      */
      this.$azureLogger.writeLogItem('activity', {
        Application: 'FieldVue',
        FeatureName: 'Log Out',
        ActivityType: this.$store.getters.loggingActivityType.clicked,
        AdditionalDetails: '',
      })

      // Choose which account to logout from by passing a homeAccountId.
      this.$store.dispatch('setLoggedIn', false)
      const account = this.mSALObj.getAccountByHomeId(accountId)

      if (account) {
        const logoutRequest = {
          account: account,
          // postLogoutRedirect: ''
        }
        this.mSALObj.logoutRedirect(logoutRequest)
      } else {
        this.mSALObj.logoutRedirect()
      }
    },
    async getTokenRedirect (notFirstTime, attempt) {
      const self = this

      var tokenRequest = {
        scopes: [fvEnvVars[location.host].VUE_APP_B2CScope],
        state: JSON.stringify(this.$store.getters.onStartAppState),
        forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
        account: this.mSALObj.getAccountByHomeId(accountId),
      }
      // console.log('getting silent token attempt ' + attempt)
      return this.mSALObj.acquireTokenSilent(tokenRequest)
        .then((response) => {
          // console.log('received response from silent token attempt ' + attempt)
          // In case the response from B2C server has an empty accessToken field
          // throw an error to initiate token acquisition
          if (!response.accessToken || response.accessToken === '') {
            throw new Msal.InteractionRequiredAuthError()
          }
          return this.$store.dispatch('updateAuthToken', {
            accessToken: response.accessToken,
            notFirstTime: notFirstTime,
          })
        })
        .catch(error => {
          if (
            error instanceof Msal.InteractionRequiredAuthError ||
            error instanceof Msal.BrowserAuthError
          ) {
            // fallback to interaction when silent call fails
            return self.mSALObj.acquireTokenRedirect(tokenRequest)
          } else {
            // console.log('inside catch else in b2cManager')
          }
      })
    },
    async initialzeUserInfo (impersonatedUserId, name, emailAddress) {
      const account = Store.$app.$storage.has('currentAccounts') ? Store.$app.$storage.get('currentAccounts')[0] : {}
      if (impersonatedUserId) {
        this.userName = name
        this.userNameShort = emailAddress.split('@')[0]
        this.userId = impersonatedUserId
        this.userEmail = emailAddress
        account.homeAccountId = accountId.replace(account.homeAccountId, impersonatedUserId)
        account.localAccountId = impersonatedUserId
        account.username = emailAddress
      } else {
        this.userName = account.name
        this.userNameShort = account.idTokenClaims.emails[0].split('@')[0]
        this.userId = account.idTokenClaims.oid
        this.userEmail = account.idTokenClaims.emails ? account.idTokenClaims.emails[0] : null
      }
    },
    routerControlBeforeAuthObject (currentRoute, impersonatedUserId, name, emailAddress) {
      // when using a bookmarked login screen, catch the error and logIn again
      var account = this.mSALObj ? this.mSALObj.getAllAccounts()[0] : null

      // user not logged in
      if (!account) {
        // alow the following pages to be displayed, and redirect to login for all others
        if (
          currentRoute.name === 'TimedOut' ||
          currentRoute.path === '/timedout'
        ) {
          // console.log('Page does not need authorization')
        } else {
          this.$store.dispatch('updateOnStartAppState', currentRoute.query)
          this.logIn()
        }

      // user logged in
      } else {
        this.initialzeUserInfo(impersonatedUserId, name, emailAddress)

        if (currentRoute.name === 'Logout') {
          this.logOut()
        } else {
          // else display a loading page while userAuthObj is being gotten from the auth service
          if (!impersonatedUserId) {
            this.pushRouteTo({ name: 'Today' })
          }
        }
      }
    },
    setTokenCallback (tokenResponse) {
      this.$store.dispatch('updateAuthToken', tokenResponse.accessToken)
    },
  },
}
