<template>
  <v-alert
    v-model="displayAlerts"
    type="error"
    dismissible="dismissible"
    prominent
    class="ml-1 text-left py-2"
    width="100%"
    @input="$notificationPlugin.markAllRead()"
  >
    <ul
      class="px-0"
    >
      <v-list-item
        v-for="(message, index) in visibleMessages"
        :key="message.id"
        dense
        :class="$vuetify.breakpoint.mdAndDown && index > 0 ? 'px-0 mt-3' : 'px-0'"
      >
        <v-list-item-content
          class="text-body-1 py-0"
        >
          <v-row
            class="text-align-center"
          >
            <v-col
              cols="10"
              lg="11"
            >
              {{ message.message }} 
            </v-col>
            <v-col
              class="px-0"
              cols="2"
              lg="1"
            >
              <v-tooltip bottom>
                <span>Mark as read</span>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="$notificationPlugin.toggleReadStatus(message.id)"
                  >
                    <v-icon
                      small
                    >
                      fa-regular fa-eye
                    </v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
              <v-tooltip bottom>
                <span>Remove Notification</span>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="$notificationPlugin.remove(message.id)"
                  >
                    <v-icon
                      small
                    >
                      fa-regular fa-trash-can
                    </v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </ul>
  </v-alert>
</template>
<script>
  export default {
  name: 'FvAlert',
  data () {
    return {
      displayAlerts: false
    }
  },
  computed: {
    visibleMessages () {
      if (this.$notificationPlugin.getNotifications('false', 'high').length > 0) {
        this.displayAlerts = true
        return this.$notificationPlugin.getNotifications('false', 'high')
      } else {
        this.displayAlerts = false
        return []
      }      
    },      
  },
}
</script>
  