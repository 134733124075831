import Axios from 'axios'
import Store from '@/store/store'
import { unflatten } from 'safe-flat'
import EmployeeManager from '@/Lib/EmployeeManager'
import RoleMapGridManager from '@/Lib/RoleMapGridManager'
import UserManager from '@/Lib/UserManager'
import { setDeep } from '@/helpers/setdeep'

export default {
  apmPreferencesModified: false,

  async initializeApplicationPreferences () {
    const URL = `${Store.getters.azureProxyBaseURL}FieldVue/Preferences/${Store.getters.userId}`

    try {
      const response = await Axios.get(URL,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Store.getters.authToken}`,
          },
        },
      )

      await this.initializeApplicationPreferencesSuccess(response).then(() => {}) // written this way on purpose

      return await response.data
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'ApplicationPreferencesManager',
        FeatureName: 'initializeApplicationPreferences()',
        ErrorCode: error.name,
        ErrorDescription: 'Failed to get application preferences.',
        AdditionalDetails: `Message: ${typeof error.message === 'object' && error.message !== null ? JSON.stringify(error.message) : error.message}. Stack: ${error.stack}`,
      })
    }
  },

  async initializeApplicationPreferencesSuccess (preferencesResponse) {
    Store.dispatch('setFvLoadingMessage', 'Getting your preferences...')
    let newPrefs = ''

    if (typeof preferencesResponse?.data !== 'undefined' && preferencesResponse.data !== null) {
      newPrefs = preferencesResponse.data.Value
    }

    // if we have preferences in the old format
    if ((newPrefs !== '{}' && newPrefs.includes('1.101.MyTeam')) || (newPrefs.includes('"Roles":[null]') || newPrefs.includes('"Tasks":[null]'))) {
      newPrefs = await this.upgradePrefs(newPrefs)
      this.apmPreferencesModified = true
    }

    // if we have the old icons
    if (newPrefs.includes('"MyTeam":') && newPrefs.includes('mdi-')) {
      newPrefs = await this.correctTeamRoleIcons(newPrefs)
      this.apmPreferencesModified = true
    }

    // if we have don't have a Tasks array
    if (!newPrefs.includes('"Tasks":') && newPrefs !== '{}') {
      newPrefs = await this.addTasksProperty(newPrefs)
      this.apmPreferencesModified = true
    }

    if (newPrefs && newPrefs !== '' && newPrefs !== '{}') {
      const parsedPrefs = JSON.parse(newPrefs)
      const employees = await EmployeeManager.getEmployees()
      const currentUser = employees.find(employee => (employee.EmployeeId.trim() === Store.getters.employeeId))
      // if we get this far and the user team is empty
      if (Array.isArray(parsedPrefs[1][101].MyTeam.value) && parsedPrefs[1][101].MyTeam.value.length === 0) {
        // add the user to their own team
        if (currentUser) {
          parsedPrefs[1][101].MyTeam.value.push({
            EmployeeId: currentUser.EmployeeId,
            Name: currentUser.Name,
            Roles: currentUser.Roles,
            Tasks: currentUser.Tasks,
          })
        }

        // indicate preferences modified
        this.apmPreferencesModified = true
      } else {
        // check that their user role matches their job code
        const currentUserAuthObj = Store.getters.userAuthObject
        const userRoles = await RoleMapGridManager.getFromApi('Roles')
        const roleMappings = await RoleMapGridManager.getFromApi('JobCodeRoles')
        const currentJobMapping = roleMappings.find(map => map.JobCode === currentUser.JobCode)
        if (currentJobMapping && currentJobMapping.RoleId !== currentUserAuthObj.RoleID && !currentUserAuthObj.RoleOverridden) {
          // set their role id to the correct one
          UserManager.editUserRole({
            OriginalRoleId: currentUserAuthObj.RoleID,
            NewRoleId: currentJobMapping.RoleId,
            UserId: currentUserAuthObj.Id,
          })
          Store.dispatch('displayAppDialog', {
            display: true,
            message: `Your user role was automatically changed from '${currentUserAuthObj.RoleName}' to '${userRoles.find(role => role.RoleId === currentJobMapping.RoleId).RoleName}'.  Log out and log back in to apply Role changes.`,
            type: 'Info',
            redirectTo: false,
          })
        }
      }

      Store.$app.$storage.set('applicationPreferences', parsedPrefs)
    } else {
      Store.$app.$storage.set('applicationPreferences', {})
    }

    Store.dispatch('setPreferencesInitialized', true)

    if (this.apmPreferencesModified) {
      // eslint-disable-next-line
      const result = await this.saveApplicationPreferencesToTheCloud()

      this.apmPreferencesModified = false
    }

    return true
  },

  async upgradePrefs (preferencesString) {
    // define valid team member object keys
    const validKeys = ['CrewId', 'EmployeeId', 'Name', 'Roles', 'Tasks']
    const oldPrefsObj = unflatten(JSON.parse(preferencesString))
    const newPrefsObj = { 1: { } }
    const employees = await EmployeeManager.getEmployees()

    // if the preferences are in the flattened format
    if (preferencesString.includes('1.6')) {
      // reformat them
      newPrefsObj['1']['6'] = JSON.parse(JSON.stringify(oldPrefsObj[1][6]))
    }

    // if the preferences are in the flattened format
    if (preferencesString.includes('1.101.MyTeam')) {
      // reformat them
      newPrefsObj['1']['101'] = JSON.parse(JSON.stringify(oldPrefsObj[1][101]))
    }

    // clean up circular references
    if (newPrefsObj?.['1']?.['101']?.MyTeam?.value) {
      newPrefsObj['1']['101'].MyTeam.value.forEach((currentTeamMember) => {
        // if we're missing the Roles array
        if ((!Array.isArray(currentTeamMember?.Roles) || currentTeamMember.Roles === '[Circular]') || currentTeamMember.Roles[0] === null) {
          currentTeamMember.Roles = [{ Id: 1, Name: 'Data Collector', DisplayName: 'Data Collector', Icon: 'fa-regular fa-user-pen' }]
        }
        if (!Array.isArray(currentTeamMember?.Tasks) || currentTeamMember.Tasks[0] === null) {
          currentTeamMember.Tasks = []
        }
        // set the CrewId and Employee.Name to prevent Today page implosion
        const employee = employees.find(currentEmployee => currentEmployee.EmployeeId === currentTeamMember.EmployeeId)

        if (typeof employee !== 'undefined' && employee !== null) {
          currentTeamMember.CrewId = employee.CrewId
          currentTeamMember.Name = employee.Name
        } else {
          currentTeamMember.CrewId = ''
          currentTeamMember.Name = `Employee (${currentTeamMember.EmployeeId}) not found`
        }

        // remove invalid team member keys if they exists
        Object.keys(currentTeamMember).forEach((key) => validKeys.includes(key) || delete currentTeamMember[key])
      })
    } else {
      newPrefsObj['1']['101'] = JSON.parse(JSON.stringify(oldPrefsObj[1][101]))
      newPrefsObj['1']['101'].MyTeam.value.forEach((currentTeamMember) => {
        if (!Array.isArray(currentTeamMember?.Tasks) || currentTeamMember.Tasks[0] === null) {
            currentTeamMember.Tasks = []
        }
        if (currentTeamMember.Roles[0] === null) {
            currentTeamMember.Roles = [{ Id: 1, Name: 'Data Collector', DisplayName: 'Data Collector', Icon: 'fa-regular fa-user-pen' }]
        }
      })
    }

    return JSON.stringify(newPrefsObj)
  },

  async correctTeamRoleIcons (prefsString) {
    // replace the field manager icon
    if (prefsString.includes('"DisplayName":"Field Manager","Icon":"mdi-account-tie"')) {
      prefsString = prefsString.replaceAll('"DisplayName":"Field Manager","Icon":"mdi-account-tie"', '"DisplayName":"Field Manager","Icon":"fa-solid fa-user-tie"')
    }

    // replace the field support icon
    if (prefsString.includes('"DisplayName":"Field Support","Icon":"mdi-account-tie"')) {
      prefsString = prefsString.replaceAll('"DisplayName":"Field Support","Icon":"mdi-account-tie"', '"DisplayName":"Field Support","Icon":"fa-solid fa-user-gear"')
    }

    // replace the "Filed" Support icon
    if (prefsString.includes('"DisplayName":"Filed Support","Icon":"mdi-account-tie"')) {
      prefsString = prefsString.replaceAll('"DisplayName":"Filed Support","Icon":"mdi-account-tie"', '"DisplayName":"Field Support","Icon":"fa-solid fa-user-gear"')
    }

    // replace the Corporate Support icon
    if (prefsString.includes('"DisplayName":"Corporate Support","Icon":"mdi-account-tie"')) {
      prefsString = prefsString.replaceAll('"DisplayName":"Corporate Support","Icon":"mdi-account-tie"', '"DisplayName":"Corporate Support","Icon":"fa-solid fa-chart-user"')
    }

    // replace the mdi-account-hard-hat icon
    if (prefsString.includes('mdi-account-hard-hat')) {
      prefsString = prefsString.replaceAll('mdi-account-hard-hat', 'fa-solid fa-user-helmet-safety')
    }

    // replace the mdi-account-edit icon
    if (prefsString.includes('mdi-account-edit')) {
      prefsString = prefsString.replaceAll('mdi-account-edit', 'fa-solid fa-user-pen')
    }

    return prefsString
  },

  async addTasksProperty (prefsString) {
    try {
      const prefsObj = JSON.parse(prefsString)
      if (prefsString !== '') {
        prefsObj['1']['101'].MyTeam.value.forEach((teamMember) => {
          if (!Object.prototype.hasOwnProperty.call(teamMember, 'Tasks')) {
            Object.defineProperty(teamMember, 'Tasks', {
              value: [],
              writable: true,
              enumerable: true,
              configurable: true,
            })

            if (teamMember.Roles.some(role => role.Id === 1)) {
              teamMember.Tasks.push({ Id: 1 })
              teamMember.Tasks.push({ Id: 2 })
            }
          }
        })

        return JSON.stringify(prefsObj)
      } else {
        return '{}'
      }
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'addTasksProperty()',
        FeatureName: 'ApplicationPreferencesManager.vue',
        ErrorCode: 'APM001',
        ErrorDescription: 'Failed to add tasks property',
        AdditionalDetails: `Error: ${error.message} , Additional Data: ${prefsString}`,
      })

      return '{}'
    }
  },

  async saveApplicationPreferencesToTheCloud () {
    if (Store.getters.isOnline && !Store.getters.impersonatingUser) {
      const URL = `${Store.getters.azureProxyBaseURL}FieldVue/Preferences`

      try {
        const response = await Axios.post(URL, {
            EmployeeId: Store.getters.EmployeeId,
            UserGUID: Store.getters.userId,
            Value: JSON.stringify(this.getAllApplicationPreferences()),
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${Store.getters.authToken}`,
            },
          },
        )

        return response
      } catch (error) {
        Store.$app.$azureLogger.writeLogItem('error', {
          Application: 'ApplicationPreferencesManager',
          FeatureName: 'saveApplicationPreferencesToTheCloud()',
          ErrorCode: 'Axios Error',
          ErrorDescription: 'Failed to save preferences to the cloud.',
          AdditionalDetails: `Message: ${typeof error.message === 'object' && error.message !== null ? JSON.stringify(error.message) : error.message}. Stack: ${error.stack}`,
        })
      }
    }
  },

  setPreference (preferenceObj) {
    const updatedPrefs = Store.$app.$storage.get('applicationPreferences')

    setDeep.setDeepObject(
      updatedPrefs,
      [preferenceObj.projId, preferenceObj.appId, preferenceObj.key],
      {
        value: preferenceObj.value,
        userCanEdit: preferenceObj.userCanEdit,
      },
      true,
    )

    Store.$app.$storage.set('applicationPreferences', updatedPrefs)
  },
  getPreference (preferenceObj) {
    const tempPref = Store.$app.$storage.get('applicationPreferences')

    return tempPref?.[preferenceObj.projId]?.[preferenceObj.appId]?.[preferenceObj.key]
  },
  getAllApplicationPreferences () {
    if (Store.$app.$storage.has('applicationPreferences')) {
      return Store.$app.$storage.get('applicationPreferences')
    } else {
      return {}
    }
  },
  hasPreference (preferenceObj) {
    const tempPref = Store.$app.$storage.get('applicationPreferences')

    const prefDefined = tempPref?.[preferenceObj.projId]?.[preferenceObj.appId]?.[preferenceObj.key]

    return (typeof prefDefined !== 'undefined')
  },
}
