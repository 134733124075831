import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{class:_vm.linkLevel === 'top' ? '' : _vm.drawerState ? '' : 'navexpanded',attrs:{"active-class":`primary ${!_vm.isDark ? 'black' : 'white'}--text`,"rel":_vm.href && _vm.href !== '#' ? 'noopener' : undefined,"to":_vm.item.to,"disabled":!_vm.isOnline && _vm.item.OnlineOnly,"title":_vm.item.Title},on:{"click":_vm.cardNavigate}},[(_vm.item.NavIconDisplay)?_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.item.NavIcon)}})],1):_c(VListItemIcon,{staticClass:"v-list-item__icon--text text-h3 font-weight-bold text-center pl-1 pt-0 mt-0",domProps:{"textContent":_vm._s(_vm.computedText)}}),(_vm.item.Title || _vm.item.subtitle)?_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.item.Title)}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }