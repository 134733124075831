<template>
  <v-dialog
    id="fvModal"
    v-model="Visibility"
    persistent
    no-close-on-esc
    no-close-on-backdrop
  >
    <v-card>
      <v-card-title class="justify-center">
        <h3
          class="text-h3"
        >
          {{ title }}
        </h3>
      </v-card-title>

      <v-card-text>
        {{ description }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!hideCancel"
          color="blue darken-1"
          text
          @click="updateVisibility"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn
          v-if="!hideOkay"
          color="blue darken-1"
          text
          @click="updateVisibility"
        >
          {{ okayText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props: {
      visibility: { type: Boolean, required: true },
      visibilityUpdateFunc: { type: Function },
      visibilityUpdateFuncName: { type: String },
      title: { type: String, requried: true },
      description: { type: String, requried: true },
      modalStyle: { type: String, default: 'primary' }, // ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark']
      okayText: { type: String, default: 'OK' },
      cancelText: { type: String, default: 'Return' },
      hideCancel: { type: Boolean, default: false },
      hideOkay: { type: Boolean, default: false },
      callback: { type: Function },
    },
    computed: {
      Visibility () {
        return this.visibility
      },
    },
    methods: {
      updateVisibility () {
      },
    },
  }
</script>
<style scoped>
</style>
<style>
</style>
