import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import FvAlert from './Components/FvAlert.vue'
import FvNotificationList from './Components/FvNotificationList.vue'

/**
 * Represents a notification plugin.
 */
class NotificationPlugin {
  /**
   * Constructs a new instance of the NotificationPlugin.
   * @param {Vue} vueInst - The Vue instance.
   */
  constructor() {
    this.notifications = []
  }

  /**
   * Adds a new notification.
   * @param {string} created - The creation date of the notification. Defaults to the current date and time.
   * @param {string} expiry - The expiry date of the notification. Defaults to the current date and time. Currently not used.
   * @param {string} id - The unique identifier of the notification. Defaults to a new UUID.
   * @param {string} message - The message content of the notification.
   * @param {string} priority - The priority of the notification. Defaults to 'low'. Valid values are 'low' or 'high'
   * @param {boolean} read - Indicates whether the notification has been read. Defaults to false.
   */
  add(created, expiry, id, message, priority, read) {
    const notification = this.#createNotificationObject(created, expiry, id, message, priority, read)

    // add the notification if it doesn't exist in the notifications array
    const index = this.notifications.findIndex(notification => notification.id === id)
    if (index !== -1) {
      // delete the existing notification and add the new one
      this.notifications.splice(index, 1)
    }
    
    this.notifications.push(notification)
  }

  /**
   * Retrieves notifications based on the provided parameters.
   * @param {string} read - The type of notifications to retrieve. Can be 'all', 'unread', 'read'.
   * @param {string} priority - The priority of notifications to retrieve. Can be 'all', 'high', 'low'.
   * @returns {Array} An array of notifications.
   */
  getNotifications(read = 'all', priority = 'all') {
    let notifications = this.notifications

    if (read !== 'all') {
      const isRead = read === 'true'
      notifications = notifications.filter(notification => notification.read === isRead)
    }

    if (priority !== 'all') {
      notifications = notifications.filter(notification => notification.priority === priority)
    }

    return notifications.sort((a, b) => DateTime.fromISO(b.created) - DateTime.fromISO(a.created))
  }

  /**
   * Toggles the read property of a specific notification.
   * @param {string} id - The unique identifier of the notification.
   */
  toggleReadStatus(id) {
    const notification = this.notifications.find(notification => notification.id === id)
    if (notification) {
      notification.read = !notification.read
    }
  }

  markAllRead() {
    this.notifications.forEach(notification => notification.read = true)
  }

  /**
   * Removes a notification by its id.
   * @param {string} id - The unique identifier of the notification to remove.
   */
  remove(id) {
    this.notifications = this.notifications.filter(notification => notification.id !== id)
  }

  removeAll() {
    this.notifications = []
  }

  /**
   * Creates a new notification object.
   * @param {string} created - The creation date of the notification. Defaults to the current date and time.
   * @param {string} expiry - The expiry date of the notification. Defaults to the current date and time. Currentlyq not used.
   * @param {string} id - The unique identifier of the notification. Defaults to a new UUID.
   * @param {string} message - The message content of the notification.
   * @param {string} priority - The priority of the notification. Defaults to 'low'. Valid values are 'low' or 'high'
   * @param {boolean} read - Indicates whether the notification has been read. Defaults to false.
   * @returns {object} The created notification object.
   */
  #createNotificationObject(created = DateTime.now().toISO(), expiry = DateTime.now.toISO(), id = uuidv4(), message = '', priority = 'low', read = false) {
    created = (typeof created === 'string' && created.length > 0) ? created : DateTime.now().toISO()
    expiry = (typeof expiry === 'string' && expiry.length > 0) ? expiry : DateTime.now().toISO()
    id = (typeof id === 'string' && id.length > 0) ? id : uuidv4()
    message = (typeof message === 'string') ? message : ''
    priority = (typeof priority === 'string') ? priority : 'low'
    read = (typeof read === 'boolean') ? read : false

    return {
      created,
      expiry,
      id,
      message,
      priority,
      read,
    }
  }
}

Vue.use({
  install(Vue) {
    Vue.component('fv-alert', FvAlert)
    Vue.component('fv-notification-list', FvNotificationList)

    let notificationPlugin = new NotificationPlugin()

    Vue.prototype.$notificationPlugin = Vue.observable(notificationPlugin)
  }
})