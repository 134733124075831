import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{attrs:{"href":_vm.item.LinkUrl,"active-class":`primary ${!_vm.isDark ? 'black' : 'white'}--text`,"target":"_blank","disabled":!_vm.isOnline && _vm.item.OnlineOnly},on:{"click":function($event){return _vm.writeToLogging()}}},[(_vm.item.NavIconDisplay)?_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.item.NavIcon)}})],1):_c(VListItemIcon,{staticClass:"v-list-item__icon--text",domProps:{"textContent":_vm._s(_vm.ComputedText)}}),(_vm.item.Title || _vm.item.Subtitle)?_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.item.Title)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.item.Subtitle)}})],1):_vm._e(),_c(VIcon,{attrs:{"small":""}},[_vm._v(" fa-solid fa-arrow-up-right-from-square ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }