import Store from '@/store/store'
import Axios from 'axios'
import Bowser from 'bowser'
import EmployeeManager from '@/Lib/EmployeeManager'
import { DateTime } from 'luxon'
import AuthorizationManager from '@/Lib/AuthorizationManager'

/******************************************************************************
* Sends a log entry to Azure Logs
* logType: <activity | error>
* logEntry: JSON Object in one of the following forms:
*   Activity Entry:
*     {
*       Application: 'Today',
*       FeatureName: 'Safety Tip',
*       ActivityType: 'Clicked',
*       AdditionalDetails: 'String'
*     }
*
*   Error Entry:
*     {
*       Application: 'Today',
*       FeatureName: 'Safety Tip',
*       ErrorCode: <String>
*       ErrorDescription: <String>
*       AdditionalDetails: <String>
*     }
******************************************************************************/
class AzureLogging {
  async sendLogRequest (path, logItemsArray = []) {
    const URL = `${Store.getters.azureProxyBaseURL}Fieldvue/${path}`

    let response = false
    try {
      if (Store.getters.authToken !== null && Array.isArray(logItemsArray) && logItemsArray.length > 0) {
        response = await Axios.post(URL,
          logItemsArray,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${Store.getters.authToken}`,
            },
          },
        )
      }
    } catch (error) {
      // Eating this for now because of possible false positives
    }
    return response
  }

  async sendToServer () {
    let queuedLogItems = JSON.parse(JSON.stringify(Store.$app.$storage.get('queuedLogItems')))
    Store.$app.$storage.set('queuedLogItems', [])

    if (queuedLogItems !== null && queuedLogItems.length > 0) {
      // seperate log items into arrays of type
      const activityLogEntries = queuedLogItems.filter(logItem => {
        if (logItem.Type === 'activity') {
          return logItem.LogEntry
        }
      })

      const partialActivityEntries = activityLogEntries.map(({ Type, ...rest }) => rest.LogEntry)
      const activityResponse = await this.sendLogRequest('LogActivity', partialActivityEntries)
      if (!activityResponse) {
        Store.$app.$storage.set(
          'queuedLogItems',
          [...JSON.parse(JSON.stringify(Store.$app.$storage.get('queuedLogItems'))), ...activityLogEntries],
        )
      } else {
        queuedLogItems = queuedLogItems.filter(logItem => logItem.Type !== 'activity')
      }

      const errorLogEntries = queuedLogItems.filter(logItem => {
        if (logItem.Type === 'error') {
          return logItem.LogEntry
        }
      })

      const partialErrorEntries = errorLogEntries.map(({ Type, ...rest }) => rest.LogEntry)
      const errorResponse = await this.sendLogRequest('LogError', partialErrorEntries)
      if (!errorResponse) {
        Store.$app.$storage.set(
          'queuedLogItems',
          [...JSON.parse(JSON.stringify(Store.$app.$storage.get('queuedLogItems'))), ...errorLogEntries],
        )
      } else {
        queuedLogItems = queuedLogItems.filter(logItem => logItem.Type !== 'error')
      }
    }
  }

  async writeLogItem (logType, logEntry = {}, sendToServer = true) {
    const userAuthObject = AuthorizationManager.getConfigurationFromLocalStorage('userAuthObject')
    const browser = Bowser.getParser(window.navigator.userAgent)
    const currentDateTime = DateTime.local().toISO({ includeOffset: true })
    let employee = {}
    if (userAuthObject.EmployeeId) {
      employee = await EmployeeManager.getEmployeeByEmployeeId(userAuthObject.EmployeeId)
    } else if (Store.getters.employeeId) {
      employee = await EmployeeManager.getEmployeeByEmployeeId(Store.getters.employeeId)
    }

    logEntry.BrowserName = browser.getBrowserName()
    logEntry.BrowserVersion = browser.getBrowserVersion()
    logEntry.EmployeeId = userAuthObject.EmployeeId
    logEntry.EmployeeName = Store.getters.userName !== null ? Store.getters.userName : userAuthObject.UserName
    logEntry.Territory = employee && employee.Territory !== null ? employee.Territory : ''
    logEntry.EntryDateTime = currentDateTime
    logEntry.ErrorDescription = typeof logEntry.ErrorDescription === 'object' && logEntry.ErrorDescription !== null ? JSON.stringify(logEntry.ErrorDescription) : logEntry.ErrorDescription
    logEntry.OSName = browser.getOSName()
    logEntry.OSVersion = browser.getOSVersion()
    logEntry.PlatformModel = typeof browser.getPlatform()?.model !== 'undefined' ? browser.getPlatform().model : ''
    logEntry.PlatformName = browser.getPlatformType()
    logEntry.PlatformVendor = typeof browser.getPlatform()?.vendor !== 'undefined' ? browser.getPlatform().vendor : ''
    logEntry.UserName = employee.LoginName
    logEntry.Version = process.env.VUE_APP_VERSION
    logEntry.Title = employee && employee.JobTitle !== null ? employee.JobTitle : ''

    // send to local storage
    if (!Store.$app.$storage.has('queuedLogItems')) {
      Store.$app.$storage.set('queuedLogItems', [{ Type: logType, LogEntry: logEntry }])
    } else {
      const queuedLogItems = Store.$app.$storage.get('queuedLogItems')
      queuedLogItems.push({ Type: logType, LogEntry: logEntry })
      Store.$app.$storage.set('queuedLogItems', queuedLogItems)
    }

    if (sendToServer && Store.getters.isOnline) {
      // attempt to send to server
      return await this.sendToServer()
    }
  }
}

class AzLogging {
  static install (Vue, options) {
    Vue.prototype.$azureLogger = new AzureLogging()
  }
}

export { AzLogging as AzureLogging }
