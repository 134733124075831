import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import NavItem from '@/components/NavItem'
import NavGroup from '@/components/NavGroup'
import FvExternalLink from '@/components/FvExternalLink'
import FvDynamicLink from '@/components/FvDynamicLink'
import ItemSubGroup from '@/components/ItemSubGroup'
import MaterialCard from '@/components/MaterialCard'
import MaterialChartCard from '@/components/MaterialChartCard'
import MaterialStatsCard from '@/components/MaterialStatsCard'
// import VueMoment from 'vue-moment'
import Card from '@/components/Card'
import InitialLogin from '@/components/infrastructure/InitialLogin'
import AppBar from '@/components/infrastructure/AppBar'
import NavigationDrawer from '@/components/infrastructure/NavigationDrawer'
import FieldVueModal from '@/components/infrastructure/FieldVueModal'
import NoticeScreen from '@/components/infrastructure/NoticeScreen'
import Vidle from 'v-idle'
import i18n from './i18n'

import { LicenseManager } from 'ag-grid-enterprise'
import { Vue2Storage } from '@/Lib/vue2StorageEsm'
import { AzureLogging } from '@/plugins/AzureLogging'
import '@/plugins/NotificationPlugin/NotificationPlugin.js'

import './registerServiceWorker'
LicenseManager.setLicenseKey('CompanyName=Osmose Utilities Services, Inc.,LicensedApplication=FieldVUE,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=11,LicensedProductionInstancesCount=1,AssetReference=AG-037169,SupportServicesEnd=18_March_2024_[v2]_MTcxMDcyMDAwMDAwMA==9fbf3228323dc2bf9e599e066c3d17ec')

Vue.config.productionTip = false
// Vue.config.performance = true
Vue.use(Vue2Storage)
Vue.use(AzureLogging)
// Vue.use(VueMoment)
Vue.component('InitialLogin', InitialLogin)
Vue.component('AppBar', AppBar)
Vue.component('NavigationDrawer', NavigationDrawer)
Vue.component('Card', Card)
Vue.component('NavItem', NavItem)
Vue.component('FvExternalLink', FvExternalLink)
Vue.component('FvDynamicLink', FvDynamicLink)
Vue.component('NavGroup', NavGroup)
Vue.component('ItemSubGroup', ItemSubGroup)
Vue.component('NoticeScreen', NoticeScreen)
Vue.component('FieldVueModal', FieldVueModal)
// Vue.component('VJsf', VJsf)
Vue.component('MaterialCard', MaterialCard)
Vue.component('MaterialChartCard', MaterialChartCard)
Vue.component('MaterialStatsCard', MaterialStatsCard)
Vue.use(Vidle)

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  created () {
    this.$storage.setOptions({
      prefix: 'FV_',
      driver: 'local',
      ttl: 0,
    })
  },
  render: h => h(App),
})

store.$app = app

app.$mount('#app')
