'use strict'
/* eslint-disable */
import Chart from 'chart.js'
import { DateTime } from 'luxon'

const defaults = {
	/**
	 * The font options used to draw the label text.
	 * @member {Object|Array|Function}
	 * @prop {String} font.family - defaults to Chart.defaults.global.defaultFontFamily
	 * @prop {Number} font.lineHeight - defaults to 1.2
	 * @prop {Number} font.size - defaults to Chart.defaults.global.defaultFontSize
	 * @prop {String} font.style - defaults to Chart.defaults.global.defaultFontStyle
	 * @prop {Number} font.weight - defaults to 'normal'
	 * @default Chart.defaults.global.defaultFont.*
	 */
	font: {
		family: undefined,
		lineHeight: 1.2,
		size: undefined,
		style: undefined,
		weight: null
	}
}

var helpers = {
	getLocalDateTime: function (dateString) {
		const currentDT = DateTime.fromISO(dateString)

		// if DST is in effect
		return (currentDT.isInDST)
			// subtract four hours
			? currentDT.minus({ hours: 4 })
			// otherwise subtract five hours
			: currentDT.minus({ hours: 5 })
	},
	getColorAndIconForLabel: function (timestamp) {
		const timeNow = DateTime.local().ts
		const timeFromLastSync = timeNow - timestamp
		let color = 'red'
		let icon
		// <= 12 hours
		if (timeFromLastSync <= 43200000) {
			color = 'rgba(0, 180, 0, 1)' //'green'
			icon = '\uF058' // circle check
		// between 12 and 24 hours
		} else if (timeFromLastSync > 43200000 && timeFromLastSync <= 86400000) {
			color = '#e66100' //'orange'
			icon = '\uF071' // trianle exclamation
		// > 24 hours
		} else {
			color = 'rgba(200, 0, 0, 1)' //'red'
			icon = '\uE286' // square x
		}
		return {
			color: color,
			icon: icon
		}
	},
}

Chart.defaults.global.plugins.doughnutholelabel = defaults;

function drawLabelFullscreen(chart, options) {
    if (options && options.data && options.data.length > 0) {
        let ctx = chart.ctx
		const height = chart.height
        let labelsToDraw = []
        options.data.forEach(datum => {
            if (datum.LastDCTSync !== '0001-01-01T00:00:00') {
                const lastSync = helpers.getLocalDateTime(datum.LastDCTSync).ts
				const labelObject = helpers.getColorAndIconForLabel(lastSync)
				labelsToDraw.push({
					color: labelObject.color,
					crewId: datum.CrewID,
					lastSyncTime: helpers.getLocalDateTime(datum.LastDCTSync).toFormat('MM-dd hh:mm a'),
					icon: labelObject.icon
				})
            } else {
				labelsToDraw.push({
					color: 'rgba(200, 0, 0, 1)', //'red',
					crewId: datum.CrewID,
					lastSyncTime: '',
					icon: '\uE286', // square x
				})
			}
        })
		ctx.textAlign = 'center'
		ctx.textBaseline = 'middle'
		const crewIdHeight = 18
		const crewIdOffset = 9
		const syncTimeHeight = 3
		const iconHeight = 19
		const iconOffset = 21
		const xAxis = chart.scales['x-axis-0']
		labelsToDraw.forEach((label, index) => {
			ctx.fillStyle = label.color
			ctx.fillText(label.crewId, xAxis.getPixelForTick(index) - crewIdOffset, height - crewIdHeight)
			ctx.fillText(label.lastSyncTime, xAxis.getPixelForTick(index), height - syncTimeHeight)
			ctx.save()
			ctx.font = '14px FontAwesome'
			ctx.fillText(label.icon, xAxis.getPixelForTick(index) + iconOffset, height - iconHeight)
			ctx.restore()
		})
    }
}

function drawLabelWidget (chart, options) {
	if (chart.options?.name === 'DCT PONI Collection Status' || chart.options?.name === 'Fulcrum PONI Collection Status') {
		const xAxis = chart.scales['x-axis-0']
		xAxis?.ticks.forEach((tick, index) => {
			const value = tick[0]
			const lastSync = helpers.getLocalDateTime(options.data[index].LastDCTSync).ts
			let labelObject
			if (lastSync !== '0001-01-01T00:00:00') {
				labelObject = helpers.getColorAndIconForLabel(lastSync)
			} else {
				labelObject = {
					color: 'rgba(200, 0, 0, 1)',
					icon: '\uE286', // square x
				}
			}
			const ctx = chart.ctx

			let labelOffsetX = 0
			let labelOffsetY = 0
			let iconOffsetX = 0
			let iconOffsetY = 0
			if (xAxis.labelRotation === 0) {
				ctx.textAlign = 'center'
				labelOffsetY = 16
			} else {
				ctx.textAlign = 'right'
				iconOffsetX = 8
				labelOffsetX = -5
				labelOffsetY = 2
			}
			ctx.textBaseline = 'middle'
			const xPos = xAxis.getPixelForTick(index)
			const yPos = xAxis.bottom - xAxis.height + 10
			const radians = -xAxis.labelRotation * (Math.PI / 180)

			ctx.save()

			ctx.translate(xPos, yPos)

			ctx.rotate(radians)

			ctx.fillStyle = labelObject.color

			ctx.fillText(value, labelOffsetX, labelOffsetY)

			ctx.font = '12px FontAwesome'

			ctx.fillText(labelObject.icon, iconOffsetX, iconOffsetY)

			ctx.restore()
		})
	}
}

Chart.plugins.register({
	id: 'barchartlabel',
	beforeDraw: function(chart, args, options) {
		const displayType = chart.options.displayType
		if (displayType === 'fullscreen') {
			drawLabelFullscreen(chart, options)
		} else if (displayType === 'widget') {
			drawLabelWidget(chart, options)
		}
	},
});