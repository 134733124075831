export const state = {
  items: null,
}

export const getters = {
  getNavItems: state => {
    return state.items
  },
}

export const actions = {
  setNavItems ({ commit }, items) {
    commit('SET_NAV_ITEMS', items)
  },
}

export const mutations = {
  SET_NAV_ITEMS (state, items) {
    state.items = items
  },
}
