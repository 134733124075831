<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawerState"
    :mini-variant="drawerMiniVariant"
    :permanent="computedPermenant"
    :temporary="drawerOverlays"
    class="navigation-drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.8)'"
    app
    :clipped="clipped"
    mini-variant-width="0"
    touchless
    :src="barImage"
    width="260"
    v-bind="$attrs"
  >
    <template #img="props">
      <v-img
        gradient="to bottom, rgba(5, 39, 68, 1), rgba(5, 39, 68, .4)"
        v-bind="props"
      />
    </template>

    <div
      class="d-flex fill-height flex-column justify-space-between"
    >
      <v-list
        :class="`flex-grow-2 pl-1 ${os === 'iOS' && platform === 'mobile' ? 'ios-padding' : ''} ${os === 'Android' && platform === 'mobile' ? 'android-padding' : ''}`"
        dense
        expand
        nav
      >
        <template v-for="(navItem, i) in items">
          <nav-group
            v-if="navItem.children"
            :key="`group-${i}`"
            :item="navItem"
          />

          <nav-item
            v-else
            :key="`navItem-${i}`"
            :item="navItem"
          />
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
  import Bowser from 'bowser'
  export default {
    data: () => ({
      clipped: false,
      fvDeviceSettings: {},
    }),
    computed: {
      platform () {
        return Bowser.getParser(window.navigator.userAgent).parsedResult.platform.type
      },
      os () {
        return Bowser.getParser(window.navigator.userAgent).getOS().name
      },
      items: {
        get () {
          return this.$store.getters.getNavItems
        },
      },
      settingsObject () {
        return this.$store.getters.getNavItems?.find(item => item.Name === 'Settings')
      },
      barColor: {
        get () {
          return this.$store.getters.getBarColor
        },
      },
      barImage: {
        get () {
          return this.$store.getters.getBarImage
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      computedPermenant () {
        if (this.platform !== 'mobile') {
          return true
        } else return false
      },
      drawerState: {
        get () {
          return this.$store.getters.getDrawerState
        },
        set (payload) {
          return this.$store.commit('SET_DRAWER_STATE', payload)
        },
      },
      drawerOverlays: {
        get () {
          if (this.platform !== 'mobile') {
            return false
          } else return this.$store.getters.getDrawerOverlays
        },
      },
      drawerMiniVariant: {
        get () {
          if (this.platform === 'mobile') {
            return false
          } else return this.$store.getters.getDrawerMiniVariant
        },
        set (payload) {
          return this.$store.commit('SET_DRAWER_MINI_VARIANT', payload)
        },
      },
    },
    watch: {
      drawerMiniVariant (newVal) {
        this.fvDeviceSettings.drawerMiniVariant = newVal
        localStorage.setItem('FV_deviceSettings', JSON.stringify(this.fvDeviceSettings))
      },
    },
    created () {
      if (localStorage.getItem('FV_deviceSettings')) {
        this.fvDeviceSettings = JSON.parse(localStorage.getItem('FV_deviceSettings'))
      } else {
        this.fvDeviceSettings = {
          drawerMiniVariant: false,
        }
      }
    },
    mounted () {
      this.drawerMiniVariant = this.fvDeviceSettings.drawerMiniVariant
      const scroll = document.querySelector('.v-navigation-drawer__content')
      if (this.platform === 'mobile') {
        scroll.addEventListener('scroll', () => {
          setTimeout(() => {
            window.scrollTo(0, 1)
          }, 0)
        })
      }
    },
    methods: {
      mapItem (navItem) {
        return {
          ...navItem,
          children: navItem.children ? navItem.children.map(this.mapItem) : undefined,
          title: navItem.title,
        }
      },
      toggleDrawerMiniVariant () {
        this.drawerMiniVariant = !this.drawerMiniVariant
      },
    },
  }
</script>

<style lang="sass">
.ios-padding
  padding-bottom: 120px
.android-padding
  padding-bottom: 48px
</style>
